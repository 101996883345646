import React from "react";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Link,
  Heading,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { Button } from "@chakra-ui/button";
import { FaComment } from "react-icons/fa";
import { Icon, CloseIcon } from "@chakra-ui/icons";
import numbro from "numbro";
import styled from "@emotion/styled";
import { Image } from "@chakra-ui/image";
import { hideTweet } from "../../../controllers/bookmark";
import { GrLike } from "react-icons/gr";
import { useClipboard } from "@chakra-ui/hooks";
import { useSession } from "next-auth/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import * as analytics from "../../../utils/analytics";
import { firebaseClient } from "../../../firebaseClient";
import { TweetContext } from "../../../context/tweetContext";
import { textStyle } from "theme/names";

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`; 

const Tweet = ({
  tweet,
  savedTweet = [],
  disableBookmarkButton,
  disableHideButton,
  disableMainHideButton=true,
  refresher=undefined,
  removeTweet=undefined,
  mode="copy",
  requestVariation=undefined,
  setSelectedTweet=undefined,
  disableRequest,
  isVariation=false,
  disableHeader=false,
  disableFooter=false,
  disableEditTweet=false,
  disableSubMenu=false,
  disableName=true,
  disableCopy=false,
  disableLike=false,
  isSelectable=false,
  disableCheckmark=true,
  disableUserPopup=false,
  toggleSelection=undefined,
  disableAddToQueue=true,
  forceSelectedTweet=undefined as any,
}) => {
  const { hasCopied, onCopy } = useClipboard(
    // tweet.data.full_text + `\n\nInspired by @${tweet.data.twUserName}`
    tweet.data.full_text
  );
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"


  const [isSaved, setIsSaved] = React.useState(false);
  // const [isSelectedInternal, setIsSelectedInternal] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  //@ts-ignore
  const inLists = [...new Set(...savedTweet.filter(x => x.id_str === tweet.data.id_str).map(s => s.lists), tweet.data.lists)];
  const initRef = React.useRef(null);

  const [editValue, setEditValue] = React.useState("");
  const [isOpenPopOver, setIsOpenPopOver] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [nbChar, setNbChar] = React.useState<number>(0);
  const [typingTimeout, setTypingTimeout] = React.useState<any>(undefined);
  
  const openPopOver = () => setIsOpenPopOver(true);
  const closePopOver = () => setIsOpenPopOver(false);
  const tweetContext = React.useContext(TweetContext);

  let textAppended = tweet.data.description + "\n\n" + tweet.data.url;

  return (
    <Box
      key={tweet.data.id}
      minW="250px"
      bg={useColorModeValue("white", "gray.800")}
      // borderWidth={isSelected ? "3px" : "1px"}
      // borderColor={isSelected ? "green.300" : useColorModeValue("gray.200", "gray.600")}
      // outline={isSelected ? "5px" : "1px"}
      borderBottomLeftRadius="12px"
      borderBottomRightRadius="12px"
      // p={2}
      boxShadow="rgba(100, 100, 111, 0.05) 0px 2px 19px 0px;"
      onClick={() => {
        if (isSelectable || toggleSelection) {
          console.log("click tweet");
          // setIsSelectedInternal(!isSelectedInternal);
          if (isSelectable) setIsSelected(!isSelected);
          //@ts-ignore
          toggleSelection && toggleSelection(tweet.data);
        }
      }}
      _hover={
        isSelectable || toggleSelection ? { backgroundColor: "gray.100" } : {}
      }
      cursor={isSelectable || toggleSelection ? "pointer" : "unset"}
    >
      <Box w="100%" css={{ filter: tweet.data.isBlurred && "blur(3px)" }}>
        {!disableHeader && (
          <>
            <Link as="a" href={tweet.data.url} isExternal>
              <Image
                key={tweet.data.urlToImage}
                width="100%"
                borderTopLeftRadius="12px"
                borderTopRightRadius="12px"
                objectFit="cover"
                height={40}
                bg={useColorModeValue("gray.200", "gray.600")}
                // borderRadius={20}
                // ml={1}
                //@ts-ignore
                src={tweet.data.urlToImage}
              />
            </Link>
          </>
        )}
        <Box
          p={2}
          borderWidth={isSelected ? "3px" : "1px"}
          borderColor={
            isSelected ? "green.300" : useColorModeValue("gray.200", "gray.600")
          }
          rounded="lg"
          mt={-2}
        >
          <Heading p={3} size="xs">
            {tweet.data.title}
          </Heading>
          <CopyToClipboard
            text={textAppended}
            onCopy={() => {
              toast.success("Post copied to clipboard", {
                style: {
                  background: "gray.600",
                  color: "#222",
                },
              });
            }}
          >
            <Text
              textStyle={textStyle["body.medium.light"]}
              pl={3}
              pr={2}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{
                __html: tweet.data.description,
              }}
            />
          </CopyToClipboard>
          {!disableFooter && (
            <>
              <Divider my={2} />
              <Flex alignItems="center" justifyContent="space-between">
                {!isVariation ? (
                  <Box display="flex" flexDir="row">
                    {!disableLike && (
                      <Flex alignItems="center" mr={3} ml={3}>
                        <Box
                          mr={2}
                          as={GrLike}
                          color={theme.colors.blue[600]}
                        />
                        {numbro(tweet.data.favorite_count)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                    {(tweet.data.commentCount ||
                      tweet.data.commentCount === 0) && (
                      <Flex alignItems="center">
                        <Box
                          mr={2}
                          as={FaComment}
                          color={theme.colors.green[300]}
                        />
                        {numbro(tweet.data.commentCount)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                  </Box>
                ) : (
                  <Box></Box>
                )}
                <Stack isInline spacing={1} alignItems="center">
                  {!disableMainHideButton && (
                    <Button
                      variant="outline"
                      size="sm"
                      _hover={{
                        colorScheme: "red",
                        bg: "red.500",
                        color: "white",
                      }}
                      leftIcon={<Icon as={CloseIcon} fontSize="xs" />}
                      onClick={async () => {
                        //@ts-ignore
                        removeTweet(tweet.data);
                        hideTweet(tweet.data, session?.user?.uid, -1);
                      }}
                    >
                      {"Hide"}
                    </Button>
                  )}
                  {!disableEditTweet ? (
                    // <Tooltip label={session?.user?.uid ? "Open the scheduler and prefil with this tweet" : "You need to be signed up to tweet"}>
                    <Box>
                      <Button
                        variant="outline"
                        size="sm"
                        _hover={{
                          colorScheme: "twitter",
                          bg: "#1A91DA",
                          color: "white",
                        }}
                        isDisabled={!session?.user?.uid}
                        // target="_blank"
                        // as="a"
                        //@ts-ignore
                        // href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet.data.fullTextAppended)}`}
                        onClick={() => {
                          // console.log("hit_edit_and_tweet");
                          // console.log("tweet.data.fullTextAppended: " + tweet.data.fullTextAppended);

                          tweet.data.isVariation = isVariation;

                          //@ts-ignore
                          tweetContext.newTweet({ text: textAppended });
                          //@ts-ignore
                          tweetContext.setSelectedTweet(tweet.data);
                          //@ts-ignore
                          tweetContext.open();
                        }}
                      >
                        {"Edit & tweet"}
                      </Button>
                    </Box>
                  ) : (
                    // </Tooltip>
                    <></>
                  )}
                </Stack>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Tweet;
