import { Text, Flex, Box, Image, useColorModeValue } from "@chakra-ui/react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import isAbsoluteUrl from 'is-absolute-url';
import * as Sentry from "@sentry/nextjs";
import { textStyle } from "theme/names";

export function MetaCard({ metadata }) {
  const {
    twitterCard,
    ogType,
    twitterDescription,
    ogDescription,
    twitterTitle,
    ogTitle,
    twitterImage,
    ogImage,
    ogUrl,
  } = metadata;

  let data: any = {
    type: twitterCard || ogType,
    description: twitterDescription || ogDescription,
    title: twitterTitle || ogTitle,
    image: twitterImage?.url || ogImage?.url,
  };
  try {
    if (ogUrl && isAbsoluteUrl(ogUrl)) {
      data.url = new URL(ogUrl).hostname;
    }
  } catch (err) {
    console.log("Error in metacard with url: ", ogUrl);
    console.log("Error in metacard with error: ", err)
    Sentry.captureException(new Error("Error in metacard with url: " + ogUrl));
  }

  if (!data.image) {
    data.type = "";
  }

  const textView = (
    <Box pl="2" fontSize="sm">
      <Text textStyle={textStyle["body.medium.light"]}
        // color={useColorModeValue("gray.500", "gray.400")}
        noOfLines={1} wordBreak={"break-all"}>
        {data.url}
      </Text>
      <Text textStyle={textStyle["body.medium.standard"]} noOfLines={1} wordBreak={"break-all"} fontSize="md">
        {data.title}
      </Text>
      <Text textStyle={textStyle["body.medium.light"]} color={useColorModeValue("gray.500", "gray.400")} noOfLines={2}>
        {data.description}
      </Text>
    </Box>
  );

  switch (data.type) {
    case "summary_large_image":
      return (
        <Box border="1px" borderRadius="xl" borderColor="inherit" mt="-2">
          <Box
            backgroundImage={data.image}
            backgroundSize="cover"
            borderTopRadius="xl"
            backgroundPosition="center"
            h="48"
          >
            <Image src={data.image} height="100%" width="100%" opacity="0" />
          </Box>
          <Box my="3" ml="0.5" mr="2">
            {textView}
          </Box>
        </Box>
      );
    case "player":
    case "summary":
      return (
        <Flex border="1px" borderRadius="xl" borderColor="inherit" h="24" mt="-2">
          <Box
            w="30%"
            backgroundImage={data.image}
            backgroundSize="cover"
            borderLeftRadius="xl"
            border="0px"
            backgroundPosition="center"
            position="relative"
          >
            {data.type === "player" && (
              <Box
                position="absolute"
                borderRadius="50%"
                background="#1d9bf0"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <AiOutlinePlayCircle color="white" size="28" />
              </Box>
            )}
            <Image src={data.image} height="100%" width="100%" opacity="0" />
          </Box>
          <Box w="70%" my="auto" mr="1">
            {textView}
          </Box>
        </Flex>
      );
    default:
      return (
        <Flex border="1px" borderRadius="xl" borderColor="inherit" h="28" mt="-2">
          <Box
            w="30%"
            borderLeftRadius="xl"
            borderRight="1px"
            borderColor="inherit"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <svg
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="1.5em"
              fill="#718096"
            >
              <g>
                <path d="M14 11.25H6c-.414 0-.75.336-.75.75s.336.75.75.75h8c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zm0-4H6c-.414 0-.75.336-.75.75s.336.75.75.75h8c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zm-3.25 8H6c-.414 0-.75.336-.75.75s.336.75.75.75h4.75c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"></path>
                <path d="M21.5 11.25h-3.25v-7C18.25 3.01 17.24 2 16 2H4C2.76 2 1.75 3.01 1.75 4.25v15.5C1.75 20.99 2.76 22 4 22h15.5c1.517 0 2.75-1.233 2.75-2.75V12c0-.414-.336-.75-.75-.75zm-18.25 8.5V4.25c0-.413.337-.75.75-.75h12c.413 0 .75.337.75.75v15c0 .452.12.873.315 1.25H4c-.413 0-.75-.337-.75-.75zm16.25.75c-.69 0-1.25-.56-1.25-1.25v-6.5h2.5v6.5c0 .69-.56 1.25-1.25 1.25z"></path>
              </g>
            </svg>
          </Box>
          <Box w="70%" my="auto" mr="1">
            {textView}
          </Box>
        </Flex>
      );
  }
}
