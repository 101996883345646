import { useState, useEffect, useContext } from "react";
import { AiButton } from "components/common/AiButton/AiButton";
import { useSession } from "next-auth/react";
import { getToken } from "firebaseClient";
import { Text, Button, Menu, MenuButton, MenuItem, MenuList, VStack } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiImage } from 'react-icons/fi'
import ProgressBar from "components/progress-bar";
import { MainContext } from "context/mainContext";
import { useCredit } from "controllers/subscription";

const styles = [
  "Default",
  "Abstract",
  "Anime",
  "Flat Design",
  "Gothic",
  "Impressionism",
  "Minimalist",
  "Pixel Art",
  "Process",
  "Papercut",
  "Steampunk",
  "Surreal",
  "Van Gogh",
];


const GenerateImage = ({
  content,
  onImageGenerated = (fileName: string, url: string) => { },
}) => {
  const { data: session, status } = useSession() ?? {}
  const sessionLoading = status === "loading"
  const mainContext = useContext(MainContext);
  const [style, setStyle] = useState(styles[0]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  let mediaRegex = /\[img:[^\]]+\]/g;
  const contentWithoutMedia = content.replace(mediaRegex, "");
  const hasCredit = session?.user?.data?.creditsBestai !== undefined && session?.user?.data?.creditsBestai > 0;
  const handleGenerateImage = async () => {
    setIsGeneratingImage(true);
    try {
      const token = await getToken(session, "GenerateImage");
      const imageURLResponse = await fetch("https://us-central1-ez4cast.cloudfunctions.net/imageGeneration-generateImageFromPost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          tokenUserId: session?.user.uid || "",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          "postContent": contentWithoutMedia,
          "postType": "Linkedin Post",
          "style": style === "Default" ? "Flat Design" : style,
          "isInfluentLeader": true,
        }),
      });
      let generatedImage = await imageURLResponse.json();
      if (generatedImage.success === 0)
        throw new Error(generatedImage.message);
      onImageGenerated(generatedImage.fileName, generatedImage.url);
      await useCredit(
        session,
        mainContext,
        "creditsBestai",
        false
      );
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while generating image, please try again later.");
    }
    setIsGeneratingImage(false);
  }
  if (sessionLoading) return null;
  if (isGeneratingImage) return <ProgressBar maxProgress={97} duration={30} />
  const canGenerateImage = contentWithoutMedia.length > 50;
  return (
    <VStack mb={4}>
      <AiButton onClick={handleGenerateImage} isDisabled={!canGenerateImage || !hasCredit}
        containerProps={{ h: "30px", }} size="sm" fontSize="xs" p={3} h="30px" iconProps={{ width: 3, height: 3 }}
      >Generate Image</AiButton>
      <Menu
        variant="Ai"
        strategy="fixed"
      >
        <MenuButton as={Button} variant="ghost" size="xs" fontSize="xs" leftIcon={<FiImage />} rightIcon={<ChevronDownIcon />} isDisabled={!canGenerateImage || !hasCredit}>
          Style: {style}
        </MenuButton>
        <MenuList fontSize="xs">
          {styles.map((style, index) => <MenuItem key={index} onClick={() => setStyle(style)}>{style}</MenuItem>)}
        </MenuList>
      </Menu>
      {!canGenerateImage && hasCredit && <Text fontSize="xs" textAlign="center" color="gray.500">Your post needs to be at least 50 characters long for AI to be able to generate a relevant image.</Text>}
      {!hasCredit && <Text fontSize="xs" textAlign="center" color="gray.500">You don't have enough credit to generate an image</Text>}
    </VStack>
  )
}


export default GenerateImage;