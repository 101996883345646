import React, { useEffect } from "react";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Image,
  VStack,
  theme,
  Wrap,
  WrapItem,
  Tag,
  Link,
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import ThreadTweet from "./threadTweet";
import { getAccount } from "../../../utils/sessionHelper";
import { AiOutlineRetweet } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { MdLeaderboard } from "react-icons/md";
import { TweetContext } from "context/tweetContext";
import { textStyle } from "theme/names";
import { BsPen } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import dateFormat from 'dateformat';

const Tweet = ({
  threadTweets,
  image = undefined,
  name = undefined,
  tweet = undefined as any,
  showOutline = false,
  maxW = "400px",
  showGenerateImage = false,
  showCanvaPDFNotice = false,
  ...rest
}) => {

  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const tweetLabels = getAccount(session)?.tweetLabels;

  const tweetContext: any = React.useContext(TweetContext);

  let nameFallback = name ?? getAccount(session)?.name;
  let imageFallback = image ?? getAccount(session)?.image;

  const buildReminders = () => {

    let nbReminders = 0;

    let isPreventLinkExpand = false;
    let isAutoRetweet = false;
    let isAutoPlug = false;
    let isDelayBetweenTweets = false;
    let isAutoDm = false;
    let dmString = "";
    let isAutoRtWithOtherAccounts = false;
    let isLiveTweet = false;
    let isPushTh = false;
    let selectedLabels: any[] = [];

    if (tweet?.isPreventLinkExpand && tweet.text.includes("http")) {
      isPreventLinkExpand = true;
      nbReminders++;
    }
    if (tweet?.isAutoRetweet) {
      isAutoRetweet = true;
      nbReminders++;
    }
    if (tweet?.isAutoPlug) {
      isAutoPlug = true;
      nbReminders++;
    }
    if (tweet?.isDelayBetweenTweets && threadTweets.length > 1) {
      isDelayBetweenTweets = true;
      nbReminders++;
    }
    if (tweet?.isAutoDm) {
      isAutoDm = true;
      nbReminders++;
      let triggers = [] as any;
      if (tweet?.autoDmTrigger?.retweet) triggers.push("retweet");
      if (tweet?.autoDmTrigger?.reply) triggers.push("reply");
      if (tweet?.autoDmTrigger?.like) triggers.push("like");
      dmString = triggers.join(" and ");
    }
    if (tweet?.autoRtWithOtherAccounts) {
      isAutoRtWithOtherAccounts = true;
      nbReminders++;
    }
    if (tweet?.isLiveTweet) {
      isLiveTweet = true;
      nbReminders++;
    }
    if (tweet?.isPushTh) {
      isPushTh = true;
      nbReminders++;
    }
    if (tweet?.labels?.length > 0) {
      selectedLabels = tweetLabels?.filter(tl => tweet?.labels?.includes(tl.id));
      nbReminders++;
    }
    if (tweet?.ghostwriterTwUserName) {
      nbReminders++;
    }

    // console.log(tweet);

    if (nbReminders > 0)
      return (
        <Stack mt={5} mb={5} w="full" spacing="4">
          {
            isPreventLinkExpand && (
              <Text textStyle={textStyle["body.medium.light"]} fontWeight="400">🔗 &nbsp;URLs will not be expanded</Text>
            )
          }
          {
            isAutoRetweet && (
              <Text textStyle={textStyle["body.medium.light"]} fontWeight="400">♻️ &nbsp;Will be auto-retweeted {tweet.autoRetweetTimes > 1 ? tweet.autoRetweetTimes + " times every" : " in"} {tweet.autoRetweetHours} hour{tweet.autoRetweetHours > 1 ? "s" : ""}</Text>
            )
          }
          {
            isAutoPlug && !tweet.isPluggedTimeBased && (
              <Text textStyle={textStyle["body.medium.light"]} fontWeight="400">🔥 A reply will be plugged if this one gets {tweet.autoPlugTrigger} likes</Text>
            )
          }
          {
            isAutoPlug && tweet.isPluggedTimeBased && (
              <Box>
                <Text textStyle={textStyle["body.medium.light"]} fontWeight="400">🔥 A reply will be plugged {tweet.autoPlugHours === 0 ? "immediately" : "after " + (tweet.autoPlugHours < 1 ? Math.round(tweet.autoPlugHours * 60) + " minutes" : tweet.autoPlugHours + (!tweet.autoPlugHours || tweet.autoPlugHours == 1 ? " hour" : " hours"))}</Text>
                <Box
                  border="1px"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  rounded="lg"
                  mt="4"
                  p="4"
                >
                  <ThreadTweet
                    text={tweet.autoPlugText}
                    name={nameFallback}
                    image={imageFallback}
                    isLast={true}
                    disableNbChar={false}
                  />
                </Box>
              </Box>
            )
          }
          {
            isDelayBetweenTweets && (
              <Text textStyle={textStyle["body.medium.light"]} fontWeight="400">⌛️ &nbsp;Each tweet to be posted with a delay of {tweet.delayBetweenTweetsMinutes} minute{tweet.delayBetweenTweetsMinutes > 1 ? "s" : ""}</Text>
            )
          }
          {
            isAutoDm && (
              <Box>
                <Text textStyle={textStyle["body.medium.light"]} fontWeight="400" textAlign="left">🗨️ &nbsp;This DM will be sent to: {dmString}</Text>
                {tweet.isAutoDmRandom && (
                  <Flex textAlign="left" gap={2} textStyle="body.medium.light" alignItems={"center"}>
                    {tweet.autoDmMax} DMs will be sent randomly after 24h
                  </Flex>
                )}
                <Box
                  border="1px"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  rounded="lg"
                  mt="4"
                  p="4"
                >
                  <ThreadTweet
                    text={tweet.autoDmText}
                    name={nameFallback}
                    image={imageFallback}
                    isLast={true}
                    disableNbChar={true}
                  />
                </Box>
              </Box>
            )
          }
          {
            isAutoRtWithOtherAccounts && (
              <Flex alignItems="center" justifyContent="left">
                <AiOutlineRetweet />
                <Text textStyle={textStyle["body.medium.light"]} fontWeight="400" textAlign="left" ml="2">will be auto retweeted with other accounts</Text>
              </Flex>
            )
          }
          {
            isLiveTweet && (
              <Flex alignItems="center" justifyContent="left">
                <MdLeaderboard />
                <Text textStyle={textStyle["body.medium.light"]} fontWeight="400" textAlign="center" ml="2">A live leaderboard will be plugged</Text>
              </Flex>
            )
          }
          {
            tweet?.isPushTh && (
              <Flex alignItems="center" justifyContent="left">
                <FiTwitter />
                <Text textStyle={textStyle["body.medium.light"]} fontWeight="400" textAlign="center" ml="2">Will be {tweet.isScheduled ? "scheduled for Twitter" : "published now on Twitter"} with Tweet Hunter</Text>
              </Flex>
            )
          }
          {
            selectedLabels?.length > 0 && (
              <Box>
                <Text fontWeight="400">Labels linked to this post:</Text>
                <Wrap spacing="2" mt="2">
                  {
                    selectedLabels.map((label, index) => (
                      <WrapItem key={"label-" + index}>
                        <Tag color="white" bg={label.color + ".500"} fontWeight="normal">{label.title}</Tag>
                      </WrapItem>
                    )
                    )}
                </Wrap>
              </Box>
            )
          }
          {
            tweet?.ghostwriterTwUserName && (
              <Flex flexDir="column">
                <Flex alignItems="center" justifyContent="left">
                  <BsPen />
                  <Text fontWeight="400" textAlign="center" ml="2">Written by <Link href={"https://www.linkedin.com/in/" + tweet?.ghostwriterTwUserName}>{tweet?.ghostwriterName ?? "@" + tweet?.ghostwriterTwUserName}</Link></Text>
                </Flex>
                <Flex alignItems="center" justifyContent="left" mt={4}>
                  <BiTimeFive />
                  <Text fontWeight="400" textAlign="center" ml="2">Pushed on {dateFormat(tweet.datePushedToCreator ? (tweet.datePushedToCreator?.toDate ? tweet.datePushedToCreator?.toDate() : new Date(tweet.datePushedToCreator?._seconds * 1000)) : (tweet.dateCreated?.toDate ? tweet.dateCreated?.toDate() : new Date(tweet.dateCreated?._seconds * 1000)), "yyyy-mm-dd HH:MM")}</Text>
                </Flex>
              </Flex>
            )
          }
        </Stack>
      )
    else
      return (
        <>
        </>
      )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      w="100%"
      maxW={maxW}
      alignItems="center"
    >
      <VStack
        w="100%"
        // maxW="400px"
        spacing={0}
        p={4}
        m={showOutline ? 2 : 0}
        border={showOutline ? "solid 1px " + useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]) : "none"}
        rounded={showOutline ? "lg" : "0"}
      >
        {
          threadTweets?.map((threadTweet, index) => (
            <Box key={index} w="100%">
              <ThreadTweet
                name={nameFallback}
                image={imageFallback}
                text={threadTweet}
                isLast={index == threadTweets.length - 1}
                isNotThread={threadTweets.length === 1}
                {...rest}
                disableNbChar={tweet?.type == "tweet" ? false : true}
                showGenerateImage={showGenerateImage}
              />
            </Box>
          ))
        }
        {showCanvaPDFNotice &&
          (
            <Box w={"100%"} position={"relative"}>
              <Text color={"#C70100"} fontSize="sm" ml={12} >
                If you uploaded a PDF made with Canva or a similar tool, <strong><Link isExternal={true} href="https://smallpdf.com" target={"blank"}>please consider using SmallPDF</Link></strong> to compress your PDF/Carousel before scheduling your post. Errors may happen when posting PDFs made with Canva or similar service.
              </Text>
            </Box>
          )
        }
      </VStack>
      {
        tweet !== undefined && (
          buildReminders()
        )
      }
    </Box>
  );
};

export default Tweet;
