import { createContext, useState, useContext, useEffect } from "react";

const MiscellaneousContext = createContext({});

export function MiscellaneousWrapper({ children }) {
  const [crmEngage, setCrmEngage] = useState<string>("");
  const [crmDm, setCrmDm] = useState<any[]>([]);
  const [isPersonSidebarOpen, setIsPersonSidebarOpen] =
    useState<boolean>(false);
  const [sidebarPerson, setSidebarPerson] = useState<any>();
  const [newListMembers, setNewListMembers] = useState<any[]>([]);
  const [refreshLabels, labelsRefresher] = useState({});
  const [isDataRefreshing, setIsDataRefreshing] = useState(false);
  const sharedState = {
    crmEngage,
    setCrmEngage,
    crmDm,
    setCrmDm,
    isPersonSidebarOpen,
    setIsPersonSidebarOpen,
    sidebarPerson,
    setSidebarPerson,
    newListMembers,
    setNewListMembers,
    refreshLabels,
    labelsRefresher,
    isDataRefreshing,
    setIsDataRefreshing,
  };

  useEffect(() => {
    if (!sidebarPerson) {
      setIsPersonSidebarOpen(false);
    }
  }, [sidebarPerson]);

  return (
    <MiscellaneousContext.Provider value={sharedState}>
      {children}
    </MiscellaneousContext.Provider>
  );
}

export function useMiscellaneousContext() {
  return useContext(MiscellaneousContext);
}
