export function MagnetIcon() {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0381 22.3639L24.0608 18.4119C23.7322 18.0832 23.2013 18.0832 22.8727 18.4119L19.0387 22.2459L14.7749 26.5013C13.0559 28.2203 10.2583 28.2203 8.53929 26.5013C6.82029 24.7823 6.82029 21.9847 8.53929 20.2657L13.0475 15.7575L16.6371 12.1679C16.9658 11.8392 16.9658 11.3084 16.6371 10.9797L12.6767 7.01929C12.3481 6.69065 11.8172 6.69065 11.4886 7.01929L7.89888 10.609L3.39072 15.1171C-1.15957 19.7011 -1.12586 27.108 3.46656 31.6583C8.02528 36.1749 15.3816 36.1749 19.9403 31.6583L24.2041 27.3945L28.0381 23.552C28.3669 23.2234 28.3669 22.6925 28.0381 22.3639ZM12.0784 8.8057L14.8507 11.578L12.4492 13.9711L9.67687 11.1988L12.0784 8.8057ZM18.7353 30.4701C14.8254 34.38 8.48874 34.38 4.57885 30.4701C0.668974 26.5603 0.668974 20.2236 4.57885 16.3137L8.48874 12.4038L11.2526 15.1677L7.34274 19.0776C4.96647 21.4538 4.96647 25.3131 7.34274 27.6978C9.719 30.0825 13.5783 30.0741 15.963 27.6978L19.6285 24.0323L22.4008 26.8046L18.7353 30.4701ZM23.589 25.6165L20.8167 22.8442L23.4626 20.1983L26.235 22.9706L23.589 25.6165Z"
        fill="white"
      />
      <path
        d="M20.7577 2.38467C20.4375 2.04761 19.8982 2.03919 19.5695 2.35939C19.5611 2.36782 19.5527 2.37625 19.5443 2.38467L17.0585 4.87048C16.7298 5.19911 16.7298 5.72998 17.0585 6.05861C17.2101 6.21029 17.4208 6.30298 17.6483 6.30298C17.8758 6.30298 18.0865 6.21029 18.2466 6.05861L20.7324 3.5728C21.0695 3.2526 21.078 2.7133 20.7577 2.38467Z"
        fill="white"
      />
      <path
        d="M22.148 8.78035H18.6342C18.1707 8.78035 17.7915 9.15954 17.7915 9.623C17.7915 10.0865 18.1707 10.4656 18.6342 10.4656H22.148C22.6115 10.4656 22.9907 10.0865 22.9907 9.623C22.9907 9.15954 22.6115 8.78035 22.148 8.78035Z"
        fill="white"
      />
      <path
        d="M14.2861 0C13.8227 0 13.4435 0.379191 13.4435 0.842646V4.35648C13.4435 4.82836 13.8227 5.19913 14.2861 5.19913C14.7496 5.19913 15.1288 4.81994 15.1288 4.35648V0.842646C15.1288 0.379191 14.7496 0 14.2861 0Z"
        fill="white"
      />
      <path
        d="M32.5294 14.1817C32.2008 13.8531 31.6699 13.8531 31.3413 14.1817L28.8555 16.6676C28.5268 16.9962 28.5268 17.5355 28.8639 17.8557C29.1925 18.1843 29.7234 18.1843 30.052 17.8557L32.5294 15.3699C32.8581 15.0412 32.8581 14.5104 32.5294 14.1817Z"
        fill="white"
      />
      <path
        d="M34.004 20.2403H30.4902C30.0267 20.2403 29.6476 20.6195 29.6476 21.083C29.6476 21.5464 30.0267 21.9256 30.4902 21.9256H34.004C34.4675 21.9256 34.8467 21.5464 34.8467 21.083C34.8467 20.6195 34.4675 20.2403 34.004 20.2403Z"
        fill="white"
      />
      <path
        d="M26.0832 11.856C25.6197 11.856 25.2405 12.2352 25.2405 12.6987V16.2125C25.2405 16.6844 25.6197 17.0552 26.0832 17.0552C26.5466 17.0552 26.9258 16.676 26.9258 16.2125V12.6987C26.9258 12.2352 26.5466 11.856 26.0832 11.856Z"
        fill="white"
      />
    </svg>
  );
}
