import {
  Box,
  Center,
  Stack,
  Text,
  Link,
} from "@chakra-ui/layout";

import {
  Switch,
  useColorModeValue as mode,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  FormControl,
  Input,
  ModalCloseButton,
  FormLabel,
  ModalFooter,
  Img,
  Button,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import SearchBar from "components/search-bar";
import Tweets from "components/tweets";
import { CustomHead } from "components/custom-head";
import React, { useEffect, memo } from "react";
import { useSession } from "next-auth/react";
import { firebaseClient } from "../../firebaseClient";
import { getAccount } from "../../utils/sessionHelper";
import { useRouter } from "next/dist/client/router";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { ModalInfoIcon } from "./ModalInfoIcon";
import { textStyle, variant } from "theme/names";

export const ConfirmPopup = ({
  isOpen,
  onClose,
  title,
  body,
  callback,
  cta = "Confirm",
  ctaProps = {},
  allowClickBackground = false,
  disableCancel = false,
  destructive = false,
  showTitleInfoIcon = false,
  customAlternateAction: CustomAlternateAction = undefined as any,
}) => {
  const initialRef = React.useRef<HTMLInputElement>(null);
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={allowClickBackground}
      //@ts-ignore
      initialFocusRef={initialRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showTitleInfoIcon ? <ModalInfoIcon /> : null}
          <Box display="flex" justifyContent="space-between">
            <Text >{title}</Text>
          </Box>
        </ModalHeader>
        {
          !disableCancel && (
            <ModalCloseButton />
          )
        }
        <ModalBody>
          {Array.isArray(body)
            ? body.map((item, index) => (
              <Text textStyle={textStyle["body.medium.light"]} key={index} mb={4}>
                {item}
              </Text>
            ))
            : body}
        </ModalBody>

        <ModalFooter>
          {!disableCancel && (
            // @ts-ignore 
            CustomAlternateAction ? <CustomAlternateAction callback={onClose} /> : (
              <Button
                variant="tertiary"
                onClick={onClose}>
                Cancel
              </Button>
            )
          )}
          <Button
            variant={destructive ? variant.Button.primaryDestructive : variant.Button.primary}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              callback && (await callback());
              onClose();
              setIsLoading(false);
            }}
            {...ctaProps}
          >
            {cta}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
