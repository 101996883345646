import { Grid, Link, Text } from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import NextLink from 'next/link'
import { getAccount } from "utils/sessionHelper";

export const CustomToast = ({ useCreditBoolean }) => {
  const { data: session } = useSession() ?? {};

    if (useCreditBoolean) {
      return (
        <Grid>
          <Text>Generating with GPT-4</Text>
          <Link as={NextLink} href="/settings#search-settings">
            <Text
              {...{
                fontSize: "xs",
              }}
            >
              Increase speed by switching to GPT-3.5
            </Text>
          </Link>
        </Grid>
      );
    }
    if (!useCreditBoolean) {
      if (getAccount(session)?.forceUseFastModel) {
        return (
          <Grid>
            <Text>Generating with GPT-3.5</Text>
            <Link as={NextLink} href="/settings#search-settings">
              <Text {...{
                fontSize: "xs",
              }}>
                increase quality by switching to GPT-4
              </Text>
            </Link>
          </Grid>
        )
      }
      return (
        <Grid>
          <Text>Generating with GPT-3.5</Text>
          <Link as={NextLink} href="/pricing">
            <Text {...{
              fontSize: "xs",
            }}>
              increase quality by upgrading
            </Text>
          </Link>
        </Grid>
      )
    }
    return <Text>Loading...</Text>
  }