import {
    Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { textStyle, variant } from "theme/names";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { ModalInfoIcon } from "./ModalInfoIcon";

export const GetExtension = ({ isOpen, onClose, showCloseButton = true }) => {

    const initialRef = React.useRef<HTMLInputElement>(null);
    const isAuthenticated = useFirebaseUser();
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"


    const router = useRouter();

    const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {/* <ModalInfoIcon /> */}
            Download extension to continue
          </ModalHeader>
          {showCloseButton && <ModalCloseButton />}
          <ModalBody pb={6}>
            <Text textStyle={textStyle["body.medium.light"]}>
              This action requires that you download the Chrome Extention.
            </Text>
            <Text textStyle={textStyle["body.medium.light"]} mt={4}>
              It's a safe and simple step to enjoy 100% of the app.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                router.push("/connect");
                onClose();
              }}
              variant={variant.Button.primary}
              //   colorScheme="blue"
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
}
