import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { CampaignPopup } from "components/campaign-popup";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { planAndTargetUsersMapping, useCampaign } from "hooks/useCampaign";
import { getAccount, updateUser } from "utils/sessionHelper";
import { getPlan } from "controllers/subscription";

const CampaignContext = createContext({});

export function CampaignWrapper({ children }) {
  const [hasShownPopup, setHasShownPopup] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: session } = useSession() ?? {};
  const isAuthenticated = useFirebaseUser();
  const { activeCampaign } = useCampaign();
  const router = useRouter();
  const isPayingCustomer = !getAccount(session)?.subStatus?.includes("FreeTrial");
  const isTargettedUser = activeCampaign?.showItTo?.some(s => planAndTargetUsersMapping[s] === getPlan(session?.user)) || false;

  // show campaign on app load
  useEffect(() => {
    if (session && isAuthenticated && !hasShownPopup) {
      let timeout = 4000;
      let isDismissed = false;
      let isOnDatabasePage = router.pathname.includes("database");
      const dismissedCampaigns = getAccount(session)?.dismissedCampaigns ?? [];
      // console.log('dismissedCampaigns:', dismissedCampaigns)
      // console.log('activeCampaign:', activeCampaign)
      if (activeCampaign?.type === "feature release") {
        isDismissed = dismissedCampaigns.includes(
          activeCampaign.id + "-" + moment().format("YYYYMMDD")
        );
      } else if (activeCampaign?.type === "regular") {
        isDismissed = dismissedCampaigns.includes(activeCampaign.id);
      }
      
      // console.log('isDismissed:', isDismissed)
      // console.log('isPayingCustomer:', isPayingCustomer)
      // console.log('isTargettedUser:', isTargettedUser)

      if (isOnDatabasePage) {
        timeout = 100;
      }

      if (
        isPayingCustomer &&
        isTargettedUser &&
        activeCampaign &&
        (!isDismissed || isOnDatabasePage) &&
        !router.pathname.includes("pricing")
      ) {
        setTimeout(() => {
          setHasShownPopup(true);
          onOpen();
        }, timeout);
      }
    }
  }, [session, isAuthenticated, activeCampaign, isPayingCustomer, router.pathname]);

  const sharedState = {
    isOpen,
    onOpen,
    onClose,
  };

  return (
    <CampaignContext.Provider value={sharedState}>
      {children}
      <CampaignPopup
        isOpen={isOpen}
        onClose={onClose}
        onCloseClick={async () => {
          let dismissedCampaigns = getAccount(session)?.dismissedCampaigns ?? [];
          if (activeCampaign?.type === "feature release") {
            dismissedCampaigns.push(
              activeCampaign.id + "-" + moment().format("YYYYMMDD")
            );
          } else {
            dismissedCampaigns.push(activeCampaign?.id);
          }
          updateUser(session, { dismissedCampaigns });
        }}
      />
    </CampaignContext.Provider>
  );
}

export function useCampaignContext() {
  return useContext(CampaignContext);
}
