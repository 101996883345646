import {
  Box,
  Text,
  VStack,
  Flex,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { GRADIENT_COLOR, homeScreenSections, Prompt } from "./utils";

interface Props {
  onSelectPrompt: (prompt: Prompt) => void;
}

export function HomeScreen({ onSelectPrompt }: Props) {
  const actionContainerBg = useColorModeValue("#FEFCFF", "#1a202c");

  return (
    <>
      <Box mb="8">
        <Text
          bgGradient={GRADIENT_COLOR}
          bgClip="text"
          fontWeight="extrabold"
          fontSize="2xl"
          textAlign="center"
        >
          Welcome to Chat Assist
        </Text>
        <Text textAlign="center" maxW="450px" mx="auto" mt="1.5">
          Your{" "}
          <Text
            as="span"
            bgClip="text"
            bgGradient={GRADIENT_COLOR}
            fontWeight="semibold"
          >
            AI-powered
          </Text>{" "}
          chat assistant to help you create better LinkedIn posts, replies and
          DMs.
        </Text>
      </Box>

      <Box overflowY="auto" flexGrow="1" className="noScrollBar">
        <VStack spacing={3} width="100%" h="fit-content" my="auto">
          {homeScreenSections.map((section) => (
            <Box
              key={section.title}
              bg={actionContainerBg}
              w="100%"
              px="4"
              py="2"
            >
              <Flex>
                <Box w="9%" minW="fit-content">
                  <Button
                    p="1"
                    mt="-1"
                    size="sm"
                    border="2px solid"
                    borderColor="transparent"
                    colorScheme="none"
                    cursor="default"
                    bg={`linear-gradient(${actionContainerBg}, ${actionContainerBg}) padding-box,linear-gradient(93.67deg, #EC6181 3.32%, #5C69E3 97.43%) border-box`}
                  >
                    {section.icon}
                  </Button>
                </Box>
                <Box w="91%">
                  <Text
                    bgGradient={GRADIENT_COLOR}
                    bgClip="text"
                    fontWeight="bold"
                  >
                    {section.title}
                  </Text>
                  <Box fontSize="sm" mt="2" lineHeight="1.5">
                    {section.prompts.map((p, index) => (
                      <Text
                        key={index}
                        w="fit-content"
                        _hover={{ textDecor: "underline", cursor: "pointer" }}
                        onClick={() => onSelectPrompt(p)}
                      >
                        {p.label}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Flex>
            </Box>
          ))}
        </VStack>
      </Box>
    </>
  );
}
