import firebaseClient from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import * as Sentry from "@sentry/nextjs";

/*

Copy/paste your *client-side* Firebase credentials below. 

To get these, go to the Firebase Console > open your project > Gear Icon >
Project Settings > General > Your apps. If you haven't created a web app
already, click the "</>" icon, name your app, and copy/paste the snippet.
Otherwise, go to Firebase SDK Snippet > click the "Config" radio button >
copy/paste.

*/
const CLIENT_CONFIG = {
  apiKey: "AIzaSyCwNyHxbr1j9-qWSAR-aXQEN2Sty9dsCkI",
  authDomain: "influent-leaders.firebaseapp.com",
  projectId: "influent-leaders",
  storageBucket: "influent-leaders.appspot.com",
  messagingSenderId: "722190148700",
  appId: "1:722190148700:web:bf067434d0c86b38e03299",
  measurementId: "G-7WREE7X47Q"
};

let isForceServer = false;
// let i=3;
// i="3"; // to prevent deploy 

// if (typeof window !== "undefined" && !firebaseClient.apps.length) {
if (!firebaseClient.apps.length) {
  // console.log("init Firebase app");
  firebaseClient.initializeApp(CLIENT_CONFIG);
  firebaseClient.firestore().settings({ ignoreUndefinedProperties: true });

  let isLocal = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !isForceServer;
  // console.log("isLocal: " + isLocal);

  // if (location.hostname === "localhost") {
  if (isLocal) {
    // console.log("Use emulator DB");
    // var db = firebaseClient.firestore();
    // db.useEmulator("localhost", 8080);
  }

  // firebaseClient
  //   .auth()
  //   .setPersistence(firebaseClient.auth.Auth.Persistence.SESSION);
  // (window as any).firebase = firebaseClient;

}

const firebaseSignOut = async function () {

  try {
    await firebaseClient.auth().signOut();
  }
  catch (e) {
    console.log("Error in firebaseSignOut: " + e.message);
  }
}

const firebaseAuth = async function (session, source = "tweetContext") {

  try {
    var user = firebaseClient.auth().currentUser;

    if (!user) {
      await firebaseSignIn(session);
    }
    else {
      console.log("user already authenticated: " + user.uid);
      if (user.uid !== session?.user?.uid) {
        console.log("error, wrong authentification");
        await firebaseClient.auth().signOut();
        await firebaseSignIn(session);
      }
    }
  }
  catch (e) {
    console.error("Error in firebaseAuth: " + e.message, { e, session });
  }
}

const firebaseSignIn = async (session) => {

  let user;

  try {
    firebaseClient.auth().setPersistence(firebaseClient.auth.Auth.Persistence.NONE);

    let userCredential = await firebaseClient.auth().signInWithCustomToken(session?.user?.firebaseToken as string);
    // Signed in
    user = userCredential.user;
    // console.log("firebaseAuth - user authenticated: " + JSON.stringify(user));
    // if (user) {
    //   console.log("firebaseAuth - user authenticated: " + user.uid);
    // }
    // else {
    //   console.error("firebaseAuth - user authenticated but user is null");
    // }
  }
  catch (error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log("firebaseAuth error: " + error.message, JSON.stringify({ error, session }));
    Sentry.captureException(error);
  };

  return user;
}

const getToken = async (session, source = "unknown") => {
  if (firebaseClient?.auth()?.currentUser?.getIdToken) {
    //@ts-ignore
    let token = await firebaseClient.auth().currentUser.getIdToken();
    return token;
  }
  else {
    console.log("getToken error: impossible to access to auth user, trying to sign firebase - source: " + source);
    let user = await firebaseSignIn(session);
    if (user) {
      let token = await user.getIdToken();
      return token;
    }
    else
      return "";
  }
}

export { firebaseClient, firebaseAuth, getToken, firebaseSignOut };
