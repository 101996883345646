import { 
    Box,
    Link,
    Text,
    Heading,
    Image
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { layerStyle, radius, textStyle } from "theme/names";
import { CarouselPreview } from "./carouselPreview";

interface Props {
    docDetails: {
        document: any,
        hrefLink: any,
        carousel_document_link: any,
  };
};

export function DocumentPreview({ docDetails }: Props) {
    const docTitle = docDetails?.document?.title;
    const coverPage = docDetails?.document?.coverPages?.pagesPerResolution?.[0]?.imageUrls?.[0];
    docDetails.carousel_document_link = docDetails?.carousel_document_link || docDetails?.document?.transcribedDocumentUrl;
    
    return (
        (docDetails?.carousel_document_link || docDetails?.carousel_document_link?.indexOf('pdf'))
        ?
            <CarouselPreview docURL={docDetails?.carousel_document_link} docName={docTitle || ""}/>
        : 
            <Link
                href={docDetails?.hrefLink}
                isExternal
                w="100%"
                title={docTitle}
            >
                <Box
                    //    w="100%"
                    //    borderWidth='1px'
                    //    borderRadius='sm'
                    //    overflow='hidden'
                    //    bg='gray.200'
                    //    display="flex"
                    //    flexDirection="column"
                    //    alignItems="start"
                   layerStyle={layerStyle.bgBorderGray}
                   borderRadius={radius.sm}
                   w="100%"
                   // borderWidth='1px'
                   // borderRadius='sm'
                   overflow='hidden'
                   // bg='gray.200'
                //    p={3}
                   display="flex"
                   flexDirection="column"
                   alignItems="start"
                >
                    <Image
                        src={coverPage}
                        alt={docTitle}
                        borderTopStartRadius="sm"
                        fit="cover"
                        h="100%" 
                        onError={(e)=>{
                            //remove img if the image doesn't load for whatever context...
                            //@ts-ignore
                            if(e?.target!=undefined) e?.target?.remove();
                        }}
                    />
                    <Box
                        flexDirection="row"
                        w="100%"
                        pl={3}
                        pt={3}
                    >
                        <Text textStyle={textStyle["body.medium.light"]}
                            display="inline-block"
                            sx={{fontSize:'0.9rem'}}
                        >
                            Document:
                        </Text>
                        <BiLinkExternal
                            size="18px"
                            title="Open external link"
                            style={{display:"inline-block", paddingLeft:"3px", verticalAlign:"middle"}}
                        />
                    </Box>
                    <Heading
                        fontSize="sm"
                        w="100%"
                        pl={3}
                        pb={3}
                    >
                        {docTitle}
                    </Heading>
                </Box>
            </Link>
    )
}
