import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Box,
  Icon,
  Text,
  Divider,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Center,
} from "@chakra-ui/react";
import { IconType } from "react-icons/lib";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { ImRocket } from "react-icons/im";
import { TimeCounter } from "components/countdown/time-counter";
import { useCountdown } from "hooks/useCountdown";
import { Campaign, useCampaign } from "hooks/useCampaign";
import { MagnetIcon } from "./magnet-icon";
import { BsChatLeftText, BsLightningCharge } from "react-icons/bs";
import { useSubscription } from "hooks/useSubscription";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseClick?: () => void;
}

export function CampaignPopup({ isOpen, onClose, onCloseClick }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="radial-gradient(59.33% 59.33% at 50% 89.34%, rgba(104, 197, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, #641BC1 0%, #0E8EDA 100%)"
        justifyContent="center"
        display="flex"
        alignItems="center"
        _after={{ height: 0 }}
        _before={{ height: 0 }}
        p="4"
      >
        <ModalCloseButton
          onClick={onCloseClick}
          bg="transparent !important"
          borderColor="white !important"
          border="0.5px solid !important"
          _focus={{ boxShadow: "none" }}
          color="white"
        />
        <ModalBody textAlign="center" color="white" border="none">
          <CampaignContent onFinishUpgrade={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

interface CampaignContentProps {
  onFinishUpgrade?: () => void;
  forcedCampaign?: Campaign;
  mode?: "action" | "preview";
}

export function CampaignContent({
  onFinishUpgrade = () => { },
  forcedCampaign,
  mode = "action",
}: CampaignContentProps) {
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const { activeCampaign: savedCampaign, updateUserAcquisition } = useCampaign();
  const activeCampaign = forcedCampaign || savedCampaign;
  const { updatePlan } = useSubscription();
  const { days, hours, minutes, seconds } = useCountdown(
    // @ts-ignore
    activeCampaign?.endDate.toDate
      ? // @ts-ignore
      activeCampaign.endDate.toDate()
      : activeCampaign?.endDate
  );
  const isCampaignActive = !!activeCampaign && (seconds > 0 || minutes > 0 || hours > 0 || days > 0);

  let title: any = (
    <Text as="span" fontWeight="bold">
      {activeCampaign?.featureTitle || ""}
    </Text>
  );
  let description = activeCampaign?.featureDescription || "";

  // console.log('activeCampaign:', activeCampaign)

  const handleUpgrade = async (isCampaignActive = false) => {
    try {
      setIsUpgrading(true);
      const isSuccess = await updatePlan("enterpriseMonthly", "ENTERPRISE99");

      if (isSuccess) {
        updateUserAcquisition();
        onFinishUpgrade();
      }
    } finally {
      setIsUpgrading(false);
    }
  };

  const counterUI = isCampaignActive && (
    <Flex justifyContent="center">
      <Flex
        justifyContent="center"
        borderRadius="md"
        color="#0E8EDA"
        bg="white"
        py="2.5"
        px="5"
        w="fit-content"
      >
        {days > 0 && <TimeCounter value={days} />}
        {(hours > 0 || days > 0) && <TimeCounter value={hours} />}
        {(minutes > 0 || hours > 0 || days > 0) && (
          <TimeCounter value={minutes} />
        )}
        <TimeCounter value={seconds} endsWithColon={false} />
      </Flex>
    </Flex>
  );

  return (
    <Box>
      <Text
        fontWeight="semibold"
        display="block"
        color="white"
        fontSize="2xl"
        mt="8"
        mb="6"
      >
        {title}
      </Text>
      <Center>
        <Text maxW="72">{description}</Text>
      </Center>
      {isCampaignActive && activeCampaign?.type === "regular" ? (
        <Box mt="5">{counterUI}</Box>
      ) : (
        <Center>
          <Text
            fontSize="sm"
            border="1px solid white"
            borderRadius="lg"
            width="fit-content"
            px="6"
            py="1.5"
            mt="5"
          >
            {activeCampaign?.secondaryCTA || "Pro subscribers only"}
          </Text>
        </Center>
      )}
      <Center>
        <Divider my="6" w="375px" />
      </Center>
      {isCampaignActive && activeCampaign?.type === "feature release" ? (
        <Box mb="5">
          <Text fontWeight="semibold" fontSize="sm" mb="3">
            Time remaining before the price goes up!
          </Text>
          {counterUI}
        </Box>
      ) : null}
      <Text fontWeight="normal" fontSize="md">
        Pro plan
      </Text>
      <Text
        as={isCampaignActive ? "s" : "p"}
        fontWeight="semibold"
        fontSize={isCampaignActive ? "xl" : "2xl"}
        mt="3"
        display="block"
      >
        $199 / mo
      </Text>
      <Text fontWeight="bold" fontSize="3xl" mt="4">
        $94/mo
      </Text>

      <Button
        bg="linear-gradient(180deg, #0A63C0 0%, #0E8EDA 100%)"
        border="2px solid white"
        borderRadius="lg"
        colorScheme="none"
        leftIcon={<ImRocket />}
        size="md"
        mt="6"
        px="6"
        py="5"
        onClick={() => {
          if (mode === "preview") {
            return;
          }
          handleUpgrade(isCampaignActive);
        }}
        isLoading={isUpgrading}
      >
        {activeCampaign?.primaryCTA || "Upgrade now!"}
      </Button>
      <Accordion allowToggle mt="1">
        <AccordionItem border="0" display="flex" justifyContent="center">
          {({ isExpanded }) => (
            <Box w="100%">
              <AccordionButton
                px="3"
                py="2"
                borderRadius="8"
                h="40px"
                display="flex"
                alignItems="center"
                fontWeight="500"
                fontSize="sm"
                mx="auto"
                _focus={{ boxShadow: "none", background: "none" }}
                _hover={{ boxShadow: "none", background: "none" }}
                justifyContent="center"
              >
                <Box width="fit-content" borderBottom="1px solid white">
                  <Text fontSize="sm" mb="-2px">
                    Pro plan features
                  </Text>
                </Box>
                <Icon
                  as={isExpanded ? AiFillCaretDown : AiFillCaretRight}
                  mt="1"
                  ml="2"
                  h="3"
                  w="3"
                  strokeWidth="1"
                  fontSize="md"
                />
              </AccordionButton>
              <AccordionPanel pt="3" pl="2" pb="0" pr="0" maxW="80%" mx="auto">
                <AccordianItem
                  svgIcon={<MagnetIcon />}
                  title="Database"
                  description="Find leads and people to engage with among millions of LinkedIn accounts with unique data."
                />
                <AccordianItem
                  icon={BsLightningCharge}
                  title="GPT-4 unleashed"
                  description="Get 5,000 GPT-4 credits per month so you can create as much LinkedIn content as you want."
                />
                <AccordianItem
                  icon={BsChatLeftText}
                  title="Highest CRM / engage limits"
                  description="Import 500 people per post / sales navigator search + comment on up to 500 posts per month."
                />
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

interface AccordianItemProps {
  icon?: IconType;
  svgIcon?: JSX.Element;
  title: string;
  description: string;
}

function AccordianItem({
  icon,
  title,
  description,
  svgIcon,
}: AccordianItemProps) {
  return (
    <Flex textAlign="start" fontSize="xs" mb="6">
      <Flex alignItems="center">
        {svgIcon ?? <Icon as={icon} h="7" w="7" fontSize="md" />}
      </Flex>
      <Box ml="3">
        <Text fontWeight="bold" fontSize="13px">
          {title}
        </Text>
        <Text fontWeight="light" fontSize="13px">
          {description}
        </Text>
      </Box>
    </Flex>
  );
}
