import React, { useEffect, useState, useRef, useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Image,
  theme,
  useDisclosure,
  HStack,
  Button,
  Link,
} from "@chakra-ui/react";
import twitter from "twitter-text";
import * as Sentry from "@sentry/nextjs";
import { getAccount } from "../../../utils/sessionHelper";
import { useSession } from "next-auth/react";
import urlRegex from "url-regex"
import { TwitterCard } from "components/twitter-card"
import Autolinker from 'autolinker';
import { isNumeric } from "utils/helpers"
import { getTweet } from "controllers/search"
import isAbsoluteUrl from "is-absolute-url";
import styled from "@emotion/styled";
import { useDebouncedCallback } from "use-debounce";
import { ImageEditPopup } from "components/popups/imageEditPopup";
import { FaMagic, FaTrash } from "react-icons/fa";
import { CarouselPreview } from "./carouselPreview";
import { MdOutlineFileDownload } from "react-icons/md";
import { color, textStyle } from "theme/names";
import { TweetContext } from "context/tweetContext";
import GenerateImage from "components/tweet-composer/generate-image";
import toast from "react-hot-toast"

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

const Tweet = ({
  text,
  image,
  name,
  isLast,
  isNotThread = false,
  disableNbChar = true,
  showSeeMoreDivider = false,
  dividerCss = {},
  dividerAfterLineCount = 5,
  displayImageOptions = false,
  showGenerateImage = false as boolean,
}) => {
  const tweetContext: any = useContext(TweetContext);
  const [data, setData] = useState<any>();
  const [tweetText, setTweetText] = useState(text);
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"

  const [medias, setMedias] = useState<any[]>([]);
  const [cardType, setCardType] = useState<"tweet" | "metacard" | "">("");
  const [nbChar, setNbChar] = useState<number>(0)
  const [currentPreviewUrl, setCurrentPreviewUrl] = useState<string>("");
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [lineCount, setLineCount] = useState<number>(0);
  const [marginTop, setMarginTop] = useState<number>(0);
  const [cachedMentions, setCachedMentions] = useState<any>([]);
  const [afterLineCount, setAfterLineCount] = useState<number>(dividerAfterLineCount);
  const tweetTextRef: any = useRef(null);
  const metaControllerRef = useRef<AbortController | null>();
  const tweetControllerRef = useRef<AbortController | null>();
  const mentionControllerRef = useRef<AbortController | null>();

  const bgMedia = useColorModeValue("gray.200", "gray.700");

  // useEffect(() => {
  //   getMentionsFromCacheOnly(tweetText);
  // }, [cachedMentions]);

  useEffect(() => {
    try {
      let textCount = text;
      let matchs = text.match(/(?:\[img:)(.*?)(?=\])/g);
      const medias: any[] = [];
      let tweetTextCopy = text;

      if (matchs && matchs.length > 0) {
        matchs.forEach(match => {
          textCount = textCount.replace(match + "]", "");
          match = match.replace("[img:", "");
          medias.push("https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + match);
          tweetTextCopy = tweetTextCopy.replace("[img:" + match + "]", "");
        });
      }
      setMedias(medias)

      let urls = text?.match(urlRegex({ strict: false }));

      urls = urls?.filter(url => {
        const indexOfUrl = text.indexOf(url)
        if ((text[indexOfUrl - 1]) === "@") {
          return false
        }

        return true
      })

      if (urls?.length > 0) {
        urls.forEach(url => {
          tweetTextCopy = tweetTextCopy.replace(url, formatUrl(url))
        })
      }

      tweetTextCopy = tweetTextCopy.replace("[tweet]", `https://twitter.com/${getAccount(session)?.twUserName}/status/...`);

      setTweetText(tweetTextCopy);

      if (medias.length === 0 && urls?.length > 0) {
        const twitterUrls = urls.filter(url => url.includes("twitter.com") && url.includes("/status/"))
        if (twitterUrls.length > 0) {
          const selectedUrl = twitterUrls[twitterUrls.length - 1];

          if (selectedUrl !== currentPreviewUrl) {
            setCurrentPreviewUrl(selectedUrl);
            let split = selectedUrl.split("/status/")
            if (isNumeric(split[split.length - 1])) {
              if (tweetControllerRef.current) {
                tweetControllerRef.current?.abort();
              }
              fetchTweet(split[split.length - 1], tweetTextCopy, selectedUrl);
            }
          } else {
            removeUrlIfLast(tweetTextCopy, selectedUrl)
          }
        } else {
          if (!getAccount(session)?.preventLinkExpand) {
            const selectedUrl = urls[urls.length - 1];

            if (selectedUrl !== currentPreviewUrl) {
              if (metaControllerRef.current) {
                metaControllerRef.current?.abort();
              }
              setCurrentPreviewUrl(selectedUrl)
              fetchMetaData(selectedUrl, tweetTextCopy)
            } else {
              removeUrlIfLast(tweetTextCopy, selectedUrl)
            }
          } else {
            setData(null);
            setCardType("");
            setCurrentPreviewUrl("");
          }
        }
      }


      getMentionsFromCacheOnly(tweetTextCopy);
      // fetchMentions(tweetTextCopy);
      debouncedFetchMention(tweetTextCopy);

      if (!urls || urls.length === 0) {
        if (metaControllerRef.current) {
          metaControllerRef.current?.abort();
        }
        if (tweetControllerRef.current) {
          tweetControllerRef.current?.abort();
        }
        setCurrentPreviewUrl("");
        setData(null);
        setCardType("");
      }
      setNbChar(twitter.parseTweet(tweetTextCopy).weightedLength);
    }
    catch (e) {
      console.log("Error in threadTweet: " + e.message);
      Sentry.captureException(e);
      setNbChar(twitter.parseTweet(text).weightedLength);
    }
  }, [text, getAccount(session)?.preventLinkExpand])

  const fetchTweet = async (tweetId: string, tweetText: string, url: string) => {
    const tweetController = new AbortController();
    tweetControllerRef.current = tweetController

    const tweet = await getTweet(session, tweetId, tweetController.signal);

    setData(tweet.tweet || {});
    setCardType("tweet");
    tweetControllerRef.current = null;

    removeUrlIfLast(tweetText, url);
  }

  const removeUrlIfLast = (tweetText: string, url: string) => {
    const trimText = tweetText.trim()
    const formattedUrl = formatUrl(url);
    const startLength = trimText.length - formattedUrl.length;

    if (trimText.trim().substring(startLength, trimText.length) === formattedUrl) {
      setTweetText(trimText.substring(0, startLength - 1));
    }
  }

  const fetchMentionsUnique = async (tweetTextCopy, mention) => {

    console.log("fetchMentionsUnique", mention);
    let newMention;

    let match;

    if (mentionControllerRef.current) {
      mentionControllerRef.current?.abort();
    }

    const mentionController = new AbortController();
    mentionControllerRef.current = mentionController;

    const response = await fetch(`https://us-central1-ez4cast.cloudfunctions.net/linkedinAutoComplete-search?search=${mention.toLowerCase()}`, {
      signal: mentionControllerRef.current?.signal,
    });
    const json = await response.json();
    if (json?.success)
      mentionControllerRef.current = null;
    if (json?.entity?.id) {
      match = json?.entity;
      // setCachedMentions([...cachedMentions, json?.entity]);
      newMention = json?.entity;
      if (mention.includes("company_")) {
        newMention.username = "company_" + newMention.username;
      }
    }
    else {
      console.log("no found, adding fake entity:", { username: "@" + mention, firstName: "@" + mention, });
      let fakeEntity = { username: mention, firstName: "@" + mention, isFake: true };
      match = fakeEntity;
      // setCachedMentions([...cachedMentions, fakeEntity]);
      newMention = fakeEntity;
    }

    // if (match && !match?.isFake) {
    //   updateMention(tweetTextCopy, mention, match);
    // }

    return newMention;
  }

  const getMentionsFromCacheOnly = async (tweetTextCopy: string, newCachedMentions = cachedMentions) => {

    if (tweetTextCopy?.includes("@")) {
      let mentions = tweetTextCopy?.match(/(^|\s+)\B@[A-Za-z0-9_.\-éèçâáàêîôóòùûëïüäöÉÈÀÇÙÂÊÎÔÛËÏÜÄÖ']+/g) ?? [];
      console.log('mentions:', mentions);

      for await (let mention of mentions) {
        // console.log("fetchMentions - checking mention: " + mention);
        mention = mention.replace("@", "").trim();
        // console.log(mention, 'check plain mention')

        // console.log("checking cache:", cachedMentions);
        let match = newCachedMentions.find((m: any) => m.username === mention);
        console.log(mention, ' match with :', match)

        if (match && !match?.isFake) {
          let updatedText = updateMention(tweetTextCopy, mention, match);
          tweetTextCopy = updatedText;
          // console.log('tweetTextCopy:', tweetTextCopy)
        }
      }
      setTweetText(tweetTextCopy);
    }
  }

  const fetchMentions = async (tweetTextCopy: string) => {

    // console.log("fetchMentions: " + tweetTextCopy);

    if (tweetTextCopy?.includes("@")) {
      let mentions = tweetTextCopy?.match(/\B@[A-Za-z0-9_.\-éèçâáàêîôóòùûëïüäöÉÈÀÇÙÂÊÎÔÛËÏÜÄÖ']+/g) ?? [];
      // console.log('mentions:', mentions);

      for await (let mention of mentions) {
        // console.log("fetchMentions - checking mention: " + mention);
        mention = mention.replace("@", "");

        // console.log("checking cache:", cachedMentions);
        let match = cachedMentions.find((m: any) => m.username === mention);
        // console.log('match:', match)

        if (!match) {
          let newMention = await fetchMentionsUnique(tweetTextCopy, mention);
          console.log('newMention:', newMention)
          cachedMentions.push(newMention);
          // tweetTextCopy = updatedText;
          // debouncedFetchMentionUnique(tweetTextCopy, mention);
        }
        // else if (!match?.isFake) {
        //   let updatedText = updateMention(tweetTextCopy, mention, match);
        //   tweetTextCopy = updatedText;
        // }
      }

      setCachedMentions([...cachedMentions]);
      getMentionsFromCacheOnly(tweetTextCopy, cachedMentions);
    }
  }

  const updateMention = (tweetTextCopy, mention, match) => {
    console.log('updateMention:', tweetTextCopy, mention)
    // let formattedMention = `<a href="${match?.profileUrl}" target="_blank" rel="noopener noreferrer">${match.firstName + (match.lastName ? " " + match.lastName : "")}</a>`;
    let realMention = mention?.replace("company_", "");
    let tooltipText = "Tip: use @company_" + realMention + " to force the mention to be about the company " + realMention + " and not a personal account";
    let formattedMention = `<div class="tooltip-mention"><span class="tooltip-mention-text" >${tooltipText}</span><a href="${match?.profileUrl}" target="_blank" rel="noopener noreferrer">${match.firstName + (match.lastName ? " " + match.lastName : "")}</a></div>`;
    tweetTextCopy = tweetTextCopy.replace("@" + mention, formattedMention);
    // setTweetText(tweetTextCopy);
    return tweetTextCopy;
  }

  const fetchMetaData = async (url: string, tweetTextCopy: string) => {
    try {
      const metaController = new AbortController();
      metaControllerRef.current = metaController;

      const response = await fetch("/api/metadata", {
        signal: metaControllerRef.current?.signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      const data = await response.json()
      if (data.success) {
        delete data.success;
        setCardType("metacard");
        setData(data);
        metaControllerRef.current = null;

        removeUrlIfLast(tweetTextCopy, url)
      } else {
        setData(null);
        setCardType("");
      }
    }
    catch (e) {
      console.log("Error in fetchMetaData: " + e.message, e);
      // updateTweet("cardData", null);
      // updateTweet("cardType", "");
      setData(null);
      setCardType("");
    }
  }

  const formatUrl = (url: string) => {
    try {
      if (!isAbsoluteUrl(url)) {
        return url;
      }
      const urlObject = new URL(url)

      let formattedUrl = urlObject.href.replace(`${urlObject.protocol}//`, "")
      if (formattedUrl.length > 35) {
        formattedUrl = formattedUrl.substring(0, 35) + "..."
      }
      return formattedUrl
    } catch (err) {
      console.log("Error in formatUrl with url: ", url);
      console.log("Error in formatUrl with error: ", err);
      Sentry.captureException(new Error("Error in formatUrl with url: " + url));
      return url;
    }
  }

  // calculate line count
  useEffect(() => {
    if (!showSeeMoreDivider) {
      return;
    }
    const lineHeight = +window
      .getComputedStyle(tweetTextRef.current)
      .getPropertyValue("line-height").replace("px", "");
    const divHeight = +tweetTextRef.current.offsetHeight;
    const marginTop = +window
      .getComputedStyle(tweetTextRef.current)
      .getPropertyValue("margin-top").replace("px", "");

    setLineHeight(lineHeight);
    setLineCount(divHeight / lineHeight)
    setMarginTop(marginTop)
  }, [tweetText, dividerAfterLineCount])

  // update see more divider position if links or medias are found
  useEffect(() => {
    if (data || medias.length > 0) {
      setAfterLineCount(3);
    } else {
      setAfterLineCount(dividerAfterLineCount)
    }
  }, [medias, data, lineCount, dividerAfterLineCount])

  // console.log(Autolinker.link(tweetText, {newWindow: true}));

  const displayMedia = (media) => {
    if (media.includes("vid-")) {
      return (
        <video style={{ marginBottom: "30px", borderRadius: "10px" }} controls>
          <source src={media} />
        </video>
      )
    }
    else if (media.includes("doc-")) {
      // console.log('media:', media)
      let splits = media.split("-");
      // console.log('splits:', splits.length)
      let docName = splits.length > 4 ? splits[splits.length - 2] : "Document name not defined";

      const mediaURL = splits?.slice(0, 3).join('-') + "-" + splits?.slice(-1).join();
      //console.table([mediaURL, media?.replace(docName + "-", "")])
      return (
        <Box rounded="md" bg={bgMedia} w={"full"}>
          <CarouselPreview docURL={mediaURL} docName={docName} />
          <Button m={1} size="sm" variant="outline" leftIcon={<MdOutlineFileDownload />} as={Link} isExternal href={mediaURL} fontSize={"md"} fontWeight={400} p={2}>
            Download document
          </Button>
        </Box>
      )
    }
    else {
      return (
        <ImageWithOptions
          mediaUrl={media}
          displayOptions={displayImageOptions}
        />
      )
    }
  }

  const handleGeneratedImage = async (fileName: string, link: string) => {

    if (tweetContext.refComposer && tweetContext.refComposer.current) {
      let textState = tweetContext?.refComposer?.current?.textState();
      textState.text += ` [img:${fileName}]`;
      tweetContext.refComposer.current.editText(textState.text);
    } else {
      console.error('handleGeneratedImage: tweetContext.refComposer.current is not initialized');
    }
  }
  const debouncedFetchMention = useDebouncedCallback(fetchMentions, 500);
  const debouncedFetchMentionUnique = useDebouncedCallback(fetchMentionsUnique, 500);

  const dividerColor = useColorModeValue(color["border.lightMode.hover"], color["border.darkMode.hover"]);

  return (
    <Flex justifyContent="start" w="100%" ml={0}>
      <Flex
        justifyContent="start"
        alignItems="center"
        flexDirection="column"
        m={0}
      >
        <Box>
          <Image
            width={10}
            height={10}
            borderRadius={20}
            ml={0}
            //@ts-ignore
            src={image}
          />
        </Box>
        {!isLast && (
          <Box
            m={2}
            bg={useColorModeValue("gray.200", "gray.600")}
            h="100%"
            w="2px"
          ></Box>
        )}
      </Flex>
      <Flex flexDirection="column" w="85%" m={2} position="relative">
        <Text textStyle={textStyle["body.medium.light"]} fontWeight="600">{name}</Text>
        {/* <Text textStyle={textStyle["body.medium.light"]}
            ref={tweetTextRef}
            mt={2} 
            mb={5} 
            whiteSpace="pre-line"
            dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
            fontFamily="'Source Sans Pro', Helvetica, sans-serif, serif"
          >
              {tweetText}
          </Text> */}
        <StyledText
          ref={tweetTextRef}
          mt={2}
          mb={5}
          whiteSpace="pre-line"
          dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
          fontFamily="'Source Sans Pro', Helvetica, sans-serif, serif"
          dangerouslySetInnerHTML={{
            __html: Autolinker.link(tweetText, {
              newWindow: true, replaceFn: function (match) {
                const checkforHttps = ("https://" + match.getMatchedText().replace(/\/$/, ""));
                const isHttps = text.includes(checkforHttps) ?? false;
                const href = (isHttps) ? match.getAnchorHref().replace('http://', 'https://') : match.getAnchorHref();
                const tag = match.buildTag();
                tag.setAttr('href', href);
                return tag;
              }
            }),
          }}
        />
        {showSeeMoreDivider && lineCount > afterLineCount && (
          <Flex
            // w="calc(100% + 12px)"
            position="absolute"
            // right="-12px"
            top={lineHeight * afterLineCount + marginTop + 0 + "px"}
            flexDir="column"
            alignItems="flex-end"
            {...dividerCss}
          >
            <Text
              mr={-0.5}
              bg="transparent"
              textStyle={textStyle["body.medium.light"]}
              fontSize="xs"
            // bg={useColorModeValue("white", "#1E1E1E")}
            >
              ...see more
              {/* &nbsp;&nbsp;&nbsp;...see more */}
            </Text>
            <Box
              border={"dashed 1px"}
              borderColor={dividerColor}
              w="100%"
              h="1px"
            />
          </Flex>
        )}
        {showGenerateImage && !text.includes("[img:") && <GenerateImage content={tweetText} onImageGenerated={handleGeneratedImage} />}
        {medias.length > 0 ? (
          medias.map((media, index) => (
            <Box key={index}>{displayMedia(media)}</Box>
          ))
        ) : cardType && data ? (
          <Box mb={disableNbChar ? 2 : 6}>
            <TwitterCard
              type={cardType}
              data={data}
              isRetweet={
                isNotThread &&
                cardType === "tweet" &&
                currentPreviewUrl === text?.trim()
              }
            />
          </Box>
        ) : null}
        {!disableNbChar && (
          <Text textStyle={textStyle["body.medium.light"]}
            position="absolute"
            bottom="0"
            right="0"
            color={nbChar > 280 ? "red" : "gray.400"}
            fontWeight={nbChar > 280 ? "bold" : "regular"}
          >
            {nbChar}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Tweet;

interface ImageWithOptions {
  mediaUrl: string;
  displayOptions: boolean;
  onChange?: (newImageId) => void;
}

function ImageWithOptions({ mediaUrl, displayOptions, onChange = () => { } }: ImageWithOptions) {
  const tweetContext: any = useContext(TweetContext);
  const [queryString, setQueryString] = useState(`#dt=${Math.floor(Math.random() * 100000).toString()}`);
  const [refComposer, setRefComposer] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isNotGif = !mediaUrl.includes("gif-")
  const imageId = mediaUrl.split("/userUpload/")[1]

  const handleRemoveImage = () => {
    if (refComposer) {
      const textState = refComposer.textState();
      textState.text = textState.text.replace(`[img:${imageId}]`, "");
      refComposer.editText(textState.text);
    } else {
      toast.error('Something went wrong, please try again');
    }
  }

  const handleImageEdit = (newImageId) => {
    if (refComposer) {
      const textState = refComposer.textState();
      textState.text = textState.text.replace(imageId, newImageId);
      refComposer.editText(textState.text);
    } else {
      toast.error('Something went wrong, please try again');
    }
  }
  useEffect(() => {
    if (tweetContext.refComposer && tweetContext.refComposer.current) {
      setRefComposer(tweetContext.refComposer.current)
    }
  }, [tweetContext.refComposer])
  return (
    <Box position="relative">
      <Image
        objectFit="cover"
        src={mediaUrl + queryString} // to fetch the new edited image
        mb={8}
        borderRadius={8}
      />
      {
        displayOptions && <HStack
          position="absolute"
          bottom={2}
          right={2}
          bgColor={"blackAlpha.300"}
          backgroundBlendMode={"multiply"}
          borderRadius={"xl"}
          p={2}
          backdropFilter={"blur(2px)"}
        >

          {isNotGif && (
            <>
              <Button
                size="xs"
                aria-label="edit"
                leftIcon={<FaMagic />}
                onClick={onOpen}
                variant={"secondary"}
                fontSize={"xs"}
              >
                Edit
              </Button>
              <ImageEditPopup
                isOpen={isOpen}
                onClose={onClose}
                imageId={imageId}
                onSave={(newMedia) => {
                  setQueryString(`#dt=${Math.floor(Math.random() * 100000).toString()}`)
                  handleImageEdit(newMedia.id);
                  onClose();
                }}
              />
            </>
          )}
          <Button
            size="xs"
            fontSize={"xs"}
            aria-label="delete"
            leftIcon={<FaTrash />}
            onClick={handleRemoveImage}
            variant={"secondary"}
          >Delete</Button>
        </HStack>
      }
    </Box>
  )
}