import { useEffect, useState } from "react"

import PuffLoader from "react-spinners/PuffLoader";
import { firebaseClient } from "firebaseClient";
import { BiLinkExternal } from "react-icons/bi"
import { FiThumbsUp } from "react-icons/fi"
import { BsChatLeft } from "react-icons/bs"
import { textStyle } from "theme/names"
import { format } from "date-fns"
import {
    Flex,
    Center,
    Divider,
    IconButton,
    Text,
    Link
} from "@chakra-ui/react"

import { getDateFromSecondsAndNanoSeconds } from 'utils/helpers'
import { Comment, Like } from "types/commentsLikes";

export const Interactions = ({ idUser, miscellaneousContext }) => {
    const [previousComments, setPreviousComments] = useState<Comment[]>([]);
    const [previousLikes, setPreviousLikes] = useState<Like[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const db = firebaseClient.firestore();

    const formatDate = (seconds: number, nanoseconds: number) => format(getDateFromSecondsAndNanoSeconds(seconds, nanoseconds), "MMM do yyyy")

    const getLastComments = async (id: string) => {
        try {
            setIsLoading(true);
            const commentsDocs = await db
                .collectionGroup("comments")
                .where("idUser", "==", idUser)
                .where("profileDashEntityUrn", "==", `urn:li:fsd_profile:${id}`)
                .orderBy("postCreatedAt", "desc")
                .limit(5)
                .get();

            const likesDocs = await db
                .collectionGroup("reactions")
                .where("idUser", "==", idUser)
                .where("profileDashEntityUrn", "==", `urn:li:fsd_profile:${id}`)
                .orderBy("postCreatedAt", "desc")
                .limit(5)
                .get();

            const comments = commentsDocs.docs.map(commentsDoc => commentsDoc.data());
            const likes = likesDocs.docs.map(likesDoc => likesDoc.data());

            setPreviousComments(comments as Comment[])
            setPreviousLikes(likes as Like[])
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getLastComments(miscellaneousContext.sidebarPerson?.id)
    }, [miscellaneousContext.sidebarPerson?.id])

    const interactions: (Comment | Like)[] = [...previousComments, ...previousLikes]

    return (
        <>
            {
                isLoading
                    ? <PuffLoader
                        color={"#0A66C2"}
                        loading={isLoading}
                        cssOverride={{
                            display: "block",
                            margin: "0 auto",
                            borderColor: "red",
                        }}
                        size={50}
                    />
                    : <>
                        <Flex
                            gap="5"
                            color="#2D64BC"
                            pt="3"
                            pb="5"
                            justifyContent="center"
                        >
                            <Flex
                                alignItems="center"
                                gap="2"
                            >
                                <FiThumbsUp
                                    size="20" />
                                {previousLikes?.length ?? 0}
                            </Flex>
                            <Flex
                                alignItems="center"
                                gap="2"
                            >
                                <BsChatLeft
                                    size="20" />
                                {previousComments?.length ?? 0}
                            </Flex>
                        </Flex>
                        <Text
                            color="gray"
                            as="i"
                            textStyle={textStyle["body.medium.light"]}
                        >
                            Most recent interactions on your content
                        </Text>
                        <Flex flexDirection="column" gap="3" pt="4">
                            {interactions
                                .sort((a, b) => b.dateAdded.seconds - a.dateAdded.seconds)
                                .map((lastInteraction, index) => (
                                    <Flex
                                        key={index}
                                        alignItems="center"
                                        gap="2">
                                        <Center>
                                            {index > 0 && (
                                                <Divider
                                                    height="50px"
                                                    size="md"
                                                    transform="translateY(-50%)"
                                                    border="1px #D8D8D8 solid"
                                                    position="absolute"
                                                    orientation="vertical" />
                                            )}
                                            <IconButton
                                                width="10"
                                                icon={('comment' in lastInteraction) ? <BsChatLeft /> : <FiThumbsUp />}
                                                borderRadius="full"
                                                variant="secondary"
                                                aria-label="Like"
                                                zIndex='1'
                                            />
                                        </Center>

                                        <Flex
                                            alignItems="center"
                                            gap="1"
                                        >
                                            <Text
                                                size="xs"
                                                textStyle={textStyle["body.medium.light"]}
                                                fontWeight="600"
                                            >
                                                {formatDate(lastInteraction.dateAdded.seconds, lastInteraction.dateAdded.nanoseconds)}
                                            </Text>
                                            {lastInteraction?.url &&
                                                <Link
                                                    href={lastInteraction.url}
                                                >
                                                    <IconButton
                                                        icon={<BiLinkExternal />}
                                                        color="#2D64BC"
                                                        variant="transparent"
                                                        aria-label={"External link to post"} />
                                                </Link>
                                            }
                                        </Flex>
                                    </Flex>
                                ))}
                        </Flex>
                    </>
            }
        </>
    )
}