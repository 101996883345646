import { Box } from "@chakra-ui/react";
import { convertLinkedinPostDate } from "../../engage/utils";
import LinkedinPost from "./linkedinPost";

export function PostInPost({ postInPostDetails }) {

    // console.log("postInPostDetails", postInPostDetails);

    const post = {
        index: 0,
        data: {
            created_at: postInPostDetails?.created_at ?? (postInPostDetails?.actor?.subDescription?.text)? convertLinkedinPostDate(postInPostDetails?.actor?.subDescription?.text?.substr(0,3)?.trim()) : postInPostDetails?.parentCreatedAt,
            score: 1,
            rank: 1,
            isPosting: false,
            favorite_count: postInPostDetails?.numLikes || postInPostDetails?.socialDetail?.likes?.paging?.total || postInPostDetails?.socialDetail?.totalSocialActivityCounts?.numLikes || 0,
            retweet_count: postInPostDetails?.totalShares || postInPostDetails?.socialDetail?.totalShares || 0,
            full_text: postInPostDetails?.text || postInPostDetails?.commentary?.text?.text || "",
            text: postInPostDetails?.text || postInPostDetails?.commentary?.text?.text || "",
            type: "linkedin",
            name: postInPostDetails?.user?.name || postInPostDetails?.actor?.name?.text,
            profilePic: postInPostDetails?.user?.image || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment || postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment,
            id_str: postInPostDetails?.id || postInPostDetails?.updateMetadata?.urn,
            id: postInPostDetails?.id || postInPostDetails?.updateMetadata?.urn,
            idShort: postInPostDetails?.idShort || (postInPostDetails?.updateMetadata?.urn!==undefined)? postInPostDetails?.updateMetadata?.urn?.substr(16) : "",
            noProfilePicRefresh: true,
            isPostTwitter: false,
            isLiveTweet: false,
            isRetweeted: false,
            isAutoRetweet: false,
            reply: "",
            isPlugged: false,
            isAutoDm: false,
            numReactionsLike: 0,
            numReactionsPraise: 0,
            numReactionsMaybe: 0,
            numReactionsEmpathy: 0,
            numReactionsInterest: 0,
            numReactionsAppreciation: 0,
            totalEngagement: 0,
            user: {
                id: postInPostDetails?.user?.dashEntityUrn || postInPostDetails?.actor?.urn,
                username: postInPostDetails?.user?.username || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.publicIdentifier,
                dashEntityUrn: postInPostDetails?.user?.dashEntityUrn || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.dashEntityUrn,
                twUserName: postInPostDetails?.user?.username || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.publicIdentifier,
                occupation: postInPostDetails?.user?.occupation || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.occupation,
                about: postInPostDetails?.user?.occupation || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.occupation,
                firstName: postInPostDetails?.user?.firstName || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.firstName,
                lastName: postInPostDetails?.user?.lastName || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.lastName,
                idShort: postInPostDetails?.user?.idShort || ((postInPostDetails?.actor?.urn !== undefined)? postInPostDetails?.actor?.urn?.substr(14) : ""),
                profile_image_url_https: postInPostDetails?.user?.image || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment || postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.["com.linkedin.common.VectorImage"]?.artifacts[0].fileIdentifyingUrlPathSegment || "https://app.taplio.com/emptyProfile.jpg",
                description: postInPostDetails?.user?.occupation || postInPostDetails?.actor?.description?.text ,
                image: postInPostDetails?.user?.image || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.backgroundImage?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment || postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniCompany?.logo?.["com.linkedin.common.VectorImage"]?.artifacts?.[0]?.fileIdentifyingUrlPathSegment || postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.["com.linkedin.common.VectorImage"]?.rootUrl + postInPostDetails?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.["com.linkedin.common.VectorImage"]?.artifacts[0].fileIdentifyingUrlPathSegment || "https://app.taplio.com/emptyProfile.jpg",
                id_str: postInPostDetails?.user?.dashEntityUrn || postInPostDetails?.actor?.urn,
                name: postInPostDetails?.user?.name || postInPostDetails?.actor?.name?.text,
            },
            zFull: postInPostDetails,
        },
        width: 800,
    };
    
  return (
    <Box
        w="100%"
        overflow='hidden'
        bg='transparent'
        p={3}
        display="flex"
        flexDirection="column"
        alignItems="start"
    >
        {/*
        // @ts-ignore */}
        <LinkedinPost
            tweet={post}
            savedTweet={[]}
            disableBookmarkButton={true}
            disableHideButton={true}
            disableMainHideButton={true}
            disableCopy={true}
            disableEditTweet={true}
            disableRequest={true}
            disableFooter={true}
            disableSubMenu={true}
            disableUserPopup={true}
            mode="answer"
        />
    </Box>
  );
}


