//react hook for making api calls to linkedIn Api via the extensions apibridge.
//returned data is only available within the callbackFunction. see engage2.tsx for demo.
export interface apiCallHookObj {
  mode?: string | undefined,
  callName?: string | undefined,
  callURL?: string | undefined,
  args?: any,
  message?: string | undefined,
  returnMessage?: string | undefined,
  conditionToFireAnEffect?: any[] | undefined,
  callbackFunction?: any
};

export function useLinkedinApi({
  mode = "func",
  callName = "",
  callURL = "",
  args = {},
  message = "taplio_app_api_call",
  returnMessage = "linkedin_api_reply",
  callbackFunction = (params) => {}
}:apiCallHookObj){
  
  // console.log('Called api hook ' + callName + ' with args: ', args);
  // the function attached to the message event listener needs a unique name to be deregistered after a reply has been received. Every api call will have its own copy of replyCall function that deals with data processing using the callbackFunction supplied. This is so that messages do no deregister another useLinkedinApi hook/comp call from any where within the taplio app.
  const uniqueFuncNameForDereg = new Date().getTime() + Math.random() + window.crypto.getRandomValues(new Uint32Array(9)).join('') + "_replyCall";

  window[uniqueFuncNameForDereg] = (event) => {
    const payload = (checkJSONString(event.data))? JSON.parse(event.data) : {};
    const replyMessage = payload?.returnMessage || "";
    const replyPayload = payload?.payload || {};
    const replyCallFnNameRemove = payload?.replyCallFnName;

      //console.log('reply from api as:', replyPayload)
      //the first two conditions are self explanatory. the third condition is for the time it takes for async function(s) to get the results and send back a message again with the results being populated.
    if(replyMessage == "linkedin_api_reply" && replyCallFnNameRemove == uniqueFuncNameForDereg && Object.keys(replyPayload).length !== 0) {            
        //send data returned to the callback function for processing...
        callbackFunction(replyPayload);

        //once data has been passed over to the callback function associated with an api call dereg the listener for that particular api call.
        window.removeEventListener("message", window[uniqueFuncNameForDereg]);

        //once dereg-ed nullify, detele the dynamically named replyCall function:
        delete window[uniqueFuncNameForDereg];
    }     
  };

  window.addEventListener("message", window[uniqueFuncNameForDereg]);

  window.postMessage(JSON.stringify({
      message,
      args,
      callURL,
      callName,
      mode,
      returnMessage,
      replyCallFnName: uniqueFuncNameForDereg,
  }), "*");
}

export function confirmApiBridgeLoad() {
  //check for the availablity of apibridge being present:
  const apiBridgePresent = document.getElementById('api-bridge-loaded');
  if(!apiBridgePresent) return false;
  return true;
}

export const checkJSONString = (jsonstring = "") => {
  try {
    JSON.parse(jsonstring);
  } 
  catch(e) {
    return false;
  }
  return true;
}
