
import { useState, useRef, useLayoutEffect, useMemo, useEffect } from "react";
import { LazyMotion, domAnimation, m, useAnimationControls } from "framer-motion";
import React from "react";
import {
  Box,
  Text,
  IconButton,
  Flex,
  Center,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
interface Props {
  docURL: string;
  docName: string;
};
import { useSize } from "@chakra-ui/react-use-size";
/**
 * IMPORTANT:
 * Please make sure that react-pdf's version stays at v5.10
 * until node's version in production is updated to 16+
 * - manoj
**/
import {Document, Page, pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
/**
* pdfjs.GlobalWorkerOptions is required for perf. 
* essentially a js worker is required for doing the heavy lifting of coverting
* the pdf's to svg/canvas images. using a shortcut and using default worker for 
* carousel previews. - manoj
**/
import { textStyle, variant } from "theme/names";

function CarouselPreviewUnMemoized({ docURL, docName }: Props) {
  //console.log(docURL, 'carousel -')
  const [pdfURL, setPDFUrl] = useState<string>(docURL);
  const [documentWidth, setDocumentWidth] = useState<number>(312);
  const topsAnimControls = useAnimationControls();
  const bottomsAnimControls = useAnimationControls();
  const buttonAnimControls = useAnimationControls();
  const [isLoadError, setIsLoadError] = useState<boolean>(false);
  const [totalPageNumbers, setTotalPageNumbers] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const pdfDocContainer = useRef(null);
  const pdfDocContainerSize = useSize(pdfDocContainer);
  const [isMouseStillHovering, setIsMouseStillHovering] = useState<boolean>(false);
  const [loadMainPDF, setLoadMainPDF] = useState<boolean>(false);
  const [isCompLoading, setIsCompLoading] =useState<boolean>(false);

  const ErrorComp = () => {
    return (
      <Text textStyle={textStyle["body.medium.light"]} px="2">
        Carousel - preview not available right now
      </Text>
    );
  }

  const onPageHoverEnter = async (event) => {
    // console.log('enter');
    if(!isMouseStillHovering) {
      topsAnimControls.start({
        y:0,
        opacity: 1,
        transition: { duration: 0.6 }
      });
  
      bottomsAnimControls.start({
        y:0,
        opacity: 1,
        transition: { duration: 0.6 }
      });
  
      buttonAnimControls.start({
        opacity: 1,
        transition: { duration: 0.6 }
      });
      setIsMouseStillHovering(true);
    }
    
  }

  const onPageHoverLeave = async (event) => {
    // console.log('leave');
    setIsMouseStillHovering(false) ;
    topsAnimControls.start({
      y:-100,
      opacity: 0,
      transition: { duration: 0.6 }
    });
    bottomsAnimControls.start({
      y:+100,
      opacity: 0,
      transition: { duration: 0.6 }
    });
    buttonAnimControls.start({
      opacity: 0,
      transition: { duration: 0.6 }
    });
  }

  const changePage = async (offset) => {
    setCurrentPageNumber(prevPage => prevPage + offset);
  }

  const prevPage = async () => {
    (currentPageNumber < 1)
    ? setCurrentPageNumber(1)
    : changePage(-1);
    
  }

  const nextPage = async () => {
    (currentPageNumber >= totalPageNumbers)
    ? setCurrentPageNumber(1)
    : changePage(1);
  }

  const memoedPdfURL = useMemo(
     () => {
      return docURL
     }, [docURL]
  );

  useLayoutEffect(() => {
      setDocumentWidth(pdfDocContainerSize?.width || 312);
      //setLoadMainPDF(true);
    }, [pdfDocContainerSize]
  );

  useEffect(()=>{
    if(isCompLoading) setIsCompLoading(false);
    },[]
  );

  return (
    <Flex
      w="100%"
      rounded="lg"
      color="gray.500"
      flexDir="column"
      m={0}
      p={0}
      maxW={"100%"}
      ref={pdfDocContainer}
    >
        <Document
          file={memoedPdfURL}
          onLoadError={ async (error)=>{
            //console.log(error);
            setIsLoadError(true);
            setLoadMainPDF(false);
          }}
          error={ErrorComp}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
          renderMode={"canvas"}
          //className={documentStyle}
          onLoadSuccess={
            async ({numPages}) => {
              //@ts-ignore
              setDocumentWidth(pdfDocContainer?.current?.offsetWidth || 312);
              setIsLoadError(false);
              setTotalPageNumbers(numPages);
              setCurrentPageNumber(1);
              setLoadMainPDF(true);
            }
          }
        >
          <Box display={"none"}>
            {/* load entire pdf, stops flicker. useMemo doesn't work. Memoized the entire component. */}
            {<Page
                  pageNumber={(currentPageNumber + 1) || 1}
                  width={0}
                  scale={0.1}
                />
            }
          </Box>
          
          { loadMainPDF && 
            <Page pageNumber={currentPageNumber || 1} width={documentWidth} error={ErrorComp} loading={<Text color={"blackAlpha.600"}>Loading Page...</Text>}>
              {
                !isLoadError && !isCompLoading &&
                <Box
                  position={"absolute"}
                  left={0}
                  top={0}
                  opacity={1}
                  display={"flex"}
                  flexDir={"column"}
                  width={"100%"}
                  height={"100%"}
                  onMouseEnter={onPageHoverEnter}
                  onMouseLeave={onPageHoverLeave}
                  zIndex={3}
                  overflow={"hidden"}
                >
                  <LazyMotion features={domAnimation}>
                    <m.div
                      style={{
                        width: '100%',
                        height: '24px',
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        fontSize: '12px',
                        padding: '3px',
                        color:'#fefefe',
                        overflow:'hidden'
                      }}
                      animate={topsAnimControls}
                      initial={{
                        y: (isMouseStillHovering) ? 0 : -100,
                        opacity: (isMouseStillHovering) ? 1 : 0,
                      }}
                    >
                      <Text noOfLines={1}><strong>{docName}</strong> - {totalPageNumbers} Pages
                      
                    </Text>
                  </m.div>
                  <m.div
                    style={{
                      display: "flex",
                      height: "calc(100% - 48px)",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    animate={buttonAnimControls}
                    initial={{
                      opacity: isMouseStillHovering ? 1 : 0,
                    }}
                  >
                    {
                      currentPageNumber > 1 ? (
                        <IconButton
                          aria-label="Prev Page"
                          icon={<MdOutlineNavigateBefore />}
                          bgColor={"blackAlpha.800"}
                          rounded={"full"}
                          fontSize={"1.5rem"}
                          size={"sm"}
                          onClick={prevPage}
                          color={"gray.300"}
                          _hover={{
                            color: "white",
                            backgroundColor: "#1E1E1E",
                          }}
                        />
                      ) : (
                        <span> </span>
                      ) //req for alignments
                    }
                    {
                      currentPageNumber != totalPageNumbers ? (
                        <IconButton
                          aria-label="Next Page"
                          icon={<MdOutlineNavigateNext />}
                          bgColor={"blackAlpha.800"}
                          rounded={"full"}
                          fontSize={"1.5rem"}
                          size={"sm"}
                          onClick={nextPage}
                          color={"gray.300"}
                          _hover={{
                            color: "white",
                            backgroundColor: "#1E1E1E",
                          }}
                        />
                      ) : (
                        <span> </span>
                      ) //req for alignments
                    }
                  </m.div>
                  <m.div
                    style={{
                      width: "100%",
                      height: "24px",
                      display: "flex",
                      backgroundColor: "rgba(0,0,0,0.8)",
                      fontSize: "12px",
                      padding: "3px",
                      color: "#fefefe",
                      overflow: "hidden",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    animate={bottomsAnimControls}
                    initial={{
                      y: isMouseStillHovering ? 0 : +100,
                      opacity: isMouseStillHovering ? 1 : 0,
                    }}
                  >
                      <Text w={"15%"}>
                        {currentPageNumber} / {totalPageNumbers}
                      </Text>
                      <Slider
                        defaultValue={currentPageNumber}
                        min={1}
                        max={totalPageNumbers || 0}
                        step={1}
                        w={"84%"} size={"sm"}
                        onChangeEnd={(pageNum)=>{
                          setCurrentPageNumber(pageNum);
                        }}
                      >
                        <SliderTrack bg='black'>
                          <SliderFilledTrack bg='white' />
                        </SliderTrack>
                        <SliderThumb boxSize={2} />
                      </Slider>
                    </m.div>
                  </LazyMotion>
                </Box>
              }
            </Page>
          }
          
        </Document>
      </Flex>
     
  );
}


export const  CarouselPreview = React.memo(CarouselPreviewUnMemoized);
