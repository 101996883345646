import {
  Box,
  Link,
  Text,
  Heading,
  Image
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { layerStyle, radius, textStyle } from "theme/names";
interface Props {
  celebrationDetails: {
    headline: any,
    image: any,
    hrefLink: string,
  };
};

export function CelebrationPreview({ celebrationDetails }: Props) {
  const headingTitle = celebrationDetails?.headline?.text;
  const celebImage = celebrationDetails?.image?.attributes?.[0]?.imageUrl;
  
  return (
    <Link
      href={celebrationDetails?.hrefLink}
      isExternal
      w="100%"
      title={headingTitle}
    >
      <Box
          // w="100%"
          // borderWidth='1px'
          // borderRadius='sm'
          // overflow='hidden'
          // bg='gray.200'
          // display="flex"
          // flexDirection="column"
          // alignItems="start"
           layerStyle={layerStyle.bgBorderGray}
           borderRadius={radius.sm}
           w="100%"
           overflow='hidden'
           display="flex"
           flexDirection="column"
           alignItems="start"
      >
        <Image
          src={celebImage}
          alt={headingTitle}
          borderTopStartRadius="sm"
          fit="cover"
          h="100%"
          onError={(e) => {
            //remove img if the image doesn't load for whatever context...
            //@ts-ignore
            if(e?.target != undefined) e?.target?.remove();
          }}
        />
          <Box
            flexDirection="row"
            w="100%"
            pl={3}
            pt={3}
          >
            <Text textStyle={textStyle["body.medium.light"]}
              display="inline-block"
              sx={{fontSize:'0.9rem'}}
            >
              See LinkedIn Celebration Post:
            </Text>
            <BiLinkExternal
              size="18px"
              title="Open external link"
              style={{display:"inline-block", paddingLeft:"3px", verticalAlign:"middle"}}
            />
          </Box>
          <Heading
            fontSize="sm"
            w="100%"
            pl={3}
            pb={3}
          >
            {headingTitle}
          </Heading>
      </Box>
    </Link>
  );
}