import { Box, Link, Text, Heading } from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import { layerStyle, radius, textStyle } from "theme/names";
interface Props {
  entityDetails: {
    title: any;
    subtitle: any;
    navigationContext: any;
  };
}

export function EntityPreview({ entityDetails }: Props) {
  const titleText = entityDetails?.title?.text;
  const subTitleText = entityDetails?.subtitle?.text;
  const link = entityDetails?.navigationContext?.actionTarget;
  const linkTitle = entityDetails?.navigationContext?.accessibilityText;

  return (
    <Link href={link} isExternal w="100%" title={linkTitle}>
      <Box
        layerStyle={layerStyle.bgBorderGray}
        borderRadius={radius.sm}
        w="100%"
        // borderWidth='1px'
        // borderRadius='sm'
        overflow="hidden"
        // bg='gray.200'
        p={3}
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Box flexDirection="row" w="100%">
          <Text
            textStyle={textStyle["body.medium.light"]}
            display="inline-block"
            sx={{ fontSize: "0.9rem" }}
          >
            {subTitleText}
          </Text>
          <BiLinkExternal
            size="18px"
            title="Open external link"
            style={{
              display: "inline-block",
              paddingLeft: "3px",
              verticalAlign: "middle",
            }}
          />
        </Box>
        <Text
          textStyle={textStyle["body.medium.standard"]}
          //   fontSize="sm"
          w="100%"
        >
          {titleText}
        </Text>
        <Text
          textStyle={textStyle["body.medium.light"]}
          sx={{ fontSize: "0.9rem" }}
          w="100%"
        >
          {linkTitle}
        </Text>
      </Box>
    </Link>
  );
}
