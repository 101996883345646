import {
  Box,
  Link,
  Text,
  Heading
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { layerStyle, radius, textStyle } from "theme/names";
interface Props {
    pollDetails: {
      question: any,
      hrefLink: any,
  };
};

export function PollPreview({ pollDetails }: Props) {
  const pollQuestion = pollDetails?.question?.text || false;

  return (
    <Link
      href={pollDetails?.hrefLink} 
      isExternal
      w="100%"
      title={pollQuestion}
    >
      <Box
        layerStyle={layerStyle.bgBorderGray}
        borderRadius={radius.sm}
        w="100%"
        // borderWidth='1px'
        // borderRadius='sm'
        overflow='hidden'
        // bg='gray.200'
        p={3}
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Box
          
          flexDirection="row"
          w="100%"
        >
          <Text
            textStyle={textStyle["body.medium.light"]}
            display="inline-block"
            sx={{fontSize:'0.9rem'}}
          >
            See LinkedIn Poll:
          </Text>
          <BiLinkExternal
            size="18px"
            title="Open external link"
            style={{display:"inline-block", paddingLeft:"3px", verticalAlign:"middle"}}
          />
        </Box>
        <Text
          textStyle={textStyle["body.medium.standard"]}
          // fontSize="sm"
          w="100%"
        >
          {pollQuestion}
        </Text>
      </Box>
    </Link>
  );
}
