import { useState } from "react";
import { useSession } from "next-auth/react";
import { TaskTypes } from "./custom-list-item";
import { getAccount, updateUser } from "utils/sessionHelper";

export function useOnboardingSteps() {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { data: session } = useSession() ?? {}
  const updateOnboardSteps = async (task: TaskTypes, nextTask?: TaskTypes) => {
    const onboarding = getAccount(session)?.onboarding;
    const completedSteps: string[] = onboarding?.completedSteps;

    /**
     * allow earlier steps to be completed even when user has reached the last step.
     * "engage" is currently the last step. Its value comes from TASKLIST array in ./index.tsx
     */
    const stepCheckCondition = (onboarding?.currentStep === task || onboarding?.currentStep === "engage")

    if (onboarding && stepCheckCondition && !completedSteps?.includes(task)) {
      setIsUpdating(true);
      await updateUser(session, {
        onboarding: {
          ...onboarding,
          completedSteps: [...onboarding.completedSteps, task],
          currentStep: nextTask || onboarding.currentStep,
        },
      });
      setIsUpdating(false);
    }
  };

  const hidePanel = async () => {
    const onboarding = getAccount(session)?.onboarding;

    if (onboarding) {
      setIsUpdating(true);

      await updateUser(session, {
        onboarding: {
          ...onboarding,
          showPanel: false,
        },
      });
      setIsUpdating(false);
    }
  };

  const skipStep = async (nextStep: TaskTypes) => {
    const onboarding = getAccount(session)?.onboarding;

    if (onboarding) {
      setIsUpdating(true);

      await updateUser(session, {
        onboarding: {
          ...onboarding,
          currentStep: nextStep,
        },
      });
      setIsUpdating(false);
    }
  };

  return { updateOnboardSteps, isUpdating, hidePanel, skipStep };
}
