import { Flex, Image, useColorModeValue } from "@chakra-ui/react";
import PulseLoader from "react-spinners/PulseLoader";

export function LoadingMessage() {
  const bg = useColorModeValue("#FEFCFF", "#1a202c");

  return (
    <Flex alignItems="self-start" my={2} p="2.5" rounded="lg" bg={bg}>
      <Image src="/logo/chat-logo-gradient.png" w="8" h="8" rounded="full" />
      <Flex ml="3" rounded="lg" minH="100%" alignItems="center">
        <Flex flexDir="column">
          <PulseLoader color="#0A66C2" loading={true} size="9" />
        </Flex>
      </Flex>
    </Flex>
  );
}
