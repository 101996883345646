export function WriteIcon() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.76596 8.34863L3.27153 10.552H0.423401C0.18959 10.552 0 10.7416 0 10.9754C0 11.2093 0.18959 11.3988 0.423401 11.3988H3.61044C3.64217 11.3988 3.67293 11.395 3.70262 11.3885C3.70284 11.3885 3.70305 11.3886 3.70316 11.3885L6.23726 10.8199L3.76596 8.34863Z"
        fill="url(#paint0_linear_1996_5213)"
      />
      <path
        d="M3.76596 8.34863L3.27153 10.552H0.423401C0.18959 10.552 0 10.7416 0 10.9754C0 11.2093 0.18959 11.3988 0.423401 11.3988H3.61044C3.64217 11.3988 3.67293 11.395 3.70262 11.3885C3.70284 11.3885 3.70305 11.3886 3.70316 11.3885L6.23726 10.8199L3.76596 8.34863Z"
        fill="url(#paint1_linear_1996_5213)"
      />
      <path
        d="M13.4285 3.8941C13.7945 3.52817 13.9961 3.04143 13.9961 2.52382C13.9961 2.00621 13.7945 1.51958 13.4286 1.15353C13.4285 1.15353 13.4285 1.15353 13.4285 1.15353C13.0624 0.787491 12.5758 0.585938 12.0582 0.585938C11.5406 0.585938 11.0538 0.787491 10.6879 1.15353L10.5703 1.27113L13.3109 4.0117L13.4285 3.8941Z"
        fill="url(#paint2_linear_1996_5213)"
      />
      <path
        d="M13.4285 3.8941C13.7945 3.52817 13.9961 3.04143 13.9961 2.52382C13.9961 2.00621 13.7945 1.51958 13.4286 1.15353C13.4285 1.15353 13.4285 1.15353 13.4285 1.15353C13.0624 0.787491 12.5758 0.585938 12.0582 0.585938C11.5406 0.585938 11.0538 0.787491 10.6879 1.15353L10.5703 1.27113L13.3109 4.0117L13.4285 3.8941Z"
        fill="url(#paint3_linear_1996_5213)"
      />
      <path
        d="M4.22656 7.61551L9.97195 1.87012L12.7125 4.61069L6.96724 10.3561L4.22656 7.61551Z"
        fill="url(#paint4_linear_1996_5213)"
      />
      <path
        d="M4.22656 7.61551L9.97195 1.87012L12.7125 4.61069L6.96724 10.3561L4.22656 7.61551Z"
        fill="url(#paint5_linear_1996_5213)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1996_5213"
          x1="0.891286"
          y1="8.46544"
          x2="4.54146"
          y2="11.6108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D693FF" />
          <stop offset="1" stopColor="#79BFFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1996_5213"
          x1="0.178207"
          y1="8.66971"
          x2="6.31878"
          y2="9.47466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1996_5213"
          x1="11.0598"
          y1="0.717122"
          x2="14.0265"
          y2="1.96728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D693FF" />
          <stop offset="1" stopColor="#79BFFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1996_5213"
          x1="10.6682"
          y1="0.946544"
          x2="14.0848"
          y2="1.16557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1996_5213"
          x1="5.43918"
          y1="2.19507"
          x2="12.7879"
          y2="5.29183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D693FF" />
          <stop offset="1" stopColor="#79BFFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1996_5213"
          x1="4.46902"
          y1="2.76338"
          x2="12.9322"
          y2="3.30592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
