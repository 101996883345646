import { useState } from "react";

import { SlLocationPin } from "react-icons/sl";
import { AiOutlineMail } from "react-icons/ai";
import { FaIndustry } from "react-icons/fa";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import {
  Image,
  Text,
  Link,
  Icon,
  Tooltip,
  Flex,
  Button,
} from "@chakra-ui/react";

import { textStyle, variant } from "theme/names";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import numbro from "numbro";
import { isFeatureEnabled } from "utils/helpers";
interface Props {
  contact: any;
  disableSendDm?: boolean;
  session?: any;
}

export function Profile({
  contact,
  disableSendDm = false,
  session = {},
}: Props) {
  const miscellaneousContext: any = useMiscellaneousContext();
  const router = useRouter();

  if (!contact) {
    return null;
  }

  const handleSendDm = async () => {
    miscellaneousContext.setCrmDm([contact]);
    miscellaneousContext.setIsPersonSidebarOpen(false);
    router.push({ pathname: "/dm", query: { from: "crm" } }, "/dm");
  };

  return (
    <>
      <Flex>
        <Flex direction="column" align="center">
          <Image
            src={contact?.image}
            alt="profile image"
            h="130"
            w="130"
            // mx="auto"
            borderRadius="50%"
            maxW="unset"
            fallbackSrc="/emptyProfile.png"
          />
          {!disableSendDm &&
            isFeatureEnabled("outreach", session, {}, false, "") && (
              <Button
                size="sm"
                mt="5"
                height="40px"
                variant={variant.Button.primary}
                leftIcon={<AiOutlineMail />}
                onClick={handleSendDm}
              >
                Send DM
              </Button>
            )}
        </Flex>
        <Flex
          direction="column"
          ml="5"
          mt="2"
          alignContent="left"
          alignItems="flex-start"
          gap="1"
          textStyle="h2.bold.standard"
        >
          <Link
            as={NextLink}
            href={contact?.profileUrl}
            fontWeight="bold"
            fontSize="lg"
            display="block"
            isExternal
          >
            {contact?.name ?? contact?.firstName + " " + contact?.lastName}
          </Link>
          <Text textStyle={textStyle["body.medium.primary"]} noOfLines={1}>
            {contact?.occupation}
          </Text>
          {contact?.email && (
            <Text textStyle={textStyle["body.medium.light"]}>
              {contact?.email}
            </Text>
          )}
          {contact?.location?.default && (
            <Tooltip label="location">
              <Text
                textStyle={textStyle["body.medium.light"]}
                fontSize="sm"
                noOfLines={1}
                textAlign="left"
              >
                <Icon as={SlLocationPin} mr="2" color="gray" strokeWidth="1" />
                {contact.location.city}, {contact.location.country}
              </Text>
            </Tooltip>
          )}
          {contact?.industry && (
            <Tooltip label="Industry">
              <Text textStyle={textStyle["body.medium.light"]} fontSize="sm">
                <Icon as={FaIndustry} mr="2" color="gray" strokeWidth="1" />{" "}
                {contact?.industry}
              </Text>
            </Tooltip>
          )}
          <Text
            textStyle={textStyle["body.medium.light"]}
            fontSize="md"
            fontWeight="600"
          >
            {numbro(contact?.followersCount)?.format({
              thousandSeparated: true,
            })}{" "}
            followers
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
