import { Flex, Image, useColorModeValue, useColorMode } from "@chakra-ui/react";
import { StyledText } from "../utils";

interface Props {
  content: string;
  contentFormatted?: string;
}

export function AssistantMessage({ content, contentFormatted }: Props) {
  const bg = useColorModeValue("#FEFCFF", "#1a202c");
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="self-start"
      my="2"
      p="2.5"
      rounded="lg"
      bg={bg}
      className="chat-container"
    >
      <Image src="/logo/chat-logo-gradient.png" w="8" h="8" rounded="full" />
      <Flex ml="3" rounded="lg" minH="100%" alignItems="center">
        <Flex flexDir="column">
          <StyledText
            colorMode={colorMode}
            whiteSpace="pre-line"
            dangerouslySetInnerHTML={{
              __html: contentFormatted ?? content,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
