import * as React from "react";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { ChakraProvider, createLocalStorageManager } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";

import { SessionProvider } from 'next-auth/react';
import { TweetWrapper } from '../context/tweetContext';
import { MainWrapper } from '../context/mainContext';
import { MiscellaneousWrapper } from "context/miscellaneousContext"
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import { customTheme } from "theme/customTheme";
import "styles/index.css";
import 'remirror/styles/all.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { useRouter } from "next/dist/client/router";
import { pageView } from "utils/analytics";
import { PersonSidebar } from "components/person-sidebar";
import 'react-vis/dist/style.css';
import { color, radius } from "theme/names";
import { colors } from "theme/colors/colors";
import { ChatWrapper } from "context/chatContext";
import { CampaignWrapper } from "context/campaignContext";
import * as analytics from "utils/analytics";

const Scheduler = dynamic(() => import('components/scheduler'), { ssr: false });

// const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const handleRouteChange = (url) => {
    pageView(url)
  }
   React.useEffect(() => {
    analytics.log("app_opened");
   },[])
  React.useEffect(() => {
    // When the component is mounted, subscribe to router changes and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // https://chakra-ui.com/getting-started/migration
  const manager = createLocalStorageManager('taplio-color-mode')

  // console log full url

  if (router?.pathname?.includes("/posts")
    || router?.pathname?.includes("/resources")
    || router?.pathname?.includes("/lookup")
    || router?.pathname?.includes("/influencers")
    || router?.pathname?.includes("/trending")
    || router?.pathname?.includes("/what-and-when-to-post")
    || router?.pathname?.includes("/generate-linkedin-posts")
    || (router?.pathname?.includes("/stats") && router.query?.idUser)
    || (router?.pathname?.includes("/statspublic") && router.query?.idUser)
    || (router?.pathname?.includes("/linkedin-profile-optimization"))
  ) {
    return (
      <SessionProvider session={pageProps.session}>
        <ChakraProvider theme={customTheme}>
          <Component {...pageProps} />
          {/* <Box
            position="fixed" bottom={5} left="50%" transform="translateX(-50%)"
            zIndex="10"
        >   
            <div dangerouslySetInnerHTML={{ __html: `<a href="https://www.producthunt.com/posts/taplio?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-taplio" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317360&theme=light" alt="Tweet Hunter - AI powered Twitter growth for makers | Product Hunt" style="width: 180px; height: 40px;" width="180" height="40" /></a>` }} />
        </Box> */}
        </ChakraProvider>
      </SessionProvider>
    )
  }

  return (
    <ChakraProvider theme={customTheme} colorModeManager={manager}>
      <SessionProvider session={pageProps.session}>
        <MainWrapper>
          <MiscellaneousWrapper>
            <TweetWrapper>
              <ChatWrapper>
                <CampaignWrapper>
                  <Scheduler />
                  <Component {...pageProps} />
                  <PersonSidebar />
                  <div>
                    <Toaster
                      toastOptions={{
                        style: {
                          padding: "8px 16px",
                          borderRadius: radius.lg,
                          border: "1px solid",
                          borderColor: colors.border.lightMode.light,
                          color: colors.text.lightMode.standard,
                          boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)"
                          // fontWeight: 500
                        },
                      }}
                    />
                  </div>
                </CampaignWrapper>
              </ChatWrapper>
            </TweetWrapper>
          </MiscellaneousWrapper>
        </MainWrapper>
      </SessionProvider>
    </ChakraProvider>
  );
}
export default MyApp;
