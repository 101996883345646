export function PlusIcon() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2101_5246)">
        <g clip-path="url(#clip0_2101_5246)">
          <path
            d="M18.5 28C13.2615 28 9 23.7385 9 18.5C9 13.2615 13.2615 9 18.5 9C23.7385 9 28 13.2615 28 18.5C28 23.7385 23.7385 28 18.5 28Z"
            fill="url(#paint0_linear_2101_5246)"
          />
          <path
            d="M22.651 17.7078H19.2864V14.3431C19.2864 13.9061 18.9318 13.5515 18.4947 13.5515C18.0577 13.5515 17.7031 13.9061 17.7031 14.3431V17.7078H14.3385C13.9014 17.7078 13.5469 18.0623 13.5469 18.4994C13.5469 18.9364 13.9014 19.291 14.3385 19.291H17.7031V22.6556C17.7031 23.0927 18.0577 23.4473 18.4947 23.4473C18.9318 23.4473 19.2864 23.0927 19.2864 22.6556V19.291H22.651C23.088 19.291 23.4426 18.9364 23.4426 18.4994C23.4426 18.0623 23.088 17.7078 22.651 17.7078Z"
            fill="#FAFAFA"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2101_5246"
          x="0.739131"
          y="0.739131"
          width="35.5217"
          height="35.5217"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.13043" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2101_5246" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2101_5246" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_2101_5246" x1="18.5" y1="28" x2="18.5" y2="9" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E96284" />
          <stop offset="1" stopColor="#6369E0" />
        </linearGradient>
        <clipPath id="clip0_2101_5246">
          <rect width="19" height="19" rx="9.5" transform="matrix(1 0 0 -1 9 28)" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
