import {useState} from "react";
import { Box, chakra, Avatar } from "@chakra-ui/react";
import NextImage, { ImageProps, ImageLoaderProps } from "next/legacy/image";
import { convertImageToCdn } from "../../utils/linkedinUtils";

const ChakraNextUnwrappedImage = chakra(NextImage, {
    shouldForwardProp: (prop) =>
        [
            "width",
            "height",
            "src",
            "alt",
            "quality",
            "placeholder",
            "blurDataURL",
            "loader ", // space intended
            "onError",
            "unoptimized",
        ].includes(prop),
});

const myLoader = (resolverProps: ImageLoaderProps): string => {
    // return `${resolverProps.src}?w=${resolverProps.width}&q=${resolverProps.quality}`;
    return `${resolverProps.src}?w=${resolverProps.width}&q=${resolverProps.quality}`;
};

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stopColor="#333" offset="20%" />
      <stop stopColor="#222" offset="50%" />
      <stop stopColor="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
    typeof window === "undefined"
        ? Buffer.from(str).toString("base64")
        : window.btoa(str);

export const ImageNextChakra = (props) => {
    let { src, alt, width, quality, height, fallbackSrc, isSwitchToAvatar=false, onError=undefined, avatarProps=undefined, ...rest } = props;

    src = convertImageToCdn(src);

    const [imgSrc, setImgSrc] = useState(src);
    const [isAvatar, setIsAvatar] = useState(false);

    if (!width) width = rest.w;
    if (!height) height = rest.h;
    
    // console.log('rest:', rest)
    return (
        <Box pos="relative" {...rest} >
            {
                isAvatar ? (
                    <Avatar 
                        name={alt} src='' 
                        {...avatarProps}
                    />
                ) : (
                    <ChakraNextUnwrappedImage
                        w="auto"
                        h="auto"
                        loader={myLoader}
                        width={width}
                        quality={quality}
                        height={height}
                        placeholder={ width > 40 ? "blur" : "empty" }
                        blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            shimmer(700, 475),
                        )}`}
                        src={imgSrc ?? "/emptyProfile.png"}
                        alt={alt}
                        transition="all 0.2s"
                        {...rest}
                        onError={(e) => {
                            console.log("error loading image: " + src, e);
                            if (isSwitchToAvatar) {
                                setIsAvatar(true);
                            }
                            else if (fallbackSrc)
                                setImgSrc(fallbackSrc);

                            onError && onError(e);
                        }}
                    />
                )
            }
        </Box>
    );
};