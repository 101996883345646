import {
  AlertDialog,
  AlertDialogBody, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Box, Button, Stack
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { FiShare2 } from 'react-icons/fi';
import { variant } from "theme/names";
import Thread from "../../components/tweets/masonry/thread";
import { ModalInfoIcon } from "./ModalInfoIcon";

export const TweetPopup = ({
  isOpen,
  onClose,
  text,
  id,
  tweet = undefined as any,
}) => {
  const initialRef = React.useRef<HTMLInputElement>(null);
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const router = useRouter();

  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  // let threadTweets = text.split("\n\n\n");
  // let threadTweets = [text];
  let threadTweets = tweet?.type == "tweet" ? text.split("\n\n\n") : [text];

  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered={false}
      // variant="info"
      //@ts-ignore
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            {/* <ModalInfoIcon /> */}
            Your post
          </AlertDialogHeader>

          <AlertDialogBody>
            <Stack
            //   {...{
            //     bg: useColorModeValue(
            //       theme.colors.gray[100],
            //       theme.colors.gray[700]
            //     ),
            //   }}
            >
              {threadTweets && (
                <Box
                  display="flex"
                  justifyContent="center"
                  // m={2}
                  // border={"solid 1px " + useColorModeValue(theme.colors.gray[200], theme.colors.gray[600])}
                  // rounded="lg"
                  //   bg={useColorModeValue(
                  //     theme.colors.gray[100],
                  //     theme.colors.gray[700]
                  //   )}
                >
                  <Thread
                    threadTweets={threadTweets}
                    disableNbChar={true}
                    tweet={tweet}
                    showOutline={true}
                    image={tweet.image}
                    name={tweet.name}
                  />
                </Box>
              )}
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variant="tertiary"
              // colorScheme="blue"
              // bg="blue.700"
              // color="white"
              // _hover={{ bg: "blue.600" }}
              onClick={async () => {
                onClose();
              }}
              // ml={3}
            >
              Cancel
            </Button>
            {id ? (
              <CopyToClipboard
                text={"https://taplio.com/public?id=" + id}
                onCopy={() => {
                  toast.success("Public url copied to clipboard", {
                    style: {
                      background: "gray.600",
                      color: "#222",
                    },
                  });
                }}
              >
                <Button
                  aria-label="GitHub"
                  variant={variant.Button.primary}
                  leftIcon={<FiShare2 fontSize="20px" />}
                >
                  Share
                </Button>
              </CopyToClipboard>
            ) : (
              <></>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
