import { firebaseClient, getToken } from "../firebaseClient";
import { postData } from "../utils/helpers";
import * as analytics from "../utils/analytics";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { isAllowed, useCredit } from "./subscription";
import { getAccount } from "utils/sessionHelper";
import { CustomToast } from "components/toasts/CustomToast";

export const generate = async (session, tweet, count=1, tempLevel=0, mainContext=null as any) => {

    console.log("generate variations");
    console.log(tweet);

    try {

      // if (! await useCredit(session, mainContext))
      //   return;
      let useBestAi = await useCredit(session, mainContext, "creditsBestai", false);
      
      // let url = `https://api.tweetbutler.com/variations`;
      let url = `https://us-central1-ez4cast.cloudfunctions.net/tweetButler-variations`;

      let dataToSend: any = {
        text: tweet.full_text,
        count: count,
        version: 3,
        tempLevel: tempLevel ?? 0,
        isInfluentLeader: true,
        isAdmin: session?.user?.data?.isAdmin ?? false,
        useBestAi,
        idUser: getAccount(session)?.id,
        description: getAccount(session)?.description,
      }

      // if (session?.user?.data?.isAdmin) {
      if (session?.user?.data?.creditsAuto > 0) {
        dataToSend.model = "j1-jumbo/tweethunter-jumbo-v0.";
        dataToSend.version = "1";
      }
  
      // start toast custom
      const toastIdCustom = toast.loading(<CustomToast useCreditBoolean={useBestAi} />);
      
      let response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(dataToSend),
      });

      let data = await response.json();

      // end toast custom 
      toast.dismiss(toastIdCustom);
  
      if (data.success === 0) {
        toast.error(data.error, {style: {background: "gray.600",color: "#222"}});
        
      }
      else if (data?.data?.variations?.length == 0) {
        toast.error("Generating the variation failed", {style: {background: "gray.600",color: "#222"}});
      }
      else {
        if (session?.user?.data?.creditsAuto > 0) {
          const db = firebaseClient.firestore();
          await db.collection("users").doc(session.user.uid).update({creditsAuto: firebaseClient.firestore.FieldValue.increment(-1)});
          session.user.data.creditsAuto --;
        }
        
      }

      
      
      return data;
    }
    catch (e) {
      console.log("Error in fetch generate: " + e.message);
      toast.error("An error occurred, please try again", {style: {background: "gray.600",color: "#222"}});
      Sentry.captureException(e);
    }
};
