//cursor: disable
import { getAccount, updateUser } from "../utils/sessionHelper";
import toast from "react-hot-toast";
import { useLinkedinApi, apiCallHookObj } from "utils/useApi";
import { confirmApiBridgeLoad } from "utils/useApi";
import { getToken } from "../firebaseClient";
import {
  accountSubCollection,
  accountUserObject,
} from "../types/accountCollection";

export async function connectLinkedin(session, setIsLoading) {
  try {
    console.log("connectLinkedin");

    if (!confirmApiBridgeLoad()) {
      toast.error(
        "Taplio Chrome Extension not found. Make sure it's up and running on this same browser to proceed further."
      );
      return;
    }
    setIsLoading(true);
    return toast.promise(
      new Promise(async (resolve, reject) => {
        const apiCall: apiCallHookObj = {
          mode: "func",
          args: {},
          callName: "fetchMe",
          callbackFunction: async (data) => {
            const res = await fetchMe(session, data);
            if (res.success === 0) reject(res.error.error);
            // else resolve(res.result);
            const profileData = res.result;
            const formattedAccountData = await formatAccountData(
              { ...profileData, ...data },
              session
            );
            const updateSetAccountResult = await updateAccountData(
              session,
              formattedAccountData
            );
            updateSetAccountResult.success === 1
              ? resolve(updateSetAccountResult)
              : reject(updateSetAccountResult.error);

            setIsLoading(false);
          },
        };

        await Promise.race([
          useLinkedinApi(apiCall),
          setTimeout(
            () => reject("Connection with the Taplio Extension failed"),
            30000
          ),
        ]);
      }),
      {
        loading: "loading ... ",
        success: "LinkedIn connection updated successfully.",
        error: (err) =>
          err ? err.toString() : "Error connecting with your LinkedIn account",
      }
    );
  } catch (e) {
    console.log("error:", e);
    return { success: 0, error: e?.message };
  }
}

export async function fetchMe(session, liConnResults) {
  const tokenuserid = session?.user?.uid;

  if (!tokenuserid) {
    throw new Error("User is not authorised");
  }

  const token = await getToken(session);

  if (Boolean(liConnResults) === true) {
    const params = {
      dashEntiryUrn: liConnResults?.dashEntityUrn,
      idUser: getAccount(session)?.id,
      username: liConnResults?.username,
      token: token,
      tokenUserId: tokenuserid,
      isInfluentLeader: true,
      idLinkedin: liConnResults?.idUser,
    };

    const result = await fetch("/api/fetchMe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ ...params, isTest: false }),
    });

    const data = await result.json();

    return data;
  } else {
    return false;
  }
}

export async function formatAccountData(accountData, session) {
  console.log("accountData:", accountData);
  const accountFromSession = getAccount(session);
  const documentId = accountData.idUser.replace("urn:li:member:", "");

  const formattedAccountData: accountSubCollection = {
    id: documentId,
    idAccount: documentId,
    image: accountData.imageHosted,
    type: "linkedin",
    accessToken: accountData.cookie_li_at,
    dateAdded: new Date(),
    access_token: accountData.cookie_li_at,
    masterUserId: getAccount(session)?.id,
    needInit: true,
    provider: "linkedin",
    name: accountData.first_name + " " + accountData.last_name,
    accessTokenExpires: new Date(
      Date.now() + 10 * 365 * 24 * 60 * 60 * 1000
    ).getTime(),
    callbackUrl: null,
    dateLastUpdate: new Date(),
    expires_in: 10 * 365 * 24 * 60 * 60 * 1000,
    thWriteAccessToken: accountData.cookie_li_at,
    email: accountData.email ?? null,
    idParent: getAccount(session)?.id,
    keywords: accountData.skills,
    dashEntityUrn: accountData.dashEntityUrn,
    idLinkedin: accountData.idUser,
    username: accountData.username,
    isLinkedinCookiesInvalidated: false,
    user: {
      firstName: accountData.first_name,
      lastName: accountData.last_name,
      image: accountData.imageHosted,
      dashEntityUrn: accountData.dashEntityUrn,
      occupation: accountData.summary,
      twUserName: accountData.username,
      about: accountData.summary,
      username: accountData.username,
      id: accountData.dashEntityUrn, //dashEntityUrn
      subTitle: accountData.sub_title,
      backgroundImage: accountData.background_image,
      type: "personal",
      profileData: accountData,
    } as accountUserObject,
  };

  console.log("formattedAccountData:", formattedAccountData);
  return formattedAccountData;
}

export async function updateAccountData(session, accountData) {
  const masterUserId = getAccount(session)?.id;
  const result = await fetch("/api/updateSetUserAccounts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify({ formattedAccountData: accountData, masterUserId }),
  });

  return await result.json();
}
