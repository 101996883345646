import { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import { Wrap, WrapItem, Text, WrapProps } from "@chakra-ui/react";
import shortid from "shortid";
import toast from "react-hot-toast";
import { Label } from "./label";
import { getAccount, updateUser } from "utils/sessionHelper";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { CreateOrFindLabels } from "./createOrFindLabel";
import { useMiscellaneousContext } from "context/miscellaneousContext";

export interface Label {
  id: string;
  title: string;
  color: string;
}

interface Props {
  selectedLabelIds: string[];
  showOnlySelectedLabels?: boolean;
  size?: "xs" | "md";
  mainContainerProps?: WrapProps;
  maxLabelsToDisplay?: number;
  showSelectedLabelsOnSearch?: boolean;
  showAddLabelButton?: boolean;
  showEditLabelOption?: boolean;
  addButtonPadding?: boolean;
  onChange: (labelIds: string[], callback?: (error?: boolean) => void) => void;
  onEnter?: () => void;
}

export const colors = [
  "gray",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
];

export function TweetLabels({
  selectedLabelIds,
  showOnlySelectedLabels = false,
  onChange,
  size = "md",
  mainContainerProps = {},
  maxLabelsToDisplay = 99999999,
  showSelectedLabelsOnSearch = false,
  showAddLabelButton = true,
  showEditLabelOption = true,
  addButtonPadding=true,
  onEnter = () => {}
}: Props) {
  const [labels, setLabels] = useState<Label[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<Label[]>([]);
  const { data: session } = useSession() ?? {};
  const isAuthenticated = useFirebaseUser();
  const miscellaneousContext: any = useMiscellaneousContext();

  useEffect(() => {
    if (session && isAuthenticated) {
      const labels = getAccount(session)?.tweetLabels ?? [];
      setLabels([...labels]);
    }
  }, [session, isAuthenticated, miscellaneousContext.refreshLabels]);

  useEffect(() => {
    const selected = labels.filter((l) => selectedLabelIds.includes(l.id));
    setSelectedLabels(selected);
  }, [selectedLabelIds, labels]);

  const handleRemoveLabel = (id: string) => {
    const updatedSelection = selectedLabelIds.filter((l) => l !== id);
    onChange(updatedSelection);
  };

  const handleAddLabel = (labelName: string) => {
    const isAlreadySelected = selectedLabels.find(
      (s) => s.title.toLowerCase() === labelName.toLowerCase()
    );

    if (isAlreadySelected) {
      toast.error("Already exist in the list");
      return;
    }

    let label = labels.find(
      (l) => l.title.toLowerCase() === labelName.toLowerCase()
    );

    if (!label) {
      const newLabelId = shortid.generate();
      label = {
        id: newLabelId,
        title: labelName,
        color: colors[Math.floor(Math.random() * colors.length)],
      };

      const updatedLabels = [...labels];
      updatedLabels.push(label);
      setLabels(updatedLabels);
      updateUser(session, { tweetLabels: updatedLabels });
      getAccount(session).tweetLabels = updatedLabels;
      miscellaneousContext.labelsRefresher({});
    }

    const selectedIds = [...selectedLabelIds];
    selectedIds.push(label.id);
    onChange(selectedIds);
  };

  const handleLabelClick = (label: Label) => {
    if (showOnlySelectedLabels) {
      return;
    }

    const isAlreadyExist = selectedLabelIds.find((id) => id === label.id);
    if (isAlreadyExist) {
      handleRemoveLabel(label.id);
    } else {
      handleAddLabel(label.title);
    }
  };

  const displayLabels = showOnlySelectedLabels ? selectedLabels : labels;

  return (
    <Wrap justify="center" position="relative" overflow="initial" fontSize="sm" {...mainContainerProps} spacing={0}>
      {displayLabels.slice(0, maxLabelsToDisplay).map((label) => (
        <WrapItem key={`label-${label.id}`}>
          <Label
            {...label}
            isSelected={
              showOnlySelectedLabels
                ? true
                : selectedLabelIds.includes(label.id)
            }
            showOption={showEditLabelOption}
            onRemove={handleRemoveLabel}
            size={size}
            showRemove={showOnlySelectedLabels}
            cursor={showOnlySelectedLabels ? "default" : "pointer"}
            onClick={() => handleLabelClick(label)}
          />
        </WrapItem>
      ))}
      {displayLabels.length > maxLabelsToDisplay && (
        <WrapItem alignItems="center">
          <Text color="gray">
            ... and {displayLabels.length - maxLabelsToDisplay} other
          </Text>
        </WrapItem>
      )}
      {showAddLabelButton && (
        <WrapItem alignSelf={"flex-end"} width={"24px"} padding={(addButtonPadding)?"10px":0}>
          <CreateOrFindLabels
            size={size}
            selectedLabels={selectedLabels}
            showSelectedLabels={showSelectedLabelsOnSearch}
            onRemove={handleRemoveLabel}
            onAdd={handleAddLabel}
            showSearchResults={showOnlySelectedLabels}
            onEnter={onEnter}
          />
        </WrapItem>
      )}
    </Wrap>
  );
}
