import { grow } from "controllers/search";
import toast from "react-hot-toast";
import { Post, PostResponse } from "types/post";

const staticParams = {
    isPersonalizedSorting: false,
    nbMinDaysOld: 0,
    nbMaxDaysOld: 1000,
    nbMinLikes: 0,
    nbMinRt: 0,
    nbMinFollowers: 0,
    nbMaxFollowers: 999999999,
    count: 5,
    mode: "people",
}

export const getPersonLastPost = async (engageAccountUsername: string, userId: string, session: any) => {
    try {
        const params = {
            ...staticParams,
            engageAccounts: engageAccountUsername,
            userId,
        };

        let data: PostResponse = await grow(session, params);
        const tweets = data?.tweets;

        if (data.success !== 0 && tweets !== undefined) {
            return tweets as Post[];
        }
        else {
            throw new Error("No data found");
        }
    }
    catch (error) {
        console.log("Error in fetching: ", error);
        toast.error("Error in fetching: " + error.message);
        return error
    }
}