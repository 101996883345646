import { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import {
  Text,
  Textarea,
  Button,
  Box,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { BsInfoCircle } from "react-icons/bs";
import { GRADIENT_COLOR } from "./utils";
import { getAccount, updateUser } from "utils/sessionHelper";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { SettingsIcon } from "./icons/settings-icon";

export function SettingsButton() {
  const [who, setWho] = useState("");
  const [topics, setTopics] = useState("");
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: session } = useSession() ?? {};
  const isAuthenticated = useFirebaseUser();
  const textColor = useColorModeValue("blue.400", "blue.300");

  useEffect(() => {
    if (isAuthenticated && !hasFetched) {
      setHasFetched(true);
      setWho(getAccount(session)?.who || "");
      setTopics(getAccount(session)?.topics || "");
    }
  }, [session, isAuthenticated]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await updateUser(session, { who, topics });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          aria-label="Open settings"
          icon={<SettingsIcon />}
          variant="action"
          size="sm"
          color="#D792FF !important"
        />
      </PopoverTrigger>
      <PopoverContent width="400px">
        <PopoverHeader display="flex" alignItems="center">
          <FiSettings size={16} />{" "}
          <Text ml="2" bgGradient={GRADIENT_COLOR} bgClip="text" fontWeight="bold">
            AI settings
          </Text>
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p="3">
          <HStack color={textColor} alignItems="flex-start">
            <Box mt="0.5">
              <BsInfoCircle size={16} />
            </Box>
            <Text fontSize="sm">
              The best way to improve the results of our AI is for it to understand how you want to position yourself on
              LinkedIn.
            </Text>
          </HStack>
          <VStack mt="6" spacing="3" fontSize="sm">
            <Text color={textColor} w="100%" textAlign="left">
              You are...
            </Text>
            <Textarea
              fontSize="sm"
              placeholder="Personal description..."
              value={who}
              onChange={(e) => setWho(e.target.value)}
            />
          </VStack>
          <VStack mt="8" spacing="3" fontSize="sm">
            <Text color={textColor} w="100%" textAlign="left">
              What you talk about...
            </Text>
            <Textarea
              fontSize="sm"
              placeholder="Personal description..."
              value={topics}
              onChange={(e) => setTopics(e.target.value)}
            />
          </VStack>
          <Box mt="5" w="100%" textAlign="right">
            <Button variant="primary" size="sm" isLoading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
