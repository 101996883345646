import { useState, useEffect, useMemo, useRef } from "react";
import { useSession } from "next-auth/react";
import {
  Box,
  IconButton,
  Button,
  Text,
  TextProps,
  Input,
  Wrap,
  Divider,
  useDisclosure,
  useColorModeValue,
  WrapItem,
  Flex,
} from "@chakra-ui/react";
import { AddTagIcon } from "./add-tag-icon";
import { useOnClickOutside } from "utils/helpers";
import { LabelProps, Label } from "./label";
import { getAccount } from "utils/sessionHelper";
import { EditLabel } from "components/popups/edit-label";
import { BsThreeDots } from "react-icons/bs";

interface Props {
  selectedLabels: LabelProps[];
  showSelectedLabels?: boolean;
  showSearchResults?: boolean;
  size?: "xs" | "md";
  onRemove?: (id: string) => void;
  onAdd?: (title: string) => void;
  onEnter?: () => void;
}

export function CreateOrFindLabels({
  selectedLabels = [],
  showSelectedLabels,
  showSearchResults = true,
  size = "md",
  onRemove = () => {},
  onAdd = () => {},
  onEnter = () => {},
}: Props) {
  const [labelName, setLabelName] = useState<string>("");
  const [filteredLabels, setFilteredLabels] = useState<LabelProps[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<LabelProps>({} as LabelProps);
  const popupRef = useRef(null);
  const { data: session } = useSession() ?? {};
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();
  const popupBg = useColorModeValue("white", "gray.800");
  const labelHoverBg = useColorModeValue("gray.50", "gray.700");
  const labels: LabelProps[] = getAccount(session)?.tweetLabels ?? [];
  // const nonSelectedLabels = useMemo(
  //   () => labels.filter((l) => selectedLabels.every((s) => s.id !== l.id)),
  //   [labels, selectedLabels]
  // );

  useOnClickOutside(popupRef, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    console.log("useOnClickOutside");
    setLabelName("");
  });

  useEffect(() => {
    // console.log("nonSelectedLabels", nonSelectedLabels);
    // console.log("labelName", labelName);
    if (showSearchResults) {
      const filtered = labels.filter((l) => selectedLabels.every((s) => s.id !== l.id)).filter((l) =>
        l.title.toLowerCase().includes(labelName.toLowerCase())
      );
      setFilteredLabels(filtered);
    }
  }, [labelName, selectedLabels]);

  let labelHelperTextProps: TextProps, inputSize, labelFontSize;
  switch (size) {
    case "xs":
      labelHelperTextProps = {
        fontSize: "xs",
        mt: "3",
        mb: "2",
      };
      inputSize = "sm";
      labelFontSize = "sm";
      break;
    case "md":
    default:
      labelHelperTextProps = {
        fontSize: "sm",
        mt: "6",
        mb: "5",
      };
      inputSize = "md";
      labelFontSize = "md";
      break;
  }

  return (
    <Box width={"27px"}>
      <Box
        as="button"
        aria-label="add label"
        width={"27px"}
        onClick={onToggle}
        p={0}
        pl={"3px"}
        mt={"3px"}
        title={"add label"}
      >
        <AddTagIcon />
      </Box>
      {isOpen && (
        <Box
          position="absolute"
          width="250px"
          zIndex="201"
          right="0"
          boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px"
          rounded="md"
          bg={popupBg}
          ref={popupRef}
        >
          {showSelectedLabels && selectedLabels.length > 0 && (
            <Wrap p="2">
              {selectedLabels?.map((l) => (
                <WrapItem key={`label-${l.id}`}>
                  <Label
                    size="xs"
                    {...l}
                    onRemove={onRemove}
                    showOption={false}
                    isSelected
                  />
                </WrapItem>
              ))}
            </Wrap>
          )}
          <Box py="5px">
            <Input
              py="5px"
              placeholder="Search or create new"
              border="none"
              size={inputSize}
              _focus={{ boxShadow: "none" }}
              value={labelName}
              onChange={(e) => {
                setLabelName(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  if (!labelName) {
                    return;
                  }
                  onAdd(labelName);
                  setLabelName("");
                  onClose();
                  onEnter();
                }
              }}
            />
          </Box>
          <Box maxH="150px" overflow="auto">
            {filteredLabels.map((label) => (
              <Box key={"filtered-" + label.id}>
                <Divider />
                <Flex
                  justifyContent="space-between"
                  p="3"
                  cursor="pointer"
                  _hover={{
                    bg: labelHoverBg,
                  }}
                  onClick={() => onAdd(label.title)}
                >
                  <Text
                    textAlign="left"
                    ml="3"
                    fontSize={labelFontSize}
                    color={label.color + ".500"}
                  >
                    {label.title}
                  </Text>
                  <IconButton
                    aria-label="edit label"
                    variant="ghost"
                    color="gray"
                    size="xs"
                    p="1"
                    as={BsThreeDots}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLabel(label);
                      onOpenEdit();
                    }}
                  />
                </Flex>
              </Box>
            ))}
          </Box>
          <Divider />
          {filteredLabels.length === 0 && (
            <Box p="2" pt="0">
              <Text
                fontWeight="semibold"
                textAlign="center"
                {...labelHelperTextProps}
              >
                {!labelName ? (
                  <i>Pick a name for your new label</i>
                ) : (
                  <i>Press enter to create and assign this new label</i>
                )}
              </Text>
            </Box>
          )}
        </Box>
      )}
      <EditLabel isOpen={isOpenEdit} onClose={onCloseEdit} label={selectedLabel} />
    </Box>
  );
}
