
export const variant = {
  Alert: {
    withIcons: "withIcons",
  },
  Button: {
    ai: "ai",
    taplioPayment: "taplioPayment",
    taplioPaymentOutline: "taplioPaymentOutline",
    primary: "primary",
    primaryAI: "primaryAI",
    primaryAI2: "primaryAI2",
    primaryDestructive: "primaryDestructive",
    primaryWarning: "primaryWarning",
    primaryLeft: "primaryLeft",
    primaryRight: "primaryRight",
    secondary: "secondary",
    secondaryDestructive: "secondaryDestructive",
    secondaryAccent: "secondaryAccent",
    secondaryAI: "secondaryAI",
    secondaryBookmark: "secondaryBookmark",
    tertiary: "tertiary",
    tertiaryLight: "tertiaryLight",
    tertiaryNoBackground: "tertiaryNoBackground",
    invisible: "invisible",
    ghost: "ghost",
    switcher: "switcher",
    action: "action",
    actionBg: "actionBg",
    actionDestructive: "actionDestructive",
    actionGray: "actionGray",
    actionEditPost: "actionEditPost",
    actionAI: "actionAI",
    suggestion: "suggestion",
    feather: "feather",
    featherLight: "featherLight",
    light: "light",
    lightSelected: "lightSelected",
    rounded: "rounded",
  },
  Checkbox: {},
  Divider: {},
  Heading: {
    pageTitle: "pageTitle",
  },
  Input: {
    outline: "outline",
  },
  Link: {
    noUnderline: "noUnderline",
    light: "light",
  },
  Menu: {
    switcher: "switcher",
    secondary: "secondary",
    accountSwitcher: "accountSwitcher",
  },
  Modal: {
    info: "info",
  },
  Select: {
    switcher: "switcher",
  },
  Table: {
    simple: "simple",
    simpleNoBg: "simpleNoBg",
  },
  Tabs: {
    line: "line",
    light: "light",
    rounded: "rounded",
  },
  Tooltip: {},
};
// values for use in components

export const textStyle = {
  "h1.bold.standard": "h1.bold.standard",
  "h2.bold.standard": "h2.bold.standard",
  "h3.bold.standard": "h3.bold.standard",
  "h4.medium.standard": "h4.medium.standard",
  "h4.bold.standard": "h4.bold.standard",
  "h4.bold.neutral": "h4.bold.neutral",
  "h4.bold.white": "h4.bold.white",
  "body.medium.light": "body.medium.light",
  "body.medium.standard": "body.medium.standard",
  "body.medium.neutral": "body.medium.neutral",
  "body.medium.white": "body.medium.white",
  "body.medium.primary": "body.medium.primary",
  "body.medium.inactive": "body.medium.inactive",
  "body.bold.standard": "body.bold.standard",
  "small.bold": "small.bold",
  "small.medium.standard": "small.medium.standard",
  "small.medium.light": "small.medium.light",
  h1: { bold: { standard: "h1.bold.standard" } },
  h2: { bold: { standard: "h2.bold.standard" } },
  h3: { bold: { standard: "h3.bold.standard" } },
  h4: {
    medium: { standard: "h4.medium.standard" },
    bold: { standard: "h4.bold.standard", white: "h4.bold.white" },
  },
  body: {
    medium: {
      light: "body.medium.light",
      standard: "body.medium.standard",
      neutral: "body.medium.neutral",
      primary: "body.medium.primary",
      white: "body.medium.white",
    },
    bold: { standard: "body.bold.standard" },
  },
  small: { bold: "small.bold" },
};
// values for use in components

export const layerStyle = {
  "bg": "bg",
  "BgGray": "BgGray",
  "bgBorder": "bgBorder",
  "bgBorderGray": "bgBorderGray",
  "bgBorderRounded": "bgBorderRounded",
  "bgBorderRoundedHoverShadow": "bgBorderRoundedHoverShadow",
  "bgBorderGrayRounded": "bgBorderGrayRounded",
  "border": "border",
  "borderHover": "borderHover",
  // naming variation (same layerStyles)
  "bg.noBorder": "bg",
  "bg.noBorder.rounded": "bgRounded",
  "bg.border": "bgBorder",
  "bg.border.rounded": "bgBorderRounded",
  "border.noHover": "border",
  "border.hover": "borderHover",
  taplioPublicBgBorderRounded: "taplioPublicBgBorderRounded",
  // bg: {
  //   noBorder: "bg",
  //   noBorderRounded: "bgRounded",
  //   border: "bgBorder",
  //   borderRounded: "bgBorderRounded",
  // },
  // border: {
  //   noHover: "border",
  //   hover: "borderHover",
  // }
};
// values for use in components

export const color = {
  "neutral.lightMode.neutral": "neutral.lightMode.neutral",
  "neutral.darkMode.neutral": "neutral.darkMode.neutral",
  "primary.lightMode.50": "primary.lightMode.50",
  "primary.lightMode.100": "primary.lightMode.100",
  "primary.lightMode.200": "primary.lightMode.200",
  "primary.lightMode.default": "primary.lightMode.default",
  "primary.lightMode.400": "primary.lightMode.400",
  "primary.lightMode.500": "primary.lightMode.500",
  "primary.darkMode.100": "primary.darkMode.100",
  "primary.darkMode.200": "primary.darkMode.200",
  "primary.darkMode.default": "primary.darkMode.default",
  "primary.darkMode.400": "primary.darkMode.400",
  "primary.darkMode.500": "primary.darkMode.500",
  "secondary.lightMode.100": "secondary.lightMode.100",
  "secondary.lightMode.200": "secondary.lightMode.200",
  "secondary.lightMode.default": "secondary.lightMode.default",
  "secondary.lightMode.400": "secondary.lightMode.400",
  "secondary.lightMode.500": "secondary.lightMode.500",
  "secondary.darkMode.100": "secondary.darkMode.100",
  "secondary.darkMode.200": "secondary.darkMode.200",
  "secondary.darkMode.default": "secondary.darkMode.default",
  "secondary.darkMode.400": "secondary.darkMode.400",
  "secondary.darkMode.500": "secondary.darkMode.500",
  "text.lightMode.standard": "text.lightMode.standard",
  "text.lightMode.light": "text.lightMode.light",
  "text.lightMode.inactive": "text.lightMode.inactive",
  "text.darkMode.standard": "text.darkMode.standard",
  "text.darkMode.light": "text.darkMode.light",
  "text.darkMode.inactive": "text.darkMode.inactive",
  "state.lightMode.inactive": "state.lightMode.inactive",
  "state.darkMode.inactive": "state.darkMode.inactive",
  "info.lightMode.100": "info.lightMode.100",
  "info.lightMode.200": "info.lightMode.200",
  "info.lightMode.default": "info.lightMode.default",
  "info.lightMode.400": "info.lightMode.400",
  "info.lightMode.500": "info.lightMode.500",
  "info.darkMode.100": "info.darkMode.100",
  "info.darkMode.200": "info.darkMode.200",
  "info.darkMode.default": "info.darkMode.default",
  "info.darkMode.400": "info.darkMode.400",
  "info.darkMode.500": "info.darkMode.500",
  "success.lightMode.100": "success.lightMode.100",
  "success.lightMode.200": "success.lightMode.200",
  "success.lightMode.default": "success.lightMode.default",
  "success.lightMode.400": "success.lightMode.400",
  "success.lightMode.500": "success.lightMode.500",
  "success.darkMode.100": "success.darkMode.100",
  "success.darkMode.200": "success.darkMode.200",
  "success.darkMode.default": "success.darkMode.default",
  "success.darkMode.400": "success.darkMode.400",
  "success.darkMode.500": "success.darkMode.500",
  "warning.lightMode.100": "warning.lightMode.100",
  "warning.lightMode.200": "warning.lightMode.200",
  "warning.lightMode.default": "warning.lightMode.default",
  "warning.lightMode.400": "warning.lightMode.400",
  "warning.lightMode.500": "warning.lightMode.500",
  "warning.darkMode.100": "warning.darkMode.100",
  "warning.darkMode.200": "warning.darkMode.200",
  "warning.darkMode.default": "warning.darkMode.default",
  "warning.darkMode.400": "warning.darkMode.400",
  "warning.darkMode.500": "warning.darkMode.500",
  "alert.lightMode.100": "alert.lightMode.100",
  "alert.lightMode.200": "alert.lightMode.200",
  "alert.lightMode.default": "alert.lightMode.default",
  "alert.lightMode.400": "alert.lightMode.400",
  "alert.lightMode.500": "alert.lightMode.500",
  "alert.darkMode.100": "alert.darkMode.100",
  "alert.darkMode.200": "alert.darkMode.200",
  "alert.darkMode.default": "alert.darkMode.default",
  "alert.darkMode.400": "alert.darkMode.400",
  "alert.darkMode.500": "alert.darkMode.500",
  "border.lightMode.inactive": "border.lightMode.inactive",
  "border.lightMode.light": "border.lightMode.light",
  "border.lightMode.hover": "border.lightMode.hover",
  "border.lightMode.active": "border.lightMode.active",
  "border.darkMode.inactive": "border.darkMode.inactive",
  "border.darkMode.light": "border.darkMode.light",
  "border.darkMode.hover": "border.darkMode.hover",
  "border.darkMode.active": "border.darkMode.active",
  "background.lightMode.light": "background.lightMode.light",
  "background.lightMode.medium": "background.lightMode.medium",
  "background.lightMode.disabled": "background.lightMode.disabled",
  "background.darkMode.light": "background.darkMode.light",
  "background.darkMode.medium": "background.darkMode.medium",
  "background.darkMode.disabled": "background.darkMode.disabled",
  neutral: {
    lightMode: {
      neutral: "neutral.lightMode.neutral",
    },
    darkMode: {
      neutral: "neutral.darkMode.neutral",
    },
  },
  primary: {
    lightMode: {
      50: "primary.lightMode.50",
      100: "primary.lightMode.100",
      200: "primary.lightMode.200",
      default: "primary.lightMode.default",
      400: "primary.lightMode.400",
      500: "primary.lightMode.500",
    },
    darkMode: {
      100: "primary.darkMode.100",
      200: "primary.darkMode.200",
      default: "primary.darkMode.default",
      400: "primary.darkMode.400",
      500: "primary.darkMode.500",
    },
  },
  secondary: {
    lightMode: {
      100: "secondary.lightMode.100",
      200: "secondary.lightMode.200",
      default: "secondary.lightMode.default",
      400: "secondary.lightMode.400",
      500: "secondary.lightMode.500",
    },
    darkMode: {
      100: "secondary.darkMode.100",
      200: "secondary.darkMode.200",
      default: "secondary.darkMode.default",
      400: "secondary.darkMode.400",
      500: "secondary.darkMode.500",
    },
  },
  text: {
    lightMode: {
      standard: "text.lightMode.standard",
      light: "text.lightMode.light",
      inactive: "text.lightMode.inactive",
    },
    darkMode: {
      standard: "text.darkMode.standard",
      light: "text.darkMode.light",
      inactive: "text.darkMode.inactive",
    },
  },
  state: {
    lightMode: {
      inactive: "state.lightMode.inactive",
    },
    darkMode: {
      inactive: "state.darkMode.inactive",
    },
  },
  info: {
    lightMode: {
      100: "info.lightMode.100",
      200: "info.lightMode.200",
      default: "info.lightMode.default",
      400: "info.lightMode.400",
      500: "info.lightMode.500",
    },
    darkMode: {
      100: "info.darkMode.100",
      200: "info.darkMode.200",
      default: "info.darkMode.default",
      400: "info.darkMode.400",
      500: "info.darkMode.500",
    },
  },
  success: {
    lightMode: {
      100: "success.lightMode.100",
      200: "success.lightMode.200",
      default: "success.lightMode.default",
      400: "success.lightMode.400",
      500: "success.lightMode.500",
    },
    darkMode: {
      100: "success.darkMode.100",
      200: "success.darkMode.200",
      default: "success.darkMode.default",
      400: "success.darkMode.400",
      500: "success.darkMode.500",
    },
  },
  warning: {
    lightMode: {
      100: "warning.lightMode.100",
      200: "warning.lightMode.200",
      default: "warning.lightMode.default",
      400: "warning.lightMode.400",
      500: "warning.lightMode.500",
    },
    darkMode: {
      100: "warning.darkMode.100",
      200: "warning.darkMode.200",
      default: "warning.darkMode.default",
      400: "warning.darkMode.400",
      500: "warning.darkMode.500",
    },
  },
  alert: {
    lightMode: {
      100: "alert.lightMode.100",
      200: "alert.lightMode.200",
      default: "alert.lightMode.default",
      400: "alert.lightMode.400",
      500: "alert.lightMode.500",
    },
    darkMode: {
      100: "alert.darkMode.100",
      200: "alert.darkMode.200",
      default: "alert.darkMode.default",
      400: "alert.darkMode.400",
      500: "alert.darkMode.500",
    },
  },
  border: {
    lightMode: {
      inactive: "border.lightMode.inactive",
      light: "border.lightMode.light",
      hover: "border.lightMode.hover",
      active: "border.lightMode.active",
    },
    darkMode: {
      inactive: "border.darkMode.inactive",
      light: "border.darkMode.light",
      hover: "border.darkMode.hover",
      active: "border.darkMode.active",
    },
  },
  background: {
    lightMode: {
      light: "background.lightMode.light",
      medium: "background.lightMode.medium",
      disabled: "background.lightMode.disabled",
    },
    darkMode: {
      light: "background.darkMode.light",
      medium: "background.darkMode.medium",
      disabled: "background.darkMode.disabled",
    },
  },
};

export const colorScheme = {
  primaryChakraColorScheme: "primaryChakraColorScheme"
};

export const radius = {
  "sm": "5px",
  "md": "7px",
  "lg": "10px",
}
