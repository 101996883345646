import {
  Box,
  Link,
  Text,
  Heading,
  Image
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { layerStyle, radius, textStyle } from "theme/names";
interface Props {
  articleDetails: {
    title: any,
    navigationContext: any,
    hrefLink: any,
    subtitle: any,
    largeImage: any,
  };
};

export function ArticlePreview({ articleDetails }: Props) {
  const articleTitle = articleDetails?.title?.text;
  const articleSubTitle = articleDetails?.subtitle?.text;
  const articleLink = articleDetails?.navigationContext?.actionTarget || articleDetails?.hrefLink;
  const articleImage = articleDetails?.largeImage?.attributes?.[0]?.vectorImage?.rootUrl + articleDetails?.largeImage?.attributes?.[0]?.vectorImage?.artifacts?.[0]?.fileIdentifyingUrlPathSegment;
  const articleImageAlt = articleDetails?.navigationContext?.accessibilityText || articleSubTitle;

  return (
    <Link 
      href={articleLink}
      isExternal 
      w="100%"
      title={articleImageAlt}
    >
      <Box
        // w="100%"
        // borderWidth='1px'
        // borderRadius='sm'
        // overflow='hidden'
        // bg='gray.200'
        // display="flex"
        // flexDirection="column"
        // alignItems="start"
        layerStyle={layerStyle.bgBorderGray}
        borderRadius={radius.sm}
        w="100%"
        overflow='hidden'
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Image 
          src={articleImage} 
          alt={articleImageAlt} 
          borderTopStartRadius="sm" 
          fit="cover" 
          h="100%" 
          onError={(e) => {
            //remove img if the image doesn't load for whatever context...
            //@ts-ignore
            if(e?.target != undefined) e?.target?.remove();
          }}
        />
        <Heading
          size="sm"
          w="100%"
          pl={3}
          pt={3}
        >
          {articleTitle}
        </Heading>
        <Box
          flexDirection="row"
          w="100%"
          pl={3}
          pb={3}
        >
          <Text textStyle={textStyle["body.medium.light"]}
            display="inline-block"
            sx={{fontSize:'0.9rem'}}
          >
            {articleSubTitle}
          </Text>
          <BiLinkExternal
            size="18px"
            title="Open external link"
            style={{display:"inline-block", paddingLeft:"3px", verticalAlign:"middle"}}
          />
        </Box>
      </Box>
    </Link>
  );
}
