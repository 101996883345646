import { useMemo } from "react";
import { useSession } from "next-auth/react";
import { Tr, Td, IconButton, Checkbox, Text, useColorModeValue } from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { getAccount } from "utils/sessionHelper";

interface Props {
  time: any;
  index: number;
  onDelete: () => void;
  onChange: (indexDay: number, isChecked: boolean) => void;
}

export function Schedule({ time, index, onDelete, onChange }: Props) {
  const { data: session } = useSession() ?? {};
  const miscellaneousContext: any = useMiscellaneousContext();
  const labels = useMemo(() => getAccount(session)?.tweetLabels, [session, miscellaneousContext.refreshLabels]);
  const labelConfig = labels?.find((l) => l.id === time?.labels?.[0]);

  return (
    <Tr
      key={"time-" + time.hour + "-" + time.minute}
      bg={time.isEvergreen ? useColorModeValue("green.100", "green.800") : "transparent"}
    >
      <Td display="flex" alignItems="center" textStyle={"body.medium.standard"}>
        {time.hour > 12 ? time.hour - 12 : time.hour === 0 ? 12 : time.hour}:
        {time.minute ? (time.minute < 10 ? "0" + time.minute : time.minute) : "00"}
        {time.hour >= 12 ? " pm" : " am"}
        <IconButton
          aria-label="delete time"
          variant="unstyled"
          size="sm"
          mr={3}
          icon={<SmallCloseIcon />}
          onClick={onDelete}
        />
        {time.isEvergreen ? "🌲" : labelConfig ? <Text as="span" color={labelConfig.color} fontWeight="semibold">{labelConfig.title}</Text> : ""}
      </Td>
      {
        //@ts-ignore
        time.days.map((d, indexDay) => (
          <Td key={"time" + index + indexDay} textAlign="center">
            <Checkbox
              defaultChecked={d}
              onChange={(e) => {
                onChange(indexDay, e.target.checked);
              }}
            />
          </Td>
        ))
      }
    </Tr>
  );
}
