import Icon from "@chakra-ui/icon";
import { Box, Flex, Stack, Text, Link } from "@chakra-ui/layout";
import React from "react";
import styled from "@emotion/styled";
import { GoLocation, GoLink } from "react-icons/go";
import theme from "@chakra-ui/theme";
import { Image } from "@chakra-ui/image";
import twitter from "twitter-text";
import numbro from "numbro";
import { textStyle } from "theme/names";
import { ImageNextChakra } from 'components/common/image-next-chakra';

const StyledText = styled.div`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

const UserPopup = ({ user, tweet = undefined as any, type = "twitter" as any, linkOverride = "", backgroundColor = "" }) => {

  // console.log("user.image: " + user.image);
  let profileUrl = linkOverride ? linkOverride : (tweet?.type == "linkedin" ? `https://linkedin.com/in/${user.username}` : `https://twitter.com/${user.screen_name}`);

  return (
    <Box p={4} maxW={["xs", "md"]} borderWidth="1px" borderRadius="lg" backgroundColor={backgroundColor}>
      <Flex justifyContent="space-between" >
        <Stack mb={2} direction="row" alignItems="center">
          <Link
            href={profileUrl}
            target="_blank"
          >
            {/* <Image
              borderRadius="full"
              boxSize="40px"
              src={type == "linkedin" ? user.image : user.profile_image_url_https}
            /> */}
            <Box w="40px" h="40px" rounded="full" overflow="hidden">
              <ImageNextChakra
                width={40}
                height={40}
                src={type == "linkedin" ? user.image : user.profile_image_url_https}
                alt={user.firstName + " " + user.lastName + " profile picture"}
                fallbackSrc="/emptyProfile.png"
                isSwitchToAvatar={true}
              />
            </Box>
          </Link>
          <Box>
            <Text textStyle={textStyle["body.medium.light"]} noOfLines={1} wordBreak={"break-all"} maxWidth="200px">{user.name ?? user.fullName ?? (user.firstName + " " + user.lastName)}</Text>
            <Link
              color={theme.colors.gray[400]}
              href={profileUrl}
              target="_blank"
            >
              <Text textStyle={textStyle["body.medium.light"]} noOfLines={1} wordBreak={"break-all"} maxW="200px">@{user.screen_name ?? user.username}</Text>
            </Link>
          </Box>
        </Stack>
      </Flex>
      <Box mt={2} fontSize="sm">
        <Text
          textStyle={textStyle["body.medium.light"]}
          maxW={"240px"}
          noOfLines={6}
          wordBreak={"break-all"}

          fontSize={11}
          color={theme.colors.gray[400]}
          overflow="hidden"
          textOverflow="ellipsis"
        // whiteSpace={"nowrap"}
        >
          {user?.description || user?.about}
        </Text>
        {/* <StyledText
          dangerouslySetInnerHTML={{
            __html: user.description?.length > 300 ? user.description.substring(0, 300) + "..." : user.description,
          }}
        /> */}
        {/* Removing styledText along with dangerouslySetInnerHTML for a more CSS based approach - manoj */}
      </Box>
      {
        type == "twitter" && (
          <>
            <Box as="hr" my={2} />
            <Stack direction="row">
              {
                user.friends_count && (
                  <Flex alignItems="center">
                    <Text textStyle={textStyle["body.medium.light"]} mr={2} fontSize="md">{numbro(user.friends_count)?.format({
                      // mantissa: 1,
                      average: true,
                      // totalLength: 3,
                    }).replace(".0", "")}
                    </Text>
                    <Text textStyle={textStyle["body.medium.light"]} fontSize="sm" color="gray.400">Following</Text>
                  </Flex>
                )
              }
              <Flex alignItems="center">
                <Text textStyle={textStyle["body.medium.light"]} ml={2} mr={2} fontSize="md">{numbro(user.followers_count)?.format({
                  // mantissa: 1,
                  average: true,
                  // totalLength: 3,
                }).replace(".0", "")}</Text>
                <Text textStyle={textStyle["body.medium.light"]} fontSize="sm" color="gray.400">Followers</Text>
              </Flex>
            </Stack>
          </>
        )
      }
      {(user.location || user.entities?.url?.urls[0].expanded_url) && (
        <Box as="hr" my={2} />
      )}
      <Stack direction="row">
        {user.location && type == "twitter" && (
          <Flex alignItems="center">
            <Icon as={GoLocation} mr={1} />
            <Text textStyle={textStyle["body.medium.light"]} noOfLines={1} wordBreak={"break-all"} maxWidth="100px">
              {user.location}
            </Text>
          </Flex>
        )}
        {user.entities?.url?.urls[0].expanded_url && (
          <Flex alignItems="center">
            <Icon as={GoLink} mr={1} />
            <Link
              noOfLines={1} wordBreak={"break-all"}
              color={theme.colors.twitter[400]}
              href={user.entities?.url?.urls[0].url}
              target="_blank"
              maxWidth="120px"
            >
              {user.entities?.url?.urls[0].expanded_url}
            </Link>
          </Flex>
        )}
      </Stack>
    </Box>
  );
};

export default UserPopup;
