import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { Box, Center, Divider, Flex, Grid, Stack, Text } from "@chakra-ui/layout";
import { Button, IconButton, Checkbox, CheckboxGroup,
    useDisclosure,
    Portal,
    useColorModeValue,
    Link,
} from "@chakra-ui/react";
import  FocusLock from "react-focus-lock";
import { getList, toggleSaveTweetInList } from "../../controllers/bookmark";
import { useSession } from "next-auth/react";
import { AddIcon, CheckIcon, LockIcon } from '@chakra-ui/icons';
import { UpsertCollection } from "components/collection-upsert";
import tweets from 'components/tweets';
import { useOnClickOutside } from "../../utils/helpers";
import toast from "react-hot-toast";
import { getPlan } from "../../controllers/subscription";
import { useRouter } from "next/dist/client/router";
import { getAccount } from "../../utils/sessionHelper";
import { layerStyle, radius, textStyle, variant } from 'theme/names';
import * as analytics from  'utils/analytics';

export const CollectionDropdown = ({ tweet, trigger, inLists, refresher = null, isOpenPopOver, closePopOver, setIsSaved, isOpenBookmark = false, refBookmark, setCollectionLoading }) => {
    const { data: session, status } = useSession() ?? {}
    const loading = status === "loading"


    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();

    // console.log(inLists);

    return (
        <Box position="relative">
            {trigger}
            {
                isOpenBookmark && (
                <Box 
                    position="absolute" 
                    top="40px" 
                    right="0" 
                    w="300px"
                    // bg={useColorModeValue("white", "#1E1E1E")} 
                    // boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;" 
                    textStyle="body.medium.standard"
                    layerStyle={layerStyle.bgBorderRounded}
                    borderRadius={radius.lg}
                    // rounded="md" 
                    zIndex="1"
                    ref={refBookmark}
                >
                    <Stack p={5} justifyContent="left" textAlign="left" justifySelf="left">
                        {
                            getAccount(session)?.lists ? (
                                    <Stack >
                                        {
                                            getAccount(session)?.lists.map((list, index) => (
                                                list?.id != -3 && (
                                                    <Box key={index}>
                                                        <Checkbox colorScheme="blue" defaultChecked={inLists.includes(list.id) || (list.id == -3 && !getPlan(session?.user).includes("enterprise"))}
                                                            onChange={async (e) => {
                                                                // console.log(e.target.value);
                                                                setCollectionLoading(true);
                                                                if (session?.user?.uid) {
    
                                                                    if (list.id === -2 && tweet.user.id_str !== getAccount(session).id && e.target.checked) {
                                                                        toast.error("You can only add your own posts in the evergreen collection");
                                                                        setCollectionLoading(false);
                                                                        return;
                                                                    }
    
                                                                    // console.log(tweet);
                                                                    await toggleSaveTweetInList(tweet, getAccount(session).id, list.id, e.target.checked ? "add" : "remove");
                                                                    //@ts-ignore
                                                                    // refresher && refresher({}); // make the experience really bad
                                                                    if (e.target.checked) {
                                                                        if (!tweet.lists)
                                                                            tweet.lists = []
    
                                                                        tweet.lists.push(list.id);
                                                                        setIsSaved(true);
                                                                    }
                                                                    else {
                                                                        if (!tweet.lists) {
                                                                            setIsSaved(false);
                                                                        }
                                                                        else {
                                                                            tweet.lists.splice(tweet.lists.indexOf(list.id), 1);
                                                                            if (tweet.lists.length == 0)
                                                                                setIsSaved(false);
                                                                            analytics.log('saved_post_removed');
                                                                        }
                                                                    }
                                                                }
                                                                setCollectionLoading(false);
                                                            }}
                                                            isDisabled={list.id == -3 && !getPlan(session?.user).includes("enterprise")}
                                                            icon={list.id == -3 && !getPlan(session?.user).includes("enterprise") ? <LockIcon/> : <CheckIcon/>} 
    
                                                            // color={list.id == -3 && !getPlan(session?.user).includes("enterprise") ? "gray.400": useColorModeValue("#1E1E1E", "white")}
                                                        >
                                                            {
                                                                list.id == -3 && !getPlan(session?.user).includes("enterprise") ? (
                                                                    <Link onClick={(e) => {
                                                                        console.log("hit custom ai model");
                                                                        router.push("/collections?tab=customai");
                                                                    }}>
                                                                        {list.name}
                                                                    </Link>
                                                                ) : (
                                                                    <Text textStyle={textStyle["body.medium.light"]} fontWeight={list.id < 0 ? "600": "400"}>{list.name}</Text>
                                                                )
                                                            }
                                                        </Checkbox>
                                                    </Box>
                                                )
                                            ))
                                        }
                                        <Grid p={0}>
                                            <Button
                                                placeSelf="start"
                                                w="fit-content"
                                                // pl={2}
                                                // pr={2}/
                                                mt={3}
                                                // w="100%"
                                                leftIcon={<AddIcon fontSize="14px" />}
                                                variant={variant.Button.secondary}
                                                onClick={() => {
                                                    console.log("open popup create new list");
                                                    onOpen();
                                                }}
                                            >
                                                Create Collection
                                            </Button>
                                        </Grid>
                                    </Stack>
                                ) : (
                                    <Box>
                                        Error
                                    </Box>
                                )
                        }
                    </Stack>
                </Box>
                )
            }

            <UpsertCollection refresher={refresher} isUpdate={false} isOpen={isOpen} onClose={onClose} />
        </Box>
    )

} 