import { Grid } from "@chakra-ui/react";
import { ReactNode } from "react";

export const WidthContainer = ({
  children,
  max,
  ...props
}: {
  children?: ReactNode;
  max?: boolean;
}) => {
  return (
    <Grid
      placeSelf="center"
      w={["96%", "90%", "80%"]}
      maxW={max ? "" : "1024px"}
      {...props}
    >
      {children}
    </Grid>
  );
};
