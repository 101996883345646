import { forwardRef, Ref, useImperativeHandle, useCallback } from "react";
import { useRemirrorContext, useHelpers, useEditorEvent, useCommands } from "@remirror/react";
import { RemirrorJSON } from "@remirror/core-types";
import toast from "react-hot-toast"

export interface EditorRef {
  setContent: (content: any) => void;
  getText: () => void;
  getHTML: () => void;
  getTextCustom: (editorState?: RemirrorJSON) => string;
}

function getTextFromState(state: RemirrorJSON, text: string = "") {
  switch(state.type) {
    case "text":
      return text + (state.text ? state.text : "");
    case "mentionAtom":
      return text + state.attrs?.label;
    case "hardBreak":
      return text + "\n";
    case "doc":
      return state.content?.map(item => getTextFromState(item, text)).join("\n");
    case "paragraph":
      return (state.content ? state.content?.map(item => getTextFromState(item, text))?.join("") : "");
    default:
      return text;
  }
}

interface Props {
  onKeyDown?: (e: KeyboardEvent) => void;
}

export const ImperativeHandle = forwardRef(({ onKeyDown = () => {} }: Props, ref: Ref<EditorRef>) => {
  const { setContent, getState } = useRemirrorContext({ autoUpdate: true });
  const { getText, getHTML, getJSON } = useHelpers();
  const { focus } = useCommands();

  const handleKeyDown = useCallback(onKeyDown, [onKeyDown]);

  useEditorEvent("keydown", handleKeyDown);

  const getTextCustom = (state?: RemirrorJSON) => {
    if (!state) {
      state = getJSON();
    }

    const result = getTextFromState(state);
    return result;
  };

  const handlePaste = useCallback((event: any) => {
    let clipboardText = event.clipboardData?.getData("Text");

    if (clipboardText) {
      event.preventDefault();
      // This is done to avoid complexity. I hope you don't remove it. If you wish to remove it be ready to handle following cases:
      // 1. text selection overlaps between normal text and a placeholder
      // 2. 'currentCursorPosition' variable value below considers a placeholder like [topic] as a single character so if you
      // paste something after the placeholder, it will be added to a different location
      // 3. When there are 2 placeholder and if you paste some text both the placeholders will be converted to simple text
      // There could be other cases as well which I don't know about so please be careful before removing

      const allMentionAtoms = document.getElementsByClassName("remirror-mention-atom")
      let filteredTags: any[] = []
      for (let item of allMentionAtoms) {
        if (item.getAttribute("data-mention-atom-id") !== "[composer content]") {
          filteredTags.push(item)
        }
      }

      if (filteredTags.length > 0) {
        const values = filteredTags.map(t => t.innerText?.slice(1, t.innerText.length - 1))
        toast.error(`Resolve ${values.join(", ")} before pasting`)
        return
      }

      const state = getState()
      let { from: currentCursorPosition, to } = state.selection
      currentCursorPosition = currentCursorPosition - 1 // first postion comes at 1 but usually in javascript first value is 0 so adjusting it here cause it'll simplify rest of the calculations below
      to = to - 1 // same issue as above

      let currentTextInEditor = getTextCustom();
      currentTextInEditor = currentTextInEditor.replaceAll("[composer content]", "~")

      // when its a selection
      if (currentCursorPosition !== to) {
        currentTextInEditor = currentTextInEditor.slice(0, currentCursorPosition) + currentTextInEditor.slice(to)
      }

      // have to do this cause slice is considering \n as a single character meaning '\n'.length results in 1
      // meanwhile the currentCursorPosition considers \n as 2 characters so this is just my way of fixing that
      let firstHalf = ""
      let i = 0
      let noOfNewLines = 0
      while (i < currentCursorPosition) {
        firstHalf += currentTextInEditor[i]
        if (currentTextInEditor[i] === "\n") {
          currentCursorPosition--
          noOfNewLines++
        }
        i++
      }
      const secondHalf = currentTextInEditor.replace(firstHalf, "")
      let text = firstHalf + clipboardText + secondHalf;
      text = text.replaceAll("~", `<highlight-input class="remirror-mention-atom remirror-mention-atom-highlight-input" data-mention-atom-id="[composer content]" data-mention-atom-name="highlight-input" contenteditable="false">[composer content]</highlight-input>`)

      const textAsArray = text.split("\n")
      const htmlString = textAsArray.map(t => `<p>${t}</p>`).join("");

      setContent(htmlString);

      // // '\n' is considered as a single character so if you do '\n'.length it will return 1 which is not correct
      // // for our case here. We need it to be 2 so the cursor can be placed accurately
      const clipboardTextLength = clipboardText.replaceAll("\n", "  ").length
      focus(currentCursorPosition + noOfNewLines + clipboardTextLength + 1) // 1 is need to set it at correct position, it doesn't work without it

      return true;
    }

    return false; // perform default actions
  }, [])

  useEditorEvent("paste", handlePaste)

  useImperativeHandle(ref, () => ({ setContent, getText, getHTML, getTextCustom }));

  return <></>;
});
