import { useState, useEffect } from "react";

import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";
import { firebaseClient } from "firebaseClient";
import {
  Flex,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";

import { layerStyle, textStyle, variant } from "theme/names";
import { ContactListDropdown } from "components/contact-list-dropdown";
import { getAccount } from "utils/sessionHelper";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { addRemovePeopleToList } from "controllers/list";
import { useMiscellaneousContext } from "context/miscellaneousContext";


interface Props {
  contact: any;
}

export function List({ contact }: Props) {
  const [personLists, setPersonLists] = useState<number[]>([]);
  const [selectedList, setSelectedList] = useState<any>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [refresh, refresher] = useState({});
  const { data: session } = useSession() ?? {}
  const miscellaneousContext: any = useMiscellaneousContext();
  const isAuthenticated = useFirebaseUser();
  const contactLists = getAccount(session)?.contactLists;

  const checkForLists = async () => {
    setPersonLists(miscellaneousContext?.sidebarPerson?.lists || []);
    if (miscellaneousContext?.sidebarPerson?.lists === undefined) {
      const db = firebaseClient.firestore();

      const data = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(miscellaneousContext?.sidebarPerson?.id)
        .get();

      const contact = data.data();
      if (contact && contact.lists) {
        setPersonLists(contact.lists);
      }
    }
  };

  const handleClick = async () => {
    try {
      if (!isAuthenticated) {
        toast.error("User is not Authenticated");
        return;
      }
      if (personLists.includes(selectedList.id)) {
        toast.error(`${contact.name} already exist in the list`);
        return;
      }

      setIsSaving(true);
      let formattedContacts = [contact];
      // if contact already has image_400x400 that means its already a contact so no need to format it
      // if (!contact?.image_400x400) {
      //   formattedContacts = getFormattedContacts([contact]);
      // }

      await addRemovePeopleToList(
        getAccount(session)?.id,
        selectedList?.id,
        formattedContacts,
        "save"
      );
      const updatedPersonList = [...personLists, selectedList.id];
      setPersonLists(updatedPersonList);
      miscellaneousContext.setSidebarPerson({ ...miscellaneousContext?.sidebarPerson, lists: updatedPersonList })
      refresher({});
      setSelectedList(null);
    } catch (e) {
      console.log("Error in adding person to the list: ", e);
      toast.error("Error in adding person to the list. " + e.message);
      Sentry.captureException(e);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (miscellaneousContext.sidebarPerson) {
      checkForLists();
      setSelectedList(null);
    }
  }, [miscellaneousContext.sidebarPerson.id, refresh]);

  const listsUI = contactLists
    ?.filter((cl) => personLists?.includes(cl.id))
    .map((c) => (
      <Flex
        key={c.id}
        mt={1}
        layerStyle={layerStyle["bg.border.rounded"]}
        textStyle={textStyle["body.medium.light"]}
        // variant="outline"
        px="4"
        py="2"
        // m="1"
        display="flex"
        alignItems="center"
        textAlign="center"
      >
        {c.name}
      </Flex>
    ));

  return (
    <>
      {listsUI?.length > 0 && (
        <Flex
        // spacing={4}
          mt="4"
          // pt="4"
          flexDirection={"column"}
        // justifyContent="center"
        // flexWrap="wrap"
        >
          <Text mb={2} textStyle={textStyle["body.medium.standard"]}>Lists</Text>
          {listsUI}
        </Flex>
      )}
      {contactLists && contactLists?.length > 0 && (
        <Box mt="4" w="full">
          <ContactListDropdown
            placeholder="Add to a contact list"
            onSelectList={(list) => setSelectedList(list)}
            reset={!selectedList?.name}
            isDisabled={isSaving}
          />
          <Button
            // size="sm"
            ml="4"
            px="4"
            variant={variant.Button.primary}
            // colorScheme="twitter"
            isDisabled={!selectedList?.name}
            isLoading={isSaving}
            onClick={handleClick}
          >
            Add
          </Button>
        </Box>
      )}
    </>
  );
}
