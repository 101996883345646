import {
  Box,
  Link,
  Text,
  Heading
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { layerStyle, radius, textStyle } from "theme/names";
interface Props {
    eventDetails: {
      navigationContext: any,
      title: any,
      titleContext: any,
      hrefLink: any,
  };
};

export function EventPreview({ eventDetails }: Props) {
  const eventTitle = eventDetails?.title?.text;
  const eventDate = eventDetails.titleContext?.text;
  const eventLink = eventDetails?.navigationContext?.actionTarget || eventDetails?.hrefLink;
  const eventLinkTitle = eventDetails?.navigationContext?.accessibilityText || eventTitle;

  return (
    <Link 
      href={eventLink} 
      isExternal 
      w="100%"
      title={eventLinkTitle}
    >
      <Box
        // w="100%"
        // borderWidth='1px'
        // borderRadius='sm'
        // overflow='hidden'
        // bg='gray.200'
        // p={3}
        // display="flex"
        // flexDirection="column"
        // alignItems="start"
        layerStyle={layerStyle.bgBorderGray}
        borderRadius={radius.sm}
        w="100%"
        overflow='hidden'
        display="flex"
        flexDirection="column"
        alignItems="start"
        p={3}
      >
        <Box
          
          flexDirection="row"
          w="100%"
        >
          <Text textStyle={textStyle["body.medium.light"]}
            display="inline-block"
            sx={{fontSize:'0.9rem'}}
          >
            {eventDate}
          </Text>
          <BiLinkExternal
            size="18px"
            title="Open external link"
            style={{display:"inline-block", paddingLeft:"3px", verticalAlign:"middle"}}
          />                  
        </Box>
        <Text
          textStyle={textStyle["body.medium.standard"]} 
          w="100%"
        >
          {eventTitle}
        </Text>
      </Box>
    </Link>
  );
}
