import React, { Dispatch, memo, SetStateAction } from "react";
import { Masonry } from "masonic";
import { Status } from "twitter-d";
import Tweet from "./tweet";
import LinkedinPost from "./linkedinPost";
import News from "./news";
import { 
  Box,
} from "@chakra-ui/react";

function isEqual(prevProps, nextProps) {
  // console.log("Masory isEqual");
  // console.log("isDiff " + (prevProps.tweets != nextProps.tweets));
  // console.log("isDiff " + (prevProps.savedTweet != nextProps.savedTweet));
  // console.log("isDiff " + (prevProps.refresh != nextProps.refresh));
  if (prevProps.tweets != nextProps.tweets 
    || prevProps.savedTweet != nextProps.savedTweet
    || prevProps.refresh != nextProps.refresh
  ) {
    return false;
  }
  return true;
}

type Props = {
  tweets: [Status];
  savedTweet: [string];
  disableBookmarkButton: boolean;
  disableHideButton: boolean;
  disableRequest: boolean;
  disableEditTweet: boolean;
  isSelectable: boolean;
  disableCopy: boolean;
  disableCheckmark: boolean;
  disableSubMenu: boolean;
  refresher: Function;
  removeTweet: Function;
  mode: string;
  type: string;
  requestVariation: Function;
  setSelectedTweet: Function;
  toggleSelection: Function;
  forceSelectedTweet: any;
  masonryExtraProps: any;
  setExcludedUsers: undefined | Dispatch<SetStateAction<string[]>> ;
};

const MasonryGrid = ({
  tweets,
  savedTweet,
  type,
  masonryExtraProps={},
  setExcludedUsers=undefined,
  ...rest
}: Props) => {

  const ref = React.createRef<any>();
  // console.log("render masory " + ref?.current);

  const getPost = (tweet) => {
    if (tweet.data.type == "linkedin") {
      return (
        <LinkedinPost
          //@ts-ignore
          savedTweet={savedTweet}
          tweet={tweet}
          setExcludedUsers={setExcludedUsers}
          {...rest}
        />
        )
    }
    else if (tweet.data.type == "news") {
      return (
        <News
          //@ts-ignore
          savedTweet={savedTweet}
          tweet={tweet}
          {...rest}
        />
        )
    }
    else {
      return (
        <Tweet
          //@ts-ignore
          savedTweet={savedTweet}
          tweet={tweet}
          setExcludedUsers={setExcludedUsers}
          {...rest}
        />
      )
    }
  }

  return (
    <Box
    >
      <Masonry
        // containerRef={ref}
        key={tweets.length}
        items={tweets}
        // columnCount={4}
        columnWidth={260}
        columnGutter={10}
        // overscanBy={3}
        {...masonryExtraProps}
        render={(tweet) => {
          return getPost(tweet);
        }}
      />
    </Box>
  );
};

export default memo(MasonryGrid, isEqual);
// export default MasonryGrid;
