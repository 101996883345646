import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box, Button, Flex, Grid,
  Heading, Link, Menu,
  MenuButton, MenuItem, MenuList, Tooltip
} from "@chakra-ui/react";
import { WidthContainer } from "components/layoutHelper/WidthContainer";
import { motion } from "framer-motion";
import moment from "moment";
import { ReactNode, useState } from "react";
import {
  GradientDefs,
  Hint, HorizontalGridLines, VerticalBarSeries,
  XAxis, XYPlot, YAxis
} from "react-vis";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const lengthsOfPost = [
  "no text",
  "up to 280",
  "up to 500",
  "up to 800",
  "over 800",
];

const lengthsOfPostNew = [
  // "no text",
  "up to 280",
  "up to 500",
  "up to 800",
  "up to 1500",
  "over 1500",
];
const contentType = ["no media", "article", "image", "video", "document"];
const contentTypeLabel = ["No Media", "LinkedIn Articles", "Images", "Videos", "Carousels"];
const contentTypeValueToLabelObject = { [contentType[0]]: contentTypeLabel[0], [contentType[1]]: contentTypeLabel[1], [contentType[2]]: contentTypeLabel[2], [contentType[3]]: contentTypeLabel[3], [contentType[4]]: contentTypeLabel[4] }

const SectionContainer = ({
  title,
  recommendation = undefined as any,
  recommendationDescription = undefined as any,
  children,
  utc = undefined,
  utcSet = (val) => { },
  country
}) => {
  return (
    <Grid {...{
      mt: "72px",
      borderRadius: '20px',
      border: '1px solid #E0DFDC',
      background: '#FFF',
      p: ["20px", '60px'],
    }}>
      <Flex {...{ alignItems: "center", gap: 4, mb: "32px", flexWrap: "wrap" }}>
        <Box {...{
          as: "h2",
          color: '#586378',
          fontFamily: 'Poppins',
          fontSize: '31.903px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        }}>
          {title}
          {country !== "global" ? ` in ${country}` : ''}
          ?
        </Box>
        {utc && <Menu>
          <MenuButton
            fontWeight={700}
            // fontSize="lg"
            color="#171F2E"
            mt="2"
            as={Button}
            // size="lg"
            variant="secondary"
            rightIcon={<ChevronDownIcon />
            }>
            {utc === "utc" ? "UTC" : `${moment.tz.guess()}, UTC ${moment().format("Z")}`}
            {/* {moment.tz.guess()}, UTC {moment().format("Z")} */}
          </MenuButton>
          <MenuList>
            <MenuItem {...{
              onClick: () => {
                // console.log("click");
                utcSet("local")
              }
            }}> {moment.tz.guess()}, UTC {moment().format("Z")}</MenuItem>
            <MenuItem {...{
              onClick: () => {
                // console.log("click");
                utcSet("utc")
              }
            }}>UTC</MenuItem>
          </MenuList>
        </ Menu>}
      </Flex>
      {recommendation && <Box {...{
        color: '#171F2E',
        fontFamily: 'Poppins',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: 'normal',
      }}>
        {recommendation}
      </Box>}
      {recommendationDescription && <Box {...{
        mt: 6,
        color: '#171F2E',
        fontFamily: 'Inter',
        fontSize: '21.268px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
      }}>
        {recommendationDescription}
      </Box>}

      {children}
    </Grid>
  )
}

const ChartContainer = ({
  wide = false,
  taplioUI = false,
  title,
  explanation,
  children,
  recommendation = undefined as ReactNode | string | undefined,
  note
}) => {
  return (
    <Grid
      {...{ maxW: "100%" }}
    >
      <Grid
        gridTemplateRows={"auto"}
        placeItems="center start"
        {...{ maxW: "100%" }}
      >
        <Tooltip {...{
          label: explanation,
        }}>
          <Grid
            gridTemplateColumns={"auto 1fr"}
            placeItems="center start"
            gap="2"
            // px="4"
            pb={taplioUI ? "" : "4"}
          >
            {!taplioUI && <Heading
              {...{
                as: "h3",
                color: '#171F2E',
                fontFamily: 'Poppins',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              {title}
            </Heading>}
            {taplioUI && <Box textStyle="body.bold.standard">
              {title}
            </Box>}
            {/* info icon */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_19_8)">
                <path d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57847 12.422 0 8 0ZM8.82153 11.1757C8.82153 11.4284 8.45297 11.6811 8.00016 11.6811C7.52628 11.6811 7.18934 11.4284 7.18934 11.1757V7.16356C7.18934 6.86872 7.52631 6.66859 8.00016 6.66859C8.45297 6.66859 8.82153 6.86872 8.82153 7.16356V11.1757ZM8.00019 5.69987C7.51578 5.69987 7.13672 5.34184 7.13672 4.94166C7.13672 4.5415 7.51581 4.194 8.00019 4.194C8.47406 4.194 8.85319 4.5415 8.85319 4.94166C8.85319 5.34184 8.47403 5.69987 8.00019 5.69987Z" fill="#B7B7B7" />
              </g>
              <defs>
                <clipPath id="clip0_19_8">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Grid>
        </Tooltip>
        <Grid {...{
          gridTemplateColumns: taplioUI ? "1fr" : wide ? "auto" : ["1fr", "1fr 1fr"],
          gap: [4, 8],
          maxW: "100%"
        }}>
          <Box>
            {recommendation && <Box {...{
              color: '#171F2E',
              fontFamily: 'Inter',
              fontSize: ["14px", '21.268px'],
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            }}>{recommendation}</Box>}
            {note && <Box {...{
              as: "i", mt: 2, mb: 1,
              color: '#171F2Eaa',
            }}>
              {note}
            </Box>}
          </Box>
          <Grid
            gridTemplateRows={"auto auto"}
            mb="6"
            gap={taplioUI ? "" : [4, 8]}
            {...{ width: "100%", }}

          >
            <Grid {...{
              overflowX: taplioUI ? "hidden" : "scroll",
              // placeContent: "start",
              width: "100%",
            }}>{children}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

const ChartsContainer = ({ children, wide = false }) => {
  return (
    <Grid
      gridTemplateColumns={"1fr"}
      gap={8}
      pt={14}
      css={{ contentVisibility: "auto" }}
    >
      {children}
    </Grid>
  );
};

export const LiveDashboardChart = ({
  wide = false,
  taplioUI = false,
  title,
  explanation = "explanation",
  recommendation,
  width = 300,
  height = 200,
  data,
  XTickFormat,
  YTickFormat,
  note
}: {
  wide?: boolean,
  taplioUI?: boolean;
  title;
  explanation?;
  recommendation?;
  width?;
  height?;
  data: {
    x: number;
    y: number;
  }[];
  XTickFormat;
  YTickFormat;
  note?;
}) => {
  const [hoveredDatapoint, hoveredDatapointSet] = useState<any>(null);
  const dataWithOpacity = data.map((d, i) => ({
    ...d,
    opacity: i === hoveredDatapoint?.x ? 1 : 0.6,
  }));
  // console.log('width:', width)
  // console.log('hoveredDatapoint?.x:', hoveredDatapoint?.x)
  // console.log('hoveredDatapoint?.y.toFixed(2):', hoveredDatapoint?.y.toFixed(2))
  return (
    <ChartContainer {...{
      wide,
      taplioUI,
      title,
      explanation,
      width,
      hoveredDatapoint,
      recommendation,
      note
    }}>
      <XYPlot
        {...{
          color: "#46AF92",
          width,
          height,
          margin: { right: 0, left: 60, bottom: 60 }
        }}
        onMouseLeave={() => hoveredDatapointSet(null)}
      >
        <GradientDefs>
          <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#11BB8D" stopOpacity={1} />
            <stop offset="100%" stopColor="#93eed5" stopOpacity={0.6} />
          </linearGradient>
        </GradientDefs>
        <HorizontalGridLines
          style={{
            stroke: "#F0F0F0",
            strokeWidth: "1px",
          }}
        />
        <XAxis
          tickSize={0}
          tickPadding={12}
          tickLabelAngle={-30}
          {...{ tickFormat: XTickFormat ?? false }}

        />
        <YAxis tickSize={3} {...{ tickFormat: YTickFormat ?? false, left: 0 }} />
        <VerticalBarSeries
          data={dataWithOpacity}
          barWidth={0.94}
          onNearestX={(datapoint, event) => {
            hoveredDatapointSet(datapoint);
          }}
          style={{
            fill: "url(#CoolGradient)",
            stroke: "#11BB8D",
            strokeWidth: "0px",
          }}
        />
        <Hint
          value={{
            x: hoveredDatapoint?.x ?? 9999,
            y: hoveredDatapoint?.y.toFixed(2) ?? 9999,
          }}
        >
          <motion.div
            animate={{
              opacity: hoveredDatapoint ? 1 : 0,
            }}
          >
            <Grid
              gap="1"
              borderRadius={"8px"}
              p={4}
              m={0}
              zIndex={9999}
              background="white"
              border="1px solid #E4E4E4"
              shadow={"lg"}
            >
              <Box fontWeight={"600"} color="gray.600">
                {title}
              </Box>
              <Box>
                {hoveredDatapoint && (
                  <Box color="gray.500">
                    <Box as={"span"}>{`${XTickFormat(
                      hoveredDatapoint?.x
                    )}: `}</Box>
                    <Box as={"span"} fontWeight={"600"}>{` ${YTickFormat(
                      hoveredDatapoint?.y
                    )}`}</Box>
                  </Box>
                )}

                {!hoveredDatapoint && "-"}
              </Box>
            </Grid>
          </motion.div>
        </Hint>
      </XYPlot>
    </ChartContainer>
  );
};

const Bold = ({ children }) => {
  return (<Box {...{ as: "span", fontWeight: "700" }}>
    {children}
  </Box>)
}

export const LiveDashboard = ({
  thirtyDayAggregate,
  utc,
  utcSet,
  hours,
  country,
}) => {

  // console.log({ thirtyDayAggregate });


  /* BY WEEKDAY */
  const sortedWeekdaysData = weekdays.map((item, mapIndex) => {
    return { day: item, ...thirtyDayAggregate.byWeekDay[item] };
  });

  const totalVolumeOfPosts = sortedWeekdaysData.reduce((previous, current) => {
    return previous + current.numPosts;
  }, 0);

  const volumeOfPostsByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.numPosts / totalVolumeOfPosts,
    };
  });
  const engagementPerPostByWeekDay = sortedWeekdaysData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );
  const reachPerPostByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalReach / item.numPosts,
    };
  });
  const engagementRateByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalEngagement / item.totalReach,
    };
  });

  /* BY HOUR OF DAY */
  const sortedhourOfDayData = [...Array(24).keys()].map((item, mapIndex) => {
    return { hour: item, ...thirtyDayAggregate.byHourOfDay[item] };
  });
  const volumeOfPostsByHourOfDay = sortedhourOfDayData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.numPosts / totalVolumeOfPosts,
    };
  });
  // const hourVolumeArray = volumeOfPostsByHourOfDay.map((item) => item.y);
  // const hourVolumeAbove2Percent = hourVolumeArray.filter((item) => item > 0.02);
  // console.log({ hourVolumeArray, hourVolumeAbove2Percent });


  const engagementPerPostByHourOfDay = sortedhourOfDayData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );
  // const totalReachByHourOfDay = sortedhourOfDayData.map((item, mapIndex) => {
  //   return {
  //     x: mapIndex,
  //     y: item.totalReach,
  //   };
  // });
  const reachPerPostByHourOfDay = sortedhourOfDayData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalReach / item.numPosts,
    };
  });
  const engagementRateByHourOfDay = sortedhourOfDayData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.totalReach,
      };
    }
  );

  // /* BY LENGTH */
  // const sortedLengthData = lengthsOfPost.map((item, mapIndex) => {
  //   return { length: item, ...thirtyDayAggregate.byLengthOfPost[item] };
  // });
  // const volumeOfPostsByLengthOfPost = sortedLengthData.map((item, mapIndex) => {
  //   return {
  //     x: mapIndex,
  //     y: item.numPosts / totalVolumeOfPosts,
  //   };
  // });
  // const engagementPerPostByLengthOfPost = sortedLengthData.map(
  //   (item, mapIndex) => {
  //     return {
  //       x: mapIndex,
  //       y: item.totalEngagement / item.numPosts,
  //     };
  //   }
  // );
  // const reachPerPostByLengthOfPost = sortedLengthData.map((item, mapIndex) => {
  //   return {
  //     x: mapIndex,
  //     y: item.totalReach / item.numPosts,
  //   };
  // });
  // const engagementRateByLengthOfPost = sortedLengthData.map(
  //   (item, mapIndex) => {
  //     return {
  //       x: mapIndex,
  //       y: item.totalEngagement / item.totalReach,
  //     };
  //   }
  // );

  /* BY LENGTH NEW*/
  const sortedLengthDataNew = lengthsOfPostNew.map((item, mapIndex) => {
    return { length: item, ...thirtyDayAggregate.byLengthOfPostNew[item] };
  });
  const volumeOfPostsByLengthOfPostNew = sortedLengthDataNew.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.numPosts / totalVolumeOfPosts,
    };
  });
  const engagementPerPostByLengthOfPostNew = sortedLengthDataNew.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );
  const reachPerPostByLengthOfPostNew = sortedLengthDataNew.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalReach / item.numPosts,
    };
  });
  const engagementRateByLengthOfPostNew = sortedLengthDataNew.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.totalReach,
      };
    }
  );

  /* BY CONTENT TYPE */
  const sortedContentTypeData = contentType.map((item, mapIndex) => {
    return { contentType: item, ...thirtyDayAggregate.byContentType[item] };
  });

  const volumeOfPostsByContentType = sortedContentTypeData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.numPosts / totalVolumeOfPosts,
      };
    }
  );
  const reachPerPostByContentType = sortedContentTypeData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalReach / item.numPosts,
      };
    }
  );
  const engagementRateByContentType = sortedContentTypeData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.totalReach,
      };
    }
  );
  const engagementPerPostByContentType = sortedContentTypeData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );

  const YTickFormatValue = (v) => Number(v).toFixed(0);
  const YTickFormatPercent = (v) => `${(v * 100).toFixed(2)}%`;
  const XTickFormatWeekdays = (v) => weekdays[v];
  const XTickFormatHours = (v) => hours[v];
  const XTickFormatLengthsOfPost = (v) => lengthsOfPost[v];
  const XTickFormatLengthsOfPostNew = (v) => lengthsOfPostNew[v];
  const XTickFormatContentType = (v) => contentTypeLabel[v];

  // turn object thirtyDayAggregate.byHourOfDay into an array
  const thirtyDayAggregateByHourOfDayArray = Object.entries(thirtyDayAggregate.byHourOfDay).map(([key, value]: [key: string, value: any]) => {
    return { hour: key, ...value }
  })
  const timeOfDayReach = [...thirtyDayAggregateByHourOfDayArray].map((item) => ({ ...item, reachPerPost: item.totalReach / item.numPosts })).sort((a, b) => b.reachPerPost - a.reachPerPost)
  const timeOfDayEngagementRate = [...thirtyDayAggregateByHourOfDayArray].map((item) => ({ ...item, engagementRate: item.totalEngagement / item.totalReach })).sort((a, b) => b.engagementRate - a.engagementRate)
  const timeOfDayEngagement = [...thirtyDayAggregateByHourOfDayArray].map((item) => ({ ...item, engagementPerPost: item.totalEngagement / item.numPosts })).sort((a, b) => b.engagementPerPost - a.engagementPerPost)

  // pick only hours in hourVolumeAbove2Percent in timeOfDayReach
  const timeOfDayReachFiltered = timeOfDayReach.filter((item) => {
    const test = volumeOfPostsByHourOfDay[item.hour].y > 0.02
    return test
  });
  // console.log({ timeOfDayReachFiltered });

  const timeOfDayEngagementRateFiltered = timeOfDayEngagementRate.filter((item) => {
    const test = volumeOfPostsByHourOfDay[item.hour].y > 0.02
    return test
  })
  // console.log({ timeOfDayEngagementRateFiltered });

  const timeOfDayEngagementFiltered = timeOfDayEngagement.filter((item) => {
    const test = volumeOfPostsByHourOfDay[item.hour].y > 0.02
    return test
  })
  // console.log({ timeOfDayEngagementFiltered });

  const thirtyDayAggregateByWeekDayArray = Object.entries(thirtyDayAggregate.byWeekDay).map(([key, value]: [key: string, value: any]) => {
    return { day: key, ...value }
  })

  const dayOfWeekReach = [...thirtyDayAggregateByWeekDayArray].map((item) => ({ ...item, reachPerPost: item.totalReach / item.numPosts })).sort((a, b) => b.reachPerPost - a.reachPerPost)
  const dayOfWeekEngagementRate = [...thirtyDayAggregateByWeekDayArray].map((item) => ({ ...item, engagementRate: item.totalEngagement / item.totalReach })).sort((a, b) => b.engagementRate - a.engagementRate)
  const dayOfWeekEngagement = [...thirtyDayAggregateByWeekDayArray].map((item) => ({ ...item, engagementPerPost: item.totalEngagement / item.numPosts })).sort((a, b) => b.engagementPerPost - a.engagementPerPost)

  const thirtyDayAggregateByLengthOfPostArrayNew = Object.entries(thirtyDayAggregate.byLengthOfPostNew).map(([key, value]: [key: string, value: any]) => {
    return { length: key, ...value }
  })

  const lengthOfPostReach = [...thirtyDayAggregateByLengthOfPostArrayNew].map((item) => ({ ...item, reachPerPost: item.totalReach / item.numPosts })).sort((a, b) => b.reachPerPost - a.reachPerPost)
  const lengthOfPostEngagementRate = [...thirtyDayAggregateByLengthOfPostArrayNew].map((item) => ({ ...item, engagementRate: item.totalEngagement / item.totalReach })).sort((a, b) => b.engagementRate - a.engagementRate)
  const lengthOfPostEngagement = [...thirtyDayAggregateByLengthOfPostArrayNew].map((item) => ({ ...item, engagementPerPost: item.totalEngagement / item.numPosts })).sort((a, b) => b.engagementPerPost - a.engagementPerPost)

  // const thirtyDayAggregateByLengthOfPostArray = Object.entries(thirtyDayAggregate.byLengthOfPost).map(([key, value]: [key: string, value: any]) => {
  //   return { length: key, ...value }
  // })

  // const lengthOfPostReach = [...thirtyDayAggregateByLengthOfPostArray].map((item) => ({ ...item, reachPerPost: item.totalReach / item.numPosts })).sort((a, b) => b.reachPerPost - a.reachPerPost)
  // const lengthOfPostEngagementRate = [...thirtyDayAggregateByLengthOfPostArray].map((item) => ({ ...item, engagementRate: item.totalEngagement / item.totalReach })).sort((a, b) => b.engagementRate - a.engagementRate)
  // const lengthOfPostEngagement = [...thirtyDayAggregateByLengthOfPostArray].map((item) => ({ ...item, engagementPerPost: item.totalEngagement / item.numPosts })).sort((a, b) => b.engagementPerPost - a.engagementPerPost)

  const thirtyDayAggregateByContentTypeArray = Object.entries(thirtyDayAggregate.byContentType).map(([key, value]: [key: string, value: any]) => {
    return { contentType: key, ...value }
  })

  const contentTypeReach = [...thirtyDayAggregateByContentTypeArray].map((item) => ({ ...item, reachPerPost: item.totalReach / item.numPosts })).sort((a, b) => b.reachPerPost - a.reachPerPost)
  const contentTypeEngagementRate = [...thirtyDayAggregateByContentTypeArray].map((item) => ({ ...item, engagementRate: item.totalEngagement / item.totalReach })).sort((a, b) => b.engagementRate - a.engagementRate)
  const contentTypeEngagement = [...thirtyDayAggregateByContentTypeArray].map((item) => ({ ...item, engagementPerPost: item.totalEngagement / item.numPosts })).sort((a, b) => b.engagementPerPost - a.engagementPerPost)

  // console.log({
  //   thirtyDayAggregateByHourOfDayArray,
  //   thirtyDayAggregateByWeekDayArray,
  //   thirtyDayAggregateByLengthOfPostArray,
  //   thirtyDayAggregateByContentTypeArray,
  //   timeOfDayReach,
  //   timeOfDayEngagementRate,
  //   timeOfDayEngagement,
  //   dayOfWeekReach,
  //   dayOfWeekEngagementRate,
  //   dayOfWeekEngagement,
  //   lengthOfPostReach,
  //   lengthOfPostEngagementRate,
  //   lengthOfPostEngagement,
  //   contentTypeReach,
  //   contentTypeEngagementRate,
  //   contentTypeEngagement
  // });


  const TimeOfDayReachBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayReachFiltered[0].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayReachFiltered[0].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayReachPercentIncrease = (100 * (timeOfDayReachFiltered[0].reachPerPost - timeOfDayReachFiltered[1].reachPerPost) / timeOfDayReachFiltered[1].reachPerPost).toFixed(2)
  const TimeOfDayReachSecondBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayReachFiltered[1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayReachFiltered[1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayReachWorstTime = utc === "utc"
    ? moment().utc().hours(timeOfDayReachFiltered[timeOfDayReachFiltered.length - 1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayReachFiltered[timeOfDayReachFiltered.length - 1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayReachWorstAvgReach = timeOfDayReachFiltered[timeOfDayReachFiltered.length - 1].reachPerPost.toFixed(0)

  const TimeOfDayEngagementRateBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementRateFiltered[0].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementRateFiltered[0].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementRateBestValue = (timeOfDayEngagementRateFiltered[0].engagementRate * 100).toFixed(2)
  const TimeOfDayEngagementRatePercentIncrease = (100 * (timeOfDayEngagementRateFiltered[0].engagementRate - timeOfDayEngagementRateFiltered[1].engagementRate) / timeOfDayEngagementRateFiltered[1].engagementRate).toFixed(2)
  const TimeOfDayEngagementRateSecondBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementRateFiltered[1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementRateFiltered[1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementRateWorstTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementRateFiltered[timeOfDayEngagementRateFiltered.length - 1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementRateFiltered[timeOfDayEngagementRateFiltered.length - 1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementRateWorstValue = (timeOfDayEngagementRateFiltered[timeOfDayEngagementRateFiltered.length - 1].engagementRate * 100).toFixed(2)

  const TimeOfDayEngagementBestValue = timeOfDayEngagementFiltered[0].engagementPerPost.toFixed(0)
  const TimeOfDayEngagementBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementFiltered[0].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementFiltered[0].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementPercentIncrease = (100 * (timeOfDayEngagementFiltered[0].engagementPerPost - timeOfDayEngagementFiltered[1].engagementPerPost) / timeOfDayEngagementFiltered[1].engagementPerPost).toFixed(2)
  const TimeOfDayEngagementSecondBestTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementFiltered[1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementFiltered[1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementWorstTime = utc === "utc"
    ? moment().utc().hours(timeOfDayEngagementFiltered[timeOfDayEngagementFiltered.length - 1].hour).minutes(0).format("hh:mm A")
    : moment().utc().hours(timeOfDayEngagementFiltered[timeOfDayEngagementFiltered.length - 1].hour).minutes(0).local().format("hh:mm A");
  const TimeOfDayEngagementWorstCount = timeOfDayEngagementFiltered[timeOfDayEngagementFiltered.length - 1].engagementPerPost.toFixed(0)

  const DayOfWeekReachBestDay = dayOfWeekReach[0].day
  const DayOfWeekReachImpressionCount = dayOfWeekReach[0].reachPerPost.toFixed(0)
  const DayOfWeekReachPercentIncrease = (100 * (dayOfWeekReach[0].reachPerPost - dayOfWeekReach[1].reachPerPost) / dayOfWeekReach[1].reachPerPost).toFixed(2)
  const DayOfWeekReachSecondBest = dayOfWeekReach[1].day
  const DayOfWeekReachWorstDay = dayOfWeekReach[dayOfWeekReach.length - 1].day

  const DayOfWeekEngagementRateBestDay = dayOfWeekEngagementRate[0].day
  const DayOfWeekEngagementRateSecondBestDay = dayOfWeekEngagementRate[1].day
  const DayOfWeekEngagementRateBestValue = (dayOfWeekEngagementRate[0].engagementRate * 100).toFixed(2)
  const DayOfWeekEngagementRateSecondBestValue = (dayOfWeekEngagementRate[1].engagementRate * 100).toFixed(2)
  const DayOfWeekEngagementRateWorstValue = (dayOfWeekEngagementRate[dayOfWeekEngagementRate.length - 1].engagementRate * 100).toFixed(2)

  const DayOfWeekEngagementBestDay = dayOfWeekEngagement[0].day
  const DayOfWeekEngagementSecondBestDay = dayOfWeekEngagement[1].day
  const DayOfWeekEngagementThirdBestDay = dayOfWeekEngagement[2].day
  const DayOfWeekEngagementBestValue = dayOfWeekEngagement[0].engagementPerPost.toFixed(0)
  const DayOfWeekEngagementWorstDay = dayOfWeekEngagement[dayOfWeekEngagement.length - 1].day

  const LengthOfPostReachBestLength = lengthOfPostReach[0].length
  const LengthOfPostReachBestLengthImpressionCount = lengthOfPostReach[0].reachPerPost.toFixed(0)
  const LengthOfPostReachPercentIncrease = (100 * (lengthOfPostReach[0].reachPerPost - lengthOfPostReach[1].reachPerPost) / lengthOfPostReach[1].reachPerPost).toFixed(2)
  const LengthOfPostReachSecondBest = lengthOfPostReach[1].length

  const LengthOfPostEngagementRateBestLength = lengthOfPostEngagementRate[0].length
  const LengthOfPostEngagementRateBestValue = (lengthOfPostEngagementRate[0].engagementRate * 100).toFixed(2)

  const LengthOfPostEngagementBestLength = lengthOfPostEngagement[0].length
  const LengthOfPostEngagementBestValue = lengthOfPostEngagement[0].engagementPerPost.toFixed(0)
  // const LengthOfPostReachBestLength = lengthOfPostReach[0].length
  // const LengthOfPostReachBestLengthImpressionCount = lengthOfPostReach[0].reachPerPost.toFixed(0)
  // const LengthOfPostReachPercentIncrease = (100 * (lengthOfPostReach[0].reachPerPost - lengthOfPostReach[1].reachPerPost) / lengthOfPostReach[1].reachPerPost).toFixed(2)
  // const LengthOfPostReachSecondBest = lengthOfPostReach[1].length

  // const LengthOfPostEngagementRateBestLength = lengthOfPostEngagementRate[0].length
  // const LengthOfPostEngagementRateBestValue = (lengthOfPostEngagementRate[0].engagementRate * 100).toFixed(2)

  // const LengthOfPostEngagementBestLength = lengthOfPostEngagement[0].length
  // const LengthOfPostEngagementBestValue = lengthOfPostEngagement[0].engagementPerPost.toFixed(0)

  const MediaTypeReachBestMediaType = contentTypeValueToLabelObject[contentTypeReach[0].contentType].toLowerCase()
  const MediaTypeReachPercentIncrease = (100 * (contentTypeReach[0].reachPerPost - contentTypeReach[1].reachPerPost) / contentTypeReach[1].reachPerPost).toFixed(2)
  const MediaTypeReachSecondBestMediatType = contentTypeValueToLabelObject[contentTypeReach[1].contentType].toLowerCase()

  const MediaTypeEngagementRateBestMediaType = contentTypeValueToLabelObject[contentTypeEngagementRate[0].contentType].toLowerCase()
  const MediaTypeEngagementRateBestValue = (contentTypeEngagementRate[0].engagementRate * 100).toFixed(2)
  const MediaTypeEngagementRateSecondBestMediatType = contentTypeValueToLabelObject[contentTypeEngagementRate[1].contentType].toLowerCase()


  const MediaTypeEngagementBestValue = contentTypeEngagement[0].engagementPerPost.toFixed(0)
  const MediaTypeEngagementBestMediatType = contentTypeValueToLabelObject[contentTypeEngagement[0].contentType].toLowerCase()
  const MediaTypeEngagementSecondBestMediatType = contentTypeValueToLabelObject[contentTypeEngagement[1].contentType].toLowerCase()

  return (
    <WidthContainer>

      {/* By Time Of Day */}
      <SectionContainer {...{
        title: "What is the best time of day to post",
        country,
        utc,
        utcSet,
        recommendation: `The best time of day is ${TimeOfDayReachBestTime}`,
        recommendationDescription: <>
          Overall, the best time of day to publish your LinkedIn posts is <Bold> {TimeOfDayReachBestTime}</Bold>
          , but that can depend on what you’re looking to achieve. If you’re curious and want to deep dive into our data, keep reading!
        </>
      }}>
        <ChartsContainer wide>
          {/* <LiveDashboardChart
            {...{
              wide: true,
              width: 900,
              title: "Volume",
              explanation: "What day of the week do people post the most?",
              data: volumeOfPostsByHourOfDay,
              XTickFormat: XTickFormatHours,
              YTickFormat: YTickFormatPercent,
            }}
          /> */}
          <LiveDashboardChart
            {...{
              wide: true,
              title: "Reach",
              explanation:
                "‘Reach’ is the number of people who are exposed to your LinkedIn post. LinkedIn doesn’t provide that data so we take ‘impressions’ as the closest alternative. Impressions are the number of times your LinkedIn post is seen (i.e. can be seen more than once by the same person).",
              recommendation: <>The best time to maximize the reach of your LinkedIn posts is
                <Bold> {TimeOfDayReachBestTime}</Bold>.
                It does <Bold> {TimeOfDayReachPercentIncrease}%</Bold> better than the second best time,
                which is <Bold>{TimeOfDayReachSecondBestTime}</Bold>.
                The worst option is <Bold>{TimeOfDayReachWorstTime} </Bold> with an average reach of  <Bold>   {TimeOfDayReachWorstAvgReach}</Bold>.</>,
              note: "Please note we only recommend times when at least 2% of posts are being published in order to be statistically relevant.",
              width: 900,
              data: reachPerPostByHourOfDay,
              XTickFormat: XTickFormatHours,
              YTickFormat: YTickFormatValue,
            }}
          />
          <LiveDashboardChart
            {...{
              wide: true,
              title: "Engagement Rate",
              explanation:
                "‘Engagement Rate’ is the sum of likes, comments and reposts divided by the number of impressions your LinkedIn posts get.",
              recommendation: <>
                Increase your engagement rate on your LinkedIn posts by posting at <Bold>{TimeOfDayEngagementRateBestTime}</Bold>
                {" "}(<Bold>{TimeOfDayEngagementRateBestValue}%</Bold>).
                You’ll get <Bold>{TimeOfDayEngagementRatePercentIncrease}</Bold>%
                more than if you post at the second best time which
                is <Bold>{TimeOfDayEngagementRateSecondBestTime}</Bold>.
                The worst option is posting at {TimeOfDayEngagementRateWorstTime}
                {" "}where the engagement rate falls to {TimeOfDayEngagementRateWorstValue}%.
              </>,
              width: 900,
              data: engagementRateByHourOfDay,
              XTickFormat: XTickFormatHours,
              YTickFormat: YTickFormatPercent,
            }}
          />
          <LiveDashboardChart
            {...{
              wide: true,
              title: "Engagement",
              explanation:
                "‘Engagement’ is the sum of likes, comments and reposts on your LinkedIn posts.",
              recommendation: <>
                With an average of <Bold>
                  {TimeOfDayEngagementBestValue}</Bold> likes, comments and reposts, the best time to post on LinkedIn to maximize engagement is <Bold>
                  {TimeOfDayEngagementBestTime}</Bold>. That’s <Bold>
                  {TimeOfDayEngagementPercentIncrease}</Bold>% better than the second option which is <Bold>
                  {TimeOfDayEngagementSecondBestTime}</Bold>. And the worst is <>
                  {TimeOfDayEngagementWorstTime}</> with <>
                  {TimeOfDayEngagementWorstCount}</>.
              </>,
              width: 900,
              data: engagementPerPostByHourOfDay,
              XTickFormat: XTickFormatHours,
              YTickFormat: YTickFormatValue,
            }}
          />
        </ChartsContainer>
      </SectionContainer>

      {/* By Day Of Week */}
      <SectionContainer {...{
        country,
        title: "What is the best day of week to post",
        recommendation: <>The best day to post on LinkedIn is {DayOfWeekReachBestDay}</>,
        recommendationDescription: <>
          Considering reach, engagement count and engagement rate, the best day of the week to publish your posts on LinkedIn is <Bold>{DayOfWeekReachBestDay}</Bold>. Take a better look at our data (from over 130,000 recent LinkedIn posts) to understand why.
        </>
      }}>
        <ChartsContainer>
          <LiveDashboardChart
            {...{
              title: "Reach",
              explanation:
                "‘Reach’ is the number of people who are exposed to your LinkedIn post. LinkedIn doesn’t provide that data so we take ‘impressions’ as the closest alternative. Impressions are the number of times your LinkedIn post is seen (i.e. can be seen more than once by the same person).",
              recommendation: <>
                Posting on <Bold>{DayOfWeekReachBestDay}</Bold> is the best option and averages <Bold>{DayOfWeekReachImpressionCount}</Bold> impressions per post.
                <br /><br />
                That’s <Bold>{DayOfWeekReachPercentIncrease}%</Bold> more than the second best day which are <Bold>{DayOfWeekReachSecondBest}</Bold>.
                <br /><br />
                Avoid posting your best content on {DayOfWeekReachWorstDay} as it’s likely to not do as well.
              </>,
              data: reachPerPostByWeekDay,
              XTickFormat: XTickFormatWeekdays,
              YTickFormat: YTickFormatValue,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement Rate",
              explanation:
                "‘Engagement Rate’ is the sum of likes, comments and reposts divided by the number of impressions your LinkedIn posts get.",
              recommendation: <>
                <Bold>{DayOfWeekEngagementRateBestDay}</Bold> do best when it comes to maximizing engagement rate (<Bold>{DayOfWeekEngagementRateBestValue}%</Bold>).
                <br /><br />
                Second best are <Bold>{DayOfWeekEngagementRateSecondBestDay}</Bold> with <Bold>{DayOfWeekEngagementRateSecondBestValue}%</Bold>.
                <br /><br />
                And the worst day of the week for engagement rate are {DayOfWeekEngagementRateWorstValue}.
              </>,
              data: engagementRateByWeekDay,
              XTickFormat: XTickFormatWeekdays,
              YTickFormat: YTickFormatPercent,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement",
              explanation:
                "‘Engagement’ is the sum of likes, comments and reposts on your LinkedIn posts.",
              recommendation: <>
                If you’re trying to get as many likes and comments on your posts as possible, <Bold>{DayOfWeekEngagementBestDay}</Bold> is the best option for you with an average of <Bold>{DayOfWeekEngagementBestValue} likes and comments per post.</Bold>
                <br /><br />
                You can also consider <Bold>{DayOfWeekEngagementSecondBestDay}</Bold> and <Bold>{DayOfWeekEngagementThirdBestDay}</Bold>.
                <br /><br />
                However, we suggest you avoid posting on <>{DayOfWeekEngagementWorstDay}</> as they don’t do as well.
              </>,
              data: engagementPerPostByWeekDay,
              XTickFormat: XTickFormatWeekdays,
              YTickFormat: YTickFormatValue,
            }}
          />
        </ChartsContainer>
      </SectionContainer>

      {/* By Length Of Post */}
      <SectionContainer  {...{
        country,
        title: "What is the ideal length for a LinkedIn post",
        recommendation: <>The ideal length for a LinkedIn post is {LengthOfPostReachBestLength} characters.</>,
        recommendationDescription: <>
          If you consider both the reach (impressions) and engagement, the LinkedIn posts that perform the best are those that are {LengthOfPostReachBestLength} characters. For more in-depth info on post length and performance, check out the detailed data below.
        </>
      }}>
        <ChartsContainer>
          {/* <LiveDashboardChart
            {...{
              title: "Reach",
              explanation:
                "‘Reach’ is the number of people who are exposed to your LinkedIn post. LinkedIn doesn’t provide that data so we take ‘impressions’ as the closest alternative. Impressions are the number of times your LinkedIn post is seen (i.e. can be seen more than once by the same person).",
              recommendation: <>
                We recommend writing <Bold>posts with {LengthOfPostReachBestLength} characters </Bold>to maximize reach as they typically receive an average of <Bold>{LengthOfPostReachBestLengthImpressionCount}</Bold> impressions.
                <br /><br />
                That's <Bold>{LengthOfPostReachPercentIncrease}%</Bold> more than posts with <Bold>{LengthOfPostReachSecondBest}</Bold> characters.
              </>,
              data: reachPerPostByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPost,
              YTickFormat: YTickFormatValue,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement Rate",
              explanation:
                "‘Engagement Rate’ is the sum of likes, comments and reposts divided by the number of impressions your LinkedIn posts get.",
              recommendation: <>
                If you’re looking to increase the engagement rate on your post, consider writing posts with <Bold>{LengthOfPostEngagementRateBestLength} characters ({LengthOfPostEngagementRateBestValue}% engagement rate)</Bold>.
              </>
              ,
              data: engagementRateByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPost,
              YTickFormat: YTickFormatPercent,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement",
              explanation:
                "‘Engagement’ is the sum of likes, comments and reposts on your LinkedIn posts.",
              recommendation: <>
                Publishing posts with <Bold>{LengthOfPostEngagementBestLength}characters </Bold> is the best choice to maximize engagement, with an average of <Bold>{LengthOfPostEngagementBestValue} likes and comments per post</Bold>.
              </>,
              data: engagementPerPostByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPost,
              YTickFormat: YTickFormatValue,
            }}
          /> */}
          <LiveDashboardChart
            {...{
              title: "Reach",
              explanation:
                "‘Reach’ is the number of people who are exposed to your LinkedIn post. LinkedIn doesn’t provide that data so we take ‘impressions’ as the closest alternative. Impressions are the number of times your LinkedIn post is seen (i.e. can be seen more than once by the same person).",
              recommendation: <>
                We recommend writing <Bold>posts with {LengthOfPostReachBestLength} characters </Bold>to maximize reach as they typically receive an average of <Bold>{LengthOfPostReachBestLengthImpressionCount}</Bold> impressions.
                <br /><br />
                That's <Bold>{LengthOfPostReachPercentIncrease}%</Bold> more than posts with <Bold>{LengthOfPostReachSecondBest}</Bold> characters.
              </>,
              data: reachPerPostByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPostNew,
              YTickFormat: YTickFormatValue,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement Rate",
              explanation:
                "‘Engagement Rate’ is the sum of likes, comments and reposts divided by the number of impressions your LinkedIn posts get.",
              recommendation: <>
                If you’re looking to increase the engagement rate on your post, consider writing posts with <Bold>{LengthOfPostEngagementRateBestLength} characters ({LengthOfPostEngagementRateBestValue}% engagement rate)</Bold>.
              </>
              ,
              data: engagementRateByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPostNew,
              YTickFormat: YTickFormatPercent,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement",
              explanation:
                "‘Engagement’ is the sum of likes, comments and reposts on your LinkedIn posts.",
              recommendation: <>
                Publishing posts with <Bold>{LengthOfPostEngagementBestLength} characters </Bold> is the best choice to maximize engagement, with an average of <Bold>{LengthOfPostEngagementBestValue} likes and comments per post</Bold>.
              </>,
              data: engagementPerPostByLengthOfPostNew,
              XTickFormat: XTickFormatLengthsOfPostNew,
              YTickFormat: YTickFormatValue,
            }}
          />
        </ChartsContainer>
      </SectionContainer>

      {/* By Media Type */}
      <SectionContainer  {...{
        country,
        title: "What type of posts perform best on LinkedIn",
        recommendation: <>LinkedIn posts that include {MediaTypeReachBestMediaType} tend to perform best overall</>,
        recommendationDescription: <>
          Considering impressions, likes and comments, the best-performing posts on LinkedIn are ones that contain <Bold> {MediaTypeReachBestMediaType}</Bold>. Take a peak at our data from over 130,000 recent posts (refreshed every week) to find out more on posts with video, images or carousels.
        </>
      }}>
        <ChartsContainer>
          <LiveDashboardChart
            {...{
              title: "Reach",
              explanation:
                "‘Reach’ is the number of people who are exposed to your LinkedIn post. LinkedIn doesn’t provide that data so we take ‘impressions’ as the closest alternative. Impressions are the number of times your LinkedIn post is seen (i.e. can be seen more than once by the same person).",
              recommendation: <>
                Want to increase your post reach? Create posts that contain <Bold>{MediaTypeReachBestMediaType}</Bold>.
                <br /><br />
                They generate <Bold>{MediaTypeReachPercentIncrease}% more impressions </Bold>than the second-best option: posts that include <Bold>{MediaTypeReachSecondBestMediatType}</Bold>.
              </>,
              data: reachPerPostByContentType,
              XTickFormat: XTickFormatContentType,
              YTickFormat: YTickFormatValue,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement Rate",
              explanation:
                "‘Engagement Rate’ is the sum of likes, comments and reposts divided by the number of impressions your LinkedIn posts get.",
              recommendation: <>
                When it comes to engagement rate, <Bold>posts that include {MediaTypeEngagementRateBestMediaType} perform the best with a ({MediaTypeEngagementRateBestValue}% engagement rate)</Bold>.
                <br /><br /> Posts featuring <Bold>{MediaTypeEngagementRateSecondBestMediatType}</Bold> also perform well.
              </>,
              data: engagementRateByContentType,
              XTickFormat: XTickFormatContentType,
              YTickFormat: YTickFormatPercent,
            }}
          />
          <LiveDashboardChart
            {...{
              title: "Engagement",
              explanation:
                "‘Engagement’ is the sum of likes, comments and reposts on your LinkedIn posts.",
              recommendation: <>
                And finally, if you consider the number of likes and comments, the top-performing posts are the ones that include <Bold>{MediaTypeEngagementBestMediatType}</Bold> with an average of <Bold>{MediaTypeEngagementBestValue} likes and comments per post.</Bold>  <br /><br /> Posts with <Bold>{MediaTypeEngagementSecondBestMediatType}</Bold> are the second-best option, so they are worth considering as well.
              </>,
              data: engagementPerPostByContentType,
              XTickFormat: XTickFormatContentType,
              YTickFormat: YTickFormatValue,
            }}
          />
        </ChartsContainer>
      </SectionContainer>
    </WidthContainer >
  );
};
