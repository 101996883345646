import {
  Box,
  Flex
} from "@chakra-ui/react";
interface Props {
  videoDetails: {
    progressiveStreams: any,
    thumbnail: any,
    aspectRatio: number,
  };
};

export function VideoPreview({ videoDetails }: Props) {
  const videoProgressiveStream = {
    stream: videoDetails?.progressiveStreams?.[1]?.streamingLocations[0]?.url || videoDetails?.progressiveStreams?.[0]?.streamingLocations[0]?.url || "",
    thumbnail: (typeof (videoDetails?.thumbnail?.rootUrl + videoDetails?.thumbnail?.artifacts?.[0]?.fileIdentifyingUrlPathSegment) == 'string')? videoDetails?.thumbnail?.rootUrl + videoDetails?.thumbnail?.artifacts?.[0]?.fileIdentifyingUrlPathSegment : "",
    videoAspectRatio: videoDetails?.aspectRatio
  }

  return (
    <Flex flexWrap="wrap" w="100%">
      <Box
        as='video'
        controls
        src={videoProgressiveStream.stream}
        poster={videoProgressiveStream.thumbnail}
        objectFit='contain'
        w="100%"
        sx={{
          aspectRatio: `${videoProgressiveStream.videoAspectRatio}`
        }}
        //@ts-ignore
        onError={(e)=>{
          //gracefull error out if video is not playable..set an image that says on error..
          //@ts-ignore
          if(e?.target!=undefined) e?.target?.setAttribute('poster','/video-expired.png');
        }}
      />
    </Flex>
  );
}