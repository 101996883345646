import { useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";
import queryString from "query-string";
import {
    Flex,
    Tag,
    Center,
    Button,
    Text
} from "@chakra-ui/react";

import { layerStyle, textStyle } from "theme/names";
import { getAccount } from "utils/sessionHelper";

export const Summary = ({ contact }) => {
    const [isLoadingIcebreaker, setIsLoadingIcebreaker] = useState(false);
    const { data: session } = useSession() ?? {}

    const handleOnClick = async (e) => {
        setIsLoadingIcebreaker(true);
        const url = queryString.stringifyUrl({
            url: "https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-addInIcebreakerTrainingFile",
            query: { id: contact.id },
        });
        let response = await fetch(url);
        let json = await response.json();
        setIsLoadingIcebreaker(false);
        if (json.success)
            toast.success("profile added in training file");
        else toast.error("an error occured: " + json.error);
    }

    return (
        <>
            {contact?.summary && (
                <Flex
                    w="100%"
                    mt={6}
                    p={4}
                    flexDir="column"
                    alignItems="flex-start"
                    layerStyle={layerStyle["bg.border.rounded"]}
                >
                    <Text
                        textStyle={textStyle["body.medium.standard"]}
                    >
                        Summary
                    </Text>
                    <Text
                        textStyle={textStyle["body.medium.light"]}
                        textAlign="left"
                        noOfLines={4}
                        wordBreak="break-word"
                    >
                        {contact?.summary}
                    </Text>
                </Flex>
            )}
            {
                contact?.companyIndustryTags?.length > 0 && (
                    <Flex w="100%" wrap="wrap" mt={4}>
                        {
                            contact?.companyIndustryTags?.map((tag: any) => (
                                <Tag size="sm" variant="outline" fontSize="sm" key={tag} p={1} m={"2px"} colorScheme="blue">{tag}</Tag>
                            ))
                        }
                    </Flex>
                )
            }
            {contact?.icebreaker && (
                <Flex
                    w="100%"
                    mt={4}
                    p={4}
                    flexDir="column"
                    alignItems="flex-start"
                    layerStyle={layerStyle["bg.border.rounded"]}
                >
                    <Text textStyle={textStyle["body.medium.standard"]}>Icebreaker</Text>
                    <CopyToClipboard
                        text={contact?.icebreaker}
                        onCopy={() => {
                            toast.success("Icebreaker copied to clipboard");
                        }}
                    >
                        <Text
                            textStyle={textStyle["body.medium.light"]}
                            textAlign="left"
                        >
                            {contact?.icebreaker}
                        </Text>
                    </CopyToClipboard>
                    {getAccount(session)?.isAdmin && (
                        <Center mt={4} w="100%">
                            <Button
                                isLoading={isLoadingIcebreaker}
                                variant="primary"
                                onClick={handleOnClick}
                            >
                                Add in Icebreaker training file
                            </Button>
                        </Center>
                    )}
                </Flex>
            )}
        </>
    )
}