
export const convertImageToCdn = (obj, key = "") => {

  if (key && obj[key]) {
    obj[key] = obj[key]?.replace("ez4cast.s3.eu-west-1.amazonaws.com", "static.taplio.com");
  }
  else if (obj?.image) {
    obj.image = obj?.image?.replace("ez4cast.s3.eu-west-1.amazonaws.com", "static.taplio.com");
  }
  else {
    obj = obj?.replace("ez4cast.s3.eu-west-1.amazonaws.com", "static.taplio.com");
  }

  // console.log('convertImageToCdn - obj:', obj);

  return obj;
}

export const trimPosts = (posts) => {

  posts?.forEach(post => {
    delete post?.zFull?.actor;
    delete post?.zFull?.socialDetail;
    delete post?.zFull?.header;
    delete post?.zFull?.updateMetadata;
    delete post?.zFull?.commentary;
  })
}

export const formatMedia = (post) => {

  if (post.text?.includes("img:")) {

    if (!post.zFull) post.zFull = {};
    if (!post.zFull.content) post.zFull.content = {};

    let matchs = post.text.match(/(?:\[img:)(.*?)(?=\])/g);
    // console.log('nb matchs:', matchs.length);

    if (!matchs?.length) {
      console.log("Error in formatMedia", post.text);
      return;
    }

    matchs = matchs.map((m) => m.replace("[img:", ""));

    if (post.text?.includes("vid-")) {

      let obj = {
        videoPlayMetadata: {
          progressiveStreams: [
            {
              streamingLocations: [
                { url: `https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/${matchs[0]}`, }
              ]
            }
          ]
        }
      }
      post.zFull.content["com.linkedin.voyager.feed.render.LinkedInVideoComponent"] = obj;
    }
    else {

      let images = [] as any;

      matchs.forEach((m) => {

        let im = {
          attributes: [
            {
              vectorImage: {
                rootUrl: "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + m,
                artifacts: [{ fileIdentifyingUrlPathSegment: "" }],
              }
            }
          ]
        }
        images.push(im);
      });

      post.zFull.content["com.linkedin.voyager.feed.render.ImageComponent"] = { images };
    }

    matchs.forEach((m) => {
      post.text = post.text.replace(`[img:${m}]`, "");
    });

    // console.log('post media:', post.zFull.content);
  }
};

export const formatTweetFromPost = (post) => {

  let tweet = {
    ...post,
    full_text: post.text,
    favorite_count: post.numLikes,
    type: "linkedin",
  };

  tweet.user.profile_image_url_https = post?.user?.image ?? "";

  return tweet;
};
