import * as Sentry from "@sentry/nextjs";
import { getToken } from "../firebaseClient";
import toast from "react-hot-toast";
import { getAccount } from "../utils/sessionHelper";

export const likePost = async (session, dataToSend, tweet={} as any) => {

  if (tweet?.zFull?.socialDetail?.socialPermissions?.canReact === false)
    toast.error("Your are not allowed to like this post");

  try {
    let url = "https://us-central1-ez4cast.cloudfunctions.net/linkedinScheduler-likePost";
    const token = await getToken(session, "likePost");
    let res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: session?.user?.uid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({...dataToSend, isInfluentLeader: true}),
    });
    let data = await res.json();

    // if already like, make it seemless
    if (data?.error === "Conflict") {
      data.error = "";
      data.success = 1;
    }

    return data;
  } catch (e) {
    console.log("Error: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const searchUsers = async (session, username) => {
  try {
    const url = `https://us-central1-ez4cast.cloudfunctions.net/linkedinContacts-get?from=list&isInfluentLeader=true&people=${username}`;
    const token = await getToken(session, "searchUsers")
    let res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      }
    });
    let data = await res.json();
    return data;
  } catch (e) {
    console.log("Error: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const verifyUserCountry = async (countryCode: string) => {
  try {
    const res = await fetch("https://api.ipregistry.co", {
      method: "GET",
      headers: {
        "Authorization": `ApiKey ${process.env.NEXT_PUBLIC_IPREGISTRY_API_KEY}`
      }
    })
    const data = await res.json();

    return data.location.country.code === countryCode;
  } catch (e) {
    console.log("Error: ", e.message, e);
    Sentry.captureException(e);
    return false;
  }
}