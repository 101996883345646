import { border } from "theme/foundations/border";
import { radius } from "theme/foundations/radius";
import { text } from "theme/foundations/text";
import { color } from "theme/names";

export const secondary = {
  background: "white",
  ...text.body.medium.primary.light,
  border: "1px solid",
  borderColor: "border.lightMode.light",
  borderRadius: radius.lg,
  _hover: {
    borderColor: "primary.lightMode.default",
  },
  _active: {
    color: "primary.lightMode.400",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    borderColor: "border.lightMode.inactive",
    _hover: {
      borderColor: "border.lightMode.light",
    },
  },
  _dark: {
    color: "text.darkMode.standard",
    borderColor: "border.darkMode.light",
    background: "#1E1E1E",
    _hover: {
      borderColor: "border.darkMode.hover",
      background: "#1E1E1E",
    },
    _active: {
      color: "primary.darkMode.400",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      borderColor: "border.darkMode.inactive",
      _hover: {
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
export const secondaryDestructive = {
  background: "white",
  ...text.body.medium.primary.light,
  color: color["alert.lightMode.default"],
  border: "1px solid",
  borderColor: "border.lightMode.light",
  borderRadius: radius.lg,
  _hover: {
    borderColor: "primary.lightMode.default",
  },
  _active: {
    color: "primary.lightMode.400",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    borderColor: "border.lightMode.inactive",
    _hover: {
      borderColor: "border.lightMode.light",
    },
  },
  _dark: {
  color: color["alert.darkMode.default"],
    borderColor: "border.darkMode.light",
    background: "#1E1E1E",
    _hover: {
      borderColor: "border.darkMode.hover",
      background: "#1E1E1E",
    },
    _active: {
      color: "primary.darkMode.400",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      borderColor: "border.darkMode.inactive",
      _hover: {
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
export const secondaryAccent = {
  background: "white",
  ...text.body.medium.primary.light,
  color: color["alert.lightMode.default"],
  border: "1px solid",
  borderColor: "border.lightMode.light",
  borderRadius: radius.lg,
  _hover: {
    borderColor: "primary.lightMode.default",
  },
  _active: {
    color: "primary.lightMode.400",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    borderColor: "border.lightMode.inactive",
    _hover: {
      borderColor: "border.lightMode.light",
    },
  },
  _dark: {
  color: color["alert.darkMode.default"],
    borderColor: "border.darkMode.light",
    background: "#1E1E1E",
    _hover: {
      borderColor: "border.darkMode.hover",
      background: "#1E1E1E",
    },
    _active: {
      color: "primary.darkMode.400",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      borderColor: "border.darkMode.inactive",
      _hover: {
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
export const secondaryAI = {
  background: "white",
  ...text.body.medium.primary.light,
  border: "1px solid",
  borderColor: "border.lightMode.light",
  borderRadius: radius.lg,
  _hover: {
    borderColor: "#d09ae3",
  },
  _active: {
    color: "primary.lightMode.400",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    borderColor: "border.lightMode.inactive",
    _hover: {
      borderColor: "border.lightMode.light",
    },
  },
  _dark: {
    color: "text.darkMode.standard",
    borderColor: "border.darkMode.light",
    background: "#1E1E1E",
    _hover: {
      borderColor: "#403278",
      background: "#1E1E1E",
    },
    _active: {
      color: "primary.darkMode.400",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      borderColor: "border.darkMode.inactive",
      _hover: {
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
export const secondaryBookmark = {
  background: "white",
  ...text.body.medium.primary.light,
  border: "1px solid",
  borderColor: "border.lightMode.light",
  borderRadius: radius.lg,
  _hover: {
    borderColor: "#efae4d",
  },
  _active: {
    color: "primary.lightMode.400",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    borderColor: "border.lightMode.inactive",
    _hover: {
      borderColor: "border.lightMode.light",
    },
  },
  _dark: {
    color: "text.darkMode.standard",
    borderColor: "border.darkMode.light",
    background: "#1E1E1E",
    _hover: {
      borderColor: "#633b1a",
      background: "#1E1E1E",
    },
    _active: {
      color: "primary.darkMode.400",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      borderColor: "border.darkMode.inactive",
      _hover: {
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
