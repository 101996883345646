import { useEffect } from "react";
import {

  chakra,
  useColorMode
} from "@chakra-ui/react";
import domToImage from "dom-to-image";
import toast from "react-hot-toast";
import { uploadOnS3, getImageSize } from "utils/uploadUtils";
import shortid from "shortid";
import { useSession } from "next-auth/react";
import { postMedia } from "controllers/media";

const BUCKET_NAME = process.env.NEXT_PUBLIC_PROJECT_BUCKET;

const IFrame = chakra("iframe");

interface Props {
  imageId: string;
  callback?: (results: any) => void;
}

export function ImageEditor({ imageId, callback = () => { } }: Props) {
  const { data: session } = useSession() ?? {};
  const { colorMode } = useColorMode();
  const saveImage = async (blob) => {
    const newId = shortid.generate().replace("-", "_");
    const file = new File([blob], newId, { type: "image/png" });
    if (file.size > 5000000) {
      toast.error("Images cannot be bigger than 5mb");
      return;
    }

    const imageSize: any = await getImageSize(file);
    if (
      imageSize.height < 4 ||
      imageSize.height > 8192 ||
      imageSize.width < 4 ||
      imageSize.width > 8192
    ) {
      toast.error("Image dimensions must be >= 4x4 and <= 8192x8192");
      return;
    }
    try {
      const params = {
        Bucket: BUCKET_NAME,
        Key: "userUpload/" + newId,
        Body: file,
        ACL: "public-read",
        // CacheControl: 'public, max-age=31536000',
      };

      let url = await uploadOnS3(params);

      if (url) {
        const mediaObj = {
          url: url,
          type: file.type,
          fileName: newId,
          size: file.size,
          id: newId,
        }
        const { media: newMedia } = await postMedia(session, mediaObj);
        toast.success("Successfully updated the image");
        callback(newMedia);
      }
    } catch (e) {
      toast.error("Error in uploading image: " + e.message);
    }
  };

  useEffect(() => {
    const messageListener = (event) => {
      console.log(
        "Message received from the Pika editor: ",
        event?.data?.message, // Event name
        event?.data?.blob // Output image blob
      );
      const eventName = event?.data?.message;
      const blob = event?.data?.blob;
      if (eventName === "getImage") {
        saveImage(blob);
      }
    };

    window.addEventListener("message", messageListener);

    // Cleanup function to be run on unmount
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  if (!imageId) {
    return null;
  }

  return (
    <IFrame
      src={`https://embed.pika.style/templates/beautify-screenshots?use=https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/${imageId}&theme=${colorMode}`}
      width="100%"
      height="885px"
    />

  );
}