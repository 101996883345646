import * as Sentry from "@sentry/nextjs";
import toast from "react-hot-toast";
import { getToken } from "../firebaseClient";

export const postMedia = async (session, params) => {
  try {
    let url = `https://us-central1-ez4cast.cloudfunctions.net/media-postMedia`;
    let user = session?.user;
    const token = await getToken(session, "postMedia");

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...params, isInfluentLeader: true })
    });

    let data = await response.json();
    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log("Error in fetching most recent tweets: ", e.message);
    Sentry.captureException(e);
    return null;
  }
}
export const getUserMedias = async (session, params = {}) => {
  try {
    let url = `https://us-central1-ez4cast.cloudfunctions.net/media-getUserMedias`;
    let user = session?.user;
    const token = await getToken(session, "getUserMedias");

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params)
    });
    let data = await response.json();
    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log("Error in fetching user medias: ", e.message);
    Sentry.captureException(e);
    return null;
  }
}

export const deleteMedia = async (session, params) => {
  try {
    let url = `https://us-central1-ez4cast.cloudfunctions.net/media-deleteMedia`;
    let user = session?.user;
    const token = await getToken(session, "deleteMedia");
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await response.json();
    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log("Error in deleting media: ", e.message);
    Sentry.captureException(e);
    return null;
  }
}

export const createThumbnail = async (session, params) => {
  try {
    let url = `https://us-central1-ez4cast.cloudfunctions.net/media-createThumbnail`;
    let user = session?.user;
    const token = await getToken(session, "createThumbnail");
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params)
    });

    let data = await response.json();
    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data.thumbnailUrl;
  } catch (e) {
    console.log("Error in deleting media: ", e.message);
    Sentry.captureException(e);
    return null;
  }
}