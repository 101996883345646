import { useState, useEffect } from "react";

import { v4 as uuid } from "uuid";
import { AddIcon } from "@chakra-ui/icons";
import { format } from 'date-fns';
import { AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import toast from "react-hot-toast";
import { useSession } from "next-auth/react";
import { firebaseClient } from "firebaseClient";
import {
  Box,
  Text,
  VStack,
  Button,
  Flex,
  IconButton,
  Textarea
} from "@chakra-ui/react";

import { textStyle, variant } from "theme/names";
import { getAccount } from "utils/sessionHelper";
import { useMiscellaneousContext } from "context/miscellaneousContext";

interface Props {
  contact: any;
}

export function Notes({ contact }: Props) {
  const [showNoteComposer, setShowNoteComposer] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [editNoteId, setEditNoteId] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<string>("");
  const [notes, setNotes] = useState<any[]>([]);
  const [refresh, refresher] = useState({});
  const miscellaneousContext: any = useMiscellaneousContext();
  const { data: session } = useSession() ?? {}
  useEffect(() => {
    if (miscellaneousContext.sidebarPerson) {
      setShowNoteComposer(false);
      setNote("");
      fetchNotes();
    }
  }, [miscellaneousContext.sidebarPerson.id, refresh]);

  const fetchNotes = async () => {
    setNotes(miscellaneousContext?.sidebarPerson?.notes || []);
    if (miscellaneousContext?.sidebarPerson?.notes === undefined) {
      const db = firebaseClient.firestore();
      const contactDoc = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(miscellaneousContext?.sidebarPerson?.id)
        .get();

      const contact = contactDoc.data();
      if (contact && contact.notes) {
        setNotes(contact.notes);
      }
    }
  };

  const handleClick = async () => {
    try {
      if (!note) {
        toast.error("Please enter some text");
        return;
      }
      setIsSaving(true);
      let updatedNoteList: any[] = [];
      const notesCopy = [...notes] || [];

      if (editNoteId) {
        const newList = notesCopy.map((n) => {
          if (n.id === editNoteId) {
            return { ...n, text: note };
          } else {
            return n;
          }
        });
        updatedNoteList = newList;
      } else {
        let data: any = {
          id: uuid(),
          text: note,
          createdAt: new Date(),
        };
        updatedNoteList = [...notesCopy, data];
      }

      const db = firebaseClient.firestore();

      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(contact?.id)
        .update({ notes: updatedNoteList });

      toast.success("Successfully added");
      miscellaneousContext.setSidebarPerson({ ...contact, notes: updatedNoteList });
      setNote("");
      setShowNoteComposer(false);
      setEditNoteId("");
      refresher({});
    } catch (err) {
      console.log("Error in saving note: ", err);
      toast.error("Error in saving note: ", err.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setDeleteNoteId(id);
      const db = firebaseClient.firestore();
      const filteredNotes = [...notes].filter((n) => n.id !== id);
      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(contact?.id)
        .update({ notes: filteredNotes });

      miscellaneousContext.setSidebarPerson({ ...contact, notes: filteredNotes });
      refresher({});
    } catch (err) {
      console.log("Error in deleting note: ", err);
      toast.error("Error in deleting note: ", err.message);
    } finally {
      setDeleteNoteId("");
    }
  };

  const compare = (ad, bd) => {
    const a = ad?.createdAt?.toDate ? ad?.createdAt?.toDate() : ad?.createdAt;
    const b = bd?.createdAt?.toDate ? bd?.createdAt?.toDate() : bd?.createdAt;
    return new Date(b).getTime() - new Date(a).getTime();
  };

  return (
    <Box
      textAlign="left"
      mt="6"
    >
      <Text
        textStyle={textStyle["body.medium.standard"]}
      >
        Personal notes:{" "}
      </Text>

      {notes?.length > 0 && (
        <VStack mt="4" spacing="4" alignItems="start">
          {notes?.sort(compare).map((note) => (
            <Button
              key={note.id}
              py={3}
              height="100%"
              overflow="hidden"
              width="100%"
              as="div"
              alignItems="left"
              justifyContent="flex-start"
              flexDirection="column"
              whiteSpace="normal"
            >
              <Flex
                fontSize="xs"
                alignItems="center"
                justifyContent="space-between"
                h="3"
                w="100%"
              >
                <Text textStyle={textStyle["body.medium.light"]} >
                  {format(
                    note?.createdAt?.toDate ? note?.createdAt?.toDate() : note?.createdAt,
                    "MMMM do yyyy"
                  )}
                </Text>
                <Box>
                  <IconButton
                    variant="action"
                    aria-label="edit note"
                    size="xs"
                    background="none"
                    icon={<BsPencil />}
                    onClick={() => {
                      setEditNoteId(note.id);
                      setNote(note.text);
                      setShowNoteComposer(true);
                    }}
                  />
                  <IconButton
                    variant="actionDestructive"
                    aria-label="delete note"
                    size="xs"
                    ml="2"
                    background="none"
                    isLoading={note.id === deleteNoteId}
                    icon={<AiOutlineDelete />}
                    onClick={() => handleDelete(note.id)}
                  />
                </Box>
              </Flex>
              <Text textStyle={textStyle["body.medium.standard"]} as="i" fontSize="sm" mt="3">
                {note.text}
              </Text>
            </Button>
          ))}
          <Button
            variant="primary"
            leftIcon={<AddIcon h="3" w="3" />}
            onClick={() => setShowNoteComposer(true)}
          >
            Add note
          </Button>
        </VStack>
      )}

      {!notes?.length && !showNoteComposer && (
        <Box textAlign="center" mt="6">
          <Text textStyle={textStyle["body.medium.light"]}>You have not added any notes</Text>
          <Button
            variant={variant.Button.secondary}
            mt="4"
            onClick={() => setShowNoteComposer(true)}
          >
            Create my first note
          </Button>
        </Box>
      )}
      {showNoteComposer && (
        <Box textAlign="end" mt="4">
          <Textarea
            placeholder="Write your note..."
            h="160px"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <Button
            variant="secondaryDestructive"
            mt="3"
            px="4"
            onClick={() => {
              setShowNoteComposer(false);
              setNote("");
            }}
            isDisabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            mt="3"
            px="4"
            ml="3"
            onClick={handleClick}
            isLoading={isSaving}
          >
            Create
          </Button>
        </Box>
      )}
    </Box>
  );
}
