import { Text, Box, useColorModeValue, Flex, Image } from "@chakra-ui/react";
import { AiOutlineRetweet } from "react-icons/ai";
import { textStyle } from "theme/names";
import { MetaCard } from "./metaCard";

interface Props {
  type: "tweet" | "metacard" | "";
  data: any;
  isRetweet?: boolean;
}

export function TwitterCard({ type, data, isRetweet }: Props) {
  if (type === "tweet" && Object.keys(data).length > 0) {
    return (
      <Box>
        <Box
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          rounded="lg"
          mt="-2"
          p="2"
        >
          <Flex alignItems="center">
            <Image
              src={data.user.profile_image_url_https}
              fallbackSrc="/emptyProfile.png"
              alt="profile image"
              borderRadius="50%"
              h="7"
              w="7"
            />
            <Text textStyle={textStyle["body.medium.light"]} fontWeight="700" fontSize="sm" ml="1" noOfLines={1} wordBreak={"break-all"}>
              {data.user.name}
            </Text>
            <Text textStyle={textStyle["body.medium.light"]}
              fontWeight="500"
              fontSize="sm"
              color={useColorModeValue("gray.500", "gray.400")}
              ml="1"
              noOfLines={1} wordBreak={"break-all"}
            >
              @{data.user.screen_name}
            </Text>
          </Flex>
          <Text textStyle={textStyle["body.medium.light"]} fontSize="sm">{data.full_text}</Text>
        </Box>
        {isRetweet && (
          <Flex alignItems="center" justifyContent="center" fontSize="sm">
            <AiOutlineRetweet />
            <Text textStyle={textStyle["body.medium.light"]} ml="1">This Linkedin post will be a retweeted</Text>
          </Flex>
        )}
      </Box>
    );
  } else if (type === "tweet") {
    return (
      <Box
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        rounded="lg"
        mt="1"
        p="2"
      >
        <Text textStyle={textStyle["body.medium.light"]} pl="3">This post is unavailable.</Text>
      </Box>
    );
  }
  return <MetaCard metadata={data} />;
}
