import { Wrap } from "@chakra-ui/react";
import { ImagePreview } from './imagePreview';
import { VideoPreview } from './videoPreview';
import { EntityPreview } from "./entityPreview";
import { PollPreview } from './pollPreview';
import { CelebrationPreview } from "./celebrationPreview";
import { DocumentPreview } from "./documentPreview";
import { ArticlePreview } from "./articlePreview";
import { EventPreview } from "./eventPreview";
import { radius } from "theme/foundations/radius";
import { layerStyle } from "theme/names";

const typeOfMedia = (medias) => {
  const key = returnCorrectContentTypeKey(Object.keys(medias));

  if(typeof medias?.[key] == "object") {
    medias[key].hrefLink = medias?.hrefLink;

    medias[key].carousel_document_link = medias?.carousel_document_link;

    let listOfMedia = {
      'com.linkedin.voyager.feed.render.ImageComponent': <ImagePreview medias={medias?.[key]?.images}/>,
      'com.linkedin.voyager.feed.render.LinkedInVideoComponent': <VideoPreview videoDetails={medias?.[key]?.videoPlayMetadata}/>,
      'com.linkedin.voyager.feed.render.EntityComponent': <EntityPreview entityDetails={medias?.[key]} />,
      'com.linkedin.voyager.feed.render.PollComponent': <PollPreview pollDetails={medias?.[key]} />,
      'com.linkedin.voyager.feed.render.CelebrationComponent': <CelebrationPreview celebrationDetails={medias?.[key]} />,
      'com.linkedin.voyager.feed.render.DocumentComponent': <DocumentPreview docDetails={medias?.[key]} />,
      'com.linkedin.voyager.feed.render.ArticleComponent': <ArticlePreview articleDetails={medias?.[key]} />,
      'com.linkedin.voyager.feed.render.EventComponent': <EventPreview eventDetails={medias?.[key]} />,
      undefined: null
    };

    return listOfMedia[key];
  } 
    return null;
}
interface Props {
  medias: string[],
};

export function RichContentBox({ medias }: Props) {
  return(
    <Wrap 
      // layerStyle={layerStyle.BgGray}
      // bg={"red.500"}
      mt="6"
      mb="0"
      w="100%"
      // borderRadius={radius.sm}
      >
        {typeOfMedia(medias)}
      </Wrap>
  );
}

const returnCorrectContentTypeKey = (keys) => {
  let retKey;
  keys.forEach((k) => {
    if(k !== 'hrefLink' && k !== 'carousel_document_link') retKey = k;
  });
  return retKey;
}