import {
  Image,
  WrapItem
} from "@chakra-ui/react";
interface Props {
  medias: any;
};

export function ImagePreview({ medias }: Props) {
  
  // console.log('ImagePreview component:', medias);

  let imagesView: any = medias?.map((m, mediasIndex) => {
    const altText = m?.accessibilityText;
    const imageSrc = m?.attributes?.[0]?.vectorImage?.rootUrl + m?.attributes?.[0]?.vectorImage.artifacts?.[0]?.fileIdentifyingUrlPathSegment;
    const wrapItemWidth = (medias?.length%2===0)? "47%" : (medias?.length===(mediasIndex+1))? "100%" : "47%";
    
    return (
      <WrapItem
        // d="inline-block"
        maxW={wrapItemWidth}
        mx="auto"
        w="100%"
        key={mediasIndex}
      >
        <Image
          src={imageSrc}
          alt={altText}
          borderRadius={(medias?.length==1)? "sm" : "md"}
          fit="cover"
          h="100%"
          loading="lazy"
          onError={(e)=>{
            //remove parent wrapitem if the image doesn't load for whatever context...neends refactor to adjust width...
            //@ts-ignore 
            //console.log(e?.target?.parentNode.style.maxWidth, 'previous s')
            if(e?.target?.parentNode!=undefined) e?.target?.parentNode?.remove();
          }}
        />
      </WrapItem>
    )
  });

  return(
    imagesView
  );
}
