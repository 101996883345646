export const devKey =
  "sk_test_51JaEQHKB4y5X3SShjzu78ClCTtEDFj34izzr42OhyLaHop967ZGMnoUli7zG4QDz34TTU7taSB7z5fCMOcanVGpZ00Vw4it8I3";
export const liveKey =
  "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";

const stripePlans = {
  prod: {
    // growMonthly: "price_1Jdxk1KB4y5X3SShvC5pCuEq", // 49
    // growMonthly: "price_1KRitQKB4y5X3SShv3V86lMh", // 29
    // growYearly: "price_1KRitQKB4y5X3SShv3V86lMh", // 29
    // startMonthly: "price_1MZWG7KB4y5X3SShRvzrSXN6", // 52 monthly - prod_NKAbUYeT4CBXu7
    // startYearly: "price_1MZWH0KB4y5X3SShp74w0ZKs", // 468 - 39 monthly - prod_NKAcCCLXdjn8ih
    // startMonthly: "price_1OeJivKB4y5X3SSh8SQ6iRwA", // 65 monthly - prod_NKAbUYeT4CBXu7
    // startYearly: "price_1OeJkEKB4y5X3SShoF7CR2pF", // 588 - 49 monthly - prod_NKAcCCLXdjn8ih
    startMonthly: "price_1PY3sMKB4y5X3SShqQi73n9y", // 39 monthly - prod_NKAbUYeT4CBXu7 - Starter 06/2024
    startYearly: "price_1PY45mKB4y5X3SSh1Ft9CBcI", // 384 - 32 monthly - prod_NKAcCCLXdjn8ih  - Starter Yearly 06/2024

    // growMonthly: "price_1KwikNKB4y5X3SShsX6aF7jB", // 39 monthly
    // growYearly: "price_1KwikNKB4y5X3SShsX6aF7jB", // 39 monthly
    // growMonthly: "price_1MTk3IKB4y5X3SShQR3FTtMH", // 52 monthly - prod_KIYrvwkEn7QXbf
    // growYearly: "price_1MTk4zKB4y5X3SShiamZDEQ7", // 468 - 39 monthly - prod_MwTvOxBEaw1CPU
    growMonthly: "price_1OeJjdKB4y5X3SShvHwgxQA9", // 65 monthly - prod_KIYrvwkEn7QXbf
    growYearly: "price_1OeJkkKB4y5X3SShWP9sB07p", // 588 - 49 monthly - prod_MwTvOxBEaw1CPU
    // enterpriseMonthly: "price_1MTk6dKB4y5X3SShLjkchBZM", // 94 monthly - prod_NECVUOpjEd3ngp
    // enterpriseYearly: "price_1MTk8DKB4y5X3SShJtU63sxu", // 852 - 71 monthly - prod_NECXvheOKxm1Yc
    enterpriseMonthly: "price_1NIS5YKB4y5X3SShF6Wh7zYg", // 199 monthly - prod_NECVUOpjEd3ngp
    enterpriseYearly: "price_1NIS6rKB4y5X3SShxHkRoqkg", // 1788 - 149 monthly - prod_NECXvheOKxm1Yc

    // growYearly: "", //
  },
};

export function getStripePlans() {
  return stripePlans.prod;
}
