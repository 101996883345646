import { radius } from "theme/foundations/radius";
import { secondary } from "./secondary";


export const suggestion = {
  ...secondary,
  borderRadius: radius.sm,
  border: "none",
  bg: "primary.lightMode.default",
  fontWeight: "600",
  p: 4,
  color: "primary.lightMode.100",
  _dark: {
    // color: "text.darkMode.standard",
    color: "primary.darkMode.100",
  },
  _hover: {
    color: "primary.lightMode.default",
    border: "1px solid",
    borderColor: "primary.lightMode.100",
    bg: "white",
    _dark: {
      color: "primary.darkMode.default",
      border: "1px solid",
      borderColor: "primary.darkMode.100",
      bg: "#1E1E1E",
    },
  },
  _active: {
    color: "primary.lightMode.default",
    bg: "white",
    _dark: {
      color: "primary.darkMode.default",
      bg: "primary.darkMode.default",
    },
  },
};
