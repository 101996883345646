import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { text } from "theme/foundations/text";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {},
  list: {
    border: "1px solid",
    borderColor: "border.lightMode.light",
    borderRadius: "10px",
    zIndex: 999,
    _dark: {
      borderColor: "border.darkMode.light",
    },
  },
  item: {},
  groupTitle: {},
  command: {},
  divider: {
    // mx: 1,
  },
});

const variants = {
  switcher: definePartsStyle({
    list: {
      p: 1,
      bg: "background.lightMode.light",
      _dark: {
        borderColor: "border.darkMode.light",
        bg: "background.darkMode.light",
      },
    },
    item: {
      borderRadius: "7px",
      p: 4,
      bg: "background.lightMode.light",
      _dark: {
        bg: "background.darkMode.light",
        color: "white"
      },
      _hover: {
        bg: "primary.lightMode.default",
        color: "white",
        _dark: {
          bg: "primary.darkMode.default",
        },
      },
      _active: {
        bg: "primary.lightMode.100",
        color: "white",
        _dark: {
          bg: "primary.darkMode.default",
        },
      },
      _focus: {
        bg: "primary.lightMode.100",
        color: "white",
        _dark: {
          bg: "primary.darkMode.default",
        },
      },
    },
    groupTitle: {},
    command: {},
    divider: {
      m: 1,
    },
  }),
  secondary: definePartsStyle({
    list: {
      // p: 1,
      py: 0,
      borderRadius: "10px",
      overflow: "hidden",
      bg: "white",
      border: "1px solid",
      fontSize: "sm",
      // borderColor: "primary.lightMode.default",
      borderColor: "border.lightMode.light",
      zIndex: 100,
      _hover: {
        // borderColor: "border.lightMode.light",
        borderColor: "primary.lightMode.default",
      },
      _dark: {
        // borderColor: "border.darkMode.light",
        borderColor: "primary.lightMode.default",
        bg: "#1E1E1E",
      },
    },
    item: {
      // borderRadius: "10px",
      px: 3,
      py: 3,
      color: "text.lightMode.light",
      bg: "white",
      _hover: {
        bg: "primary.lightMode.100",
        color: "text.lightMode.standard",
      },
      _active: {
        bg: "primary.lightMode.100",
        color: "text.lightMode.standard",
        _dark: {
          bg: "primary.lightMode.default",
        },
      },
      _focus: {
        bg: "primary.lightMode.100",
        color: "text.lightMode.standard",
      },
      _dark: {
        bg: "#1E1E1E",
        color: "text.darkMode.light",
        _hover: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
        },
        _active: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
          _dark: {
            bg: "primary.darkMode.default",
          },
        },
        _focus: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
        },
      },
    },
    groupTitle: {},
    command: {},
    divider: {
      m: 1,
    },
  }),
  accountSwitcher: definePartsStyle({
    list: {
      p: 1,
      bg: "white",
      border: "1px solid",
      borderColor: "border.lightMode.light",
      zIndex: 100,
      _hover: {
        borderColor: "border.lightMode.hover",
      },
      _dark: {
        borderColor: "border.darkMode.hover",
        bg: "#1E1E1E",
      },
    },
    item: {
      borderRadius: "7px",
      p: 2,
      color: "text.lightMode.light",
      bg: "white",
      _hover: {
        bg: "primary.lightMode.100",
        color: "text.lightMode.standard",
      },
      _active: {
        // bg: "primary.lightMode.100",
        // color: "text.lightMode.standard",
        _dark: {
          // bg: "primary.lightMode.default",
        },
      },
      _focus: {
        // bg: "primary.lightMode.100",
        // color: "text.lightMode.standard",
      },
      _dark: {
        bg: "#1E1E1E",
        color: "text.neutral",
        _hover: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
        },
        _active: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
          _dark: {
            // bg: "primary.lightMode.default",
          },
        },
        _focus: {
          bg: "primary.darkMode.100",
          color: "text.darkMode.standard",
        },
      },
    },
    groupTitle: {},
    command: {},
    divider: {
      m: 1,
    },
  }),
};

// export the component theme
export const Menu = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "secondary"
  },
});
