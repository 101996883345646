import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { color } from "theme/names";
import { text } from "../foundations/text";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  const { status } = props; // "info" | "warning" | "success" | "error" | "loading"
  return {
    container: {
      borderRadius: "10px",
      py: "6px",
      px: "16px",
    },
    title: {},
    description: {},
    icon: {},
    spinner: {},
  };
});

const withIcons = definePartsStyle((props) => {
  const { status } = props; // "info" | "warning" | "success" | "error" | "loading"

  return {
    container: {
      ...text.body.medium.standard.light,
      color: (() => {
        if (status === "error") return color["alert.darkMode.1400"];
        if (status === "warning") return color["orange.700"];
        if (status === "success") return "success.darkMode.400";
        if (status === "info") return "primary.darkMode.400";
        // if (status === "loading") return "gray.100";
        return "gray.100";
      })(),
      background: (() => {
        if (status === "error") return color["alert.lightMode.100"];
        if (status === "warning") return color["orange.100"];
        if (status === "success") return color["success.lightMode.100"];
        if (status === "info") return "primary.lightMode.100";
        // if (status === "loading") return "gray.100";
        return "gray.100";
      })(),
      _dark: {
        ...text.body.medium.standard.dark,
        color: (() => {
          if (status === "error") return color["alert.darkMode.100"];
          if (status === "warning") return color["orange.100"];
          if (status === "success") return "success.darkMode.100";
          if (status === "info") return "primary.darkMode.100";
          // if (status === "loading") return "gray.100";
          return "gray.100";
        })(),
        background: (() => {
          if (status === "error") return color["alert.darkMode.400"];
          if (status === "warning") return color["orange.700"];
          if (status === "success") return "success.darkMode.400";
          if (status === "info") return "primary.darkMode.400";
          // if (status === "loading") return "gray.100";
          return "gray.100";
        })(),
      },
    },
    title: {},
    description: {
      // ...text.body.medium.standard,
      // color: "red.100",
      ...text.body.medium.standard.light,
    },
    icon: {
      w: "16px",
      mr: 2,
      color: (() => {
        // hide default icon by setting it with background color
        if (status === "success") return "success.lightMode.100";
        if (status === "info") return "primary.lightMode.100";
        return "gray.100";
      })(),
      _dark: {
        color: (() => {
          // hide default icon by setting it with background color
          if (status === "success") return "success.darkMode.400";
          if (status === "info") return "primary.darkMode.400";
          return "gray.100";
        })(),
      },
      position: "relative",
      _before: {
        content: "''",
        position: "absolute",
        top: "4px",
        left: "0px",
        width: "100%",
        height: "100%",
        backgroundImage: (() => {
          if (status === "success") return "url('./theme/successAlert.svg')";
          if (status === "info") return "url('./theme/infoAlert.svg')";
          return "url('./theme/info.svg')";
        })(),
        backgroundSize: "16px 16px",
        backgroundRepeat: "no-repeat",
      },
    },
    spinner: {},
  };
});

const variants = {
  withIcons,
};

export const Alert = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "withIcons",
  },
});
