import { text } from "theme/foundations/text";

export const primary = {
  ...text.body.medium.neutral.light,
  // prettier-ignore
  background: "radial-gradient(80% 80% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #0A66C2",
  backgroundBlendMode: "overlay, normal",
  border: "1px solid #05488B",
  boxShadow: "0px 3px 4px rgba(49, 107, 255, 0.28)",
  borderRadius: "10px",
  // minW: "fit-content",
  // inner rectangle with blue border:
  _before: {
    content: '""', // required for visibility
    border: "1px solid #54A9FF",
    position: "absolute",
    top: "0",
    left: "0",
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    borderRadius: "9px",
  },
  _hover: {
    // prettier-ignore
    background: "radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #0A66C2",
    textDecor: "none",
    _disabled: {
      background: "#F3F6F9",
    },
  },
  _active: {
    background: "#05488B",
  },
  _disabled: {
    _before: {
      // cover the blue rectangle on disabled
      content: '""', // required for visibility
      border: "1px solid #F3F6F9",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "9px",
    },
    background: "#F3F6F9",
    border: "1px solid #F3F6F9",
    color: "text.lightMode.inactive",
    _hover: {
      background: "#F3F6F9",
      border: "1px solid #F3F6F9",
      color: "text.lightMode.inactive",
      backgroundBlendMode: "normal",
    },
  },
};

export const primaryDestructive = {
  ...text.body.medium.neutral.light,
  // prettier-ignore
  background: "radial-gradient(80% 80% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #EC4343",
  backgroundBlendMode: "overlay, normal",
  border: "1px solid #EC4343",
  boxShadow: "0px 3px 4px #EC434344",
  borderRadius: "10px",
  // minW: "fit-content",
  // inner rectangle with blue border:
  _before: {
    content: '""', // required for visibility
    border: "1px solid #F8B1B1",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "9px",
  },
  _hover: {
    // prettier-ignore
    background: "radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #EC4343",
    textDecor: "none",
    _disabled: {
      background: "#F3F6F9",
    },
  },
  _active: {
    background: "#8C0000",
  },
  _disabled: {
    _before: {
      // cover the blue rectangle on disabled
      content: '""', // required for visibility
      border: "1px solid #F3F6F9",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "9px",
    },
    background: "#F3F6F9",
    border: "1px solid #F3F6F9",
    color: "text.lightMode.inactive",
    _hover: {
      background: "#F3F6F9",
      border: "1px solid #F3F6F9",
      color: "text.lightMode.inactive",
      backgroundBlendMode: "normal",
    },
  },
};

export const primaryWarning = {
  ...text.body.medium.neutral.light,
  // prettier-ignore
  background: "radial-gradient(80% 80% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #DFB009",
  backgroundBlendMode: "overlay, normal",
  border: "1px solid #DFB009",
  boxShadow: "0px 3px 4px #DFB00944",
  borderRadius: "10px",
  // minW: "fit-content",
  // inner rectangle with blue border:
  _before: {
    content: '""', // required for visibility
    border: "1px solid #FFF8D3",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "9px",
  },
  _hover: {
    textDecor: "none",
    // prettier-ignore
    background: "radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #DFB009",
    _disabled: {
      background: "#F3F6F9",
    },
  },
  _active: {
    background: "#916D00",
  },
  _disabled: {
    _before: {
      // cover the blue rectangle on disabled
      content: '""', // required for visibility
      border: "1px solid #F3F6F9",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "9px",
    },
    background: "#F3F6F9",
    border: "1px solid #F3F6F9",
    color: "text.lightMode.inactive",
    _hover: {
      background: "#F3F6F9",
      border: "1px solid #F3F6F9",
      color: "text.lightMode.inactive",
      backgroundBlendMode: "normal",
    },
  },
};
