import { useEffect, useState } from "react";

import { Text } from '@chakra-ui/react'
import PuffLoader from "react-spinners/PuffLoader";

import Tweets from "components/tweets";
import { getAccount } from "utils/sessionHelper";
import { textStyle } from "theme/names";
import { Post } from "types/post";
import { getPersonLastPost } from "services/engage/engage";

export const Engage = ({ miscellaneousContext, contact, session }) => {
    const [contactLastPost, setContactLastPost] = useState<Post[]>([]);
    const account = getAccount(session)
    const [refresh, refresher] = useState({});
    const engageAccountUsername = contact?.username ?? miscellaneousContext.sidebarPerson?.username
    const [noResult, setNoResult] = useState<boolean>(false);

    useEffect(() => {
        const fetchLastPosts = async () => {
            const lastPosts = await getPersonLastPost(engageAccountUsername, account.id, session);

            if (lastPosts?.message === "No data found") {
                setNoResult(true);
            } else {
                setContactLastPost(lastPosts);
            }
        };

        if (miscellaneousContext.sidebarPerson?.id) {
            fetchLastPosts();
        }
    }, [miscellaneousContext.sidebarPerson?.id]);

    return (
        <>
            <Text
                textStyle={textStyle["h4.bold.standard"]}
                pb="2"
            >
                {contact.firstName || contact.name }'s latest post
            </Text>
            {
                noResult && (
                    <Text textStyle={textStyle["body.regular.standard"]}>
                        No result found
                    </Text>
                )
            }
            {
                !noResult && contactLastPost.length > 0 && (
                    <Tweets
                        tweets={[contactLastPost[0]]}
                        setData={setContactLastPost}
                        refresher={refresher}
                        disableHideButton={true}
                        disableSaveCsv={true}
                        disableEmbed={true}
                    />
                )
            }
            {
                !noResult && contactLastPost.length === 0 && (
                    <PuffLoader
                        color={"#0A66C2"}
                        cssOverride={{
                            display: "block",
                            margin: "0 auto",
                            borderColor: "red",
                        }}
                        size={50}
                    />
                )
            }
        </>
    )
}