import toast from "react-hot-toast";

const AWS = require('aws-sdk');

const accessKeyId = process.env.NEXT_PUBLIC_AWS_ACCESS_KEY
const secretAccessKey = process.env.NEXT_PUBLIC_AWS_SECRET_KEY

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  region: 'eu-west-1'
});

export const getImageSize = async (file) => {
  return new Promise(function (resolve, reject) {
    var image = document.createElement("img");
    image.src = URL.createObjectURL(file);
    image.onerror = function (err) {
      clearInterval(intervalId);
      reject(err);
    };
    var intervalId = setInterval(function () {
      if (image.naturalWidth && image.naturalHeight) {
        clearInterval(intervalId);
        URL.revokeObjectURL(image.src);
        resolve({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      }
    }, 1);
  });
};

export async function uploadOnS3 (params) {

    let prom = new Promise((resolve, reject) => {
        s3.upload(params, function (err, data) {
            if (err) {
                let message = err?.message ?? JSON.stringify(err) ?? "unknown error";

                if (message.includes("The difference between the request time and the current time is too large."))
                    message = "Your device's time is not correct and is blocking the upload for security reasons.";

                toast.error("Error in upload: " + message);
                console.error("Error in upload: " + JSON.stringify(err));
                reject("Error in file upload: " + JSON.stringify(err));
            }
            else if (data && data.Location) {
                resolve(data.Location);
            }
            else {
                reject("Unknown Error in file upload");
            }
        });
    });

    return prom;
}
