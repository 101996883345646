import { useState, useEffect, useMemo } from "react";
import {
  Text,
  Box,
  Flex,
  IconButton,
  Icon,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SearchIcon } from "@chakra-ui/icons";
import { LibraryIcon } from "./icons/library-icon";
import { GRADIENT_COLOR, prompts, SavedAction } from "./utils";
import { CloseLibrary } from "./icons/close-library";
import { BsFillCaretDownFill } from "react-icons/bs";
import { SavedActions } from "./saved-actions";

interface Props {
  onSelect?: (prompt: any) => void;
  searchTerm: string;
  setSearchTerm: (value: any) => void;
  activeIndex: any;
  setActiveIndex: (value: any) => void;
  savedActions: SavedAction[];
  onUpdateSavedActions: (updatedSavedActions: SavedAction[]) => void;
  editorInstance: any;
  imperativeHandleInstance: any;
}

export function ActionLibrary({
  onSelect = () => {},
  searchTerm,
  setSearchTerm,
  activeIndex,
  setActiveIndex,
  savedActions,
  onUpdateSavedActions,
  editorInstance,
  imperativeHandleInstance,
}: Props) {
  const [filteredPrompts, setFilteredPrompts] = useState(prompts);
  const [filteredSavedActions, setFilteredSavedActions] =
    useState<SavedAction[]>(savedActions);

  const promptBg = useColorModeValue(
    "background.lightMode.medium",
    "background.darkMode.medium"
  );
  const accordionExpandedBg = useColorModeValue(
    "linear-gradient(91deg, #FFEFF2 0%, #EEF0FF 100%)",
    "#1a202c"
  );
  const iconColor = useColorModeValue(
    "var(--chakra-colors-text-lightMode-standard)",
    "var(--chakra-colors-text-darkMode-standard)"
  );

  useEffect(() => {
    if (searchTerm) {
      const filteredList = prompts.filter(
        (p) =>
          (typeof p.label === "string" &&
            p.label.includes(searchTerm.trim())) ||
          p.prompt.includes(searchTerm.trim())
      );
      setFilteredPrompts(filteredList);

      const filteredSavedList = savedActions.filter(
        (a) =>
          a.title.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
          a.prompt.toLowerCase().includes(searchTerm.toLowerCase().trim())
      );
      setFilteredSavedActions(filteredSavedList);
    } else {
      setFilteredPrompts(prompts);
      setFilteredSavedActions(savedActions);
    }
  }, [searchTerm]);

  const categories = useMemo(() => {
    let categoryList = filteredPrompts
      .map((prompt) => prompt.category)
      .filter((x) => !!x);
    return [...new Set(categoryList)];
  }, [filteredPrompts]);

  return (
    <>
      <Box p="5">
        <InputGroup size="sm">
          <Input
            placeholder="Search actions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <InputRightElement children={<SearchIcon />} />
        </InputGroup>
      </Box>
      {searchTerm ? (
        <Box flex="1 1 0%" overflow="auto" className="noScrollBar">
          {filteredSavedActions.map(({ id, title, prompt }) => (
            <Flex
              key={id}
              _hover={{ bg: promptBg }}
              p="2"
              pl="6"
              cursor="pointer"
              minH="50px"
              alignItems="center"
              onClick={() => onSelect({ prompt })}
            >
              <Text fontWeight="normal">{title}</Text>
            </Flex>
          ))}
          {filteredPrompts.map((prompt, index) => (
            <Flex
              key={typeof prompt.label === "string" ? prompt.label : index}
              _hover={{ bg: promptBg }}
              p="2"
              pl="6"
              cursor="pointer"
              minH="50px"
              alignItems="center"
              onClick={() => onSelect(prompt)}
            >
              <Text fontWeight="normal">{prompt.label}</Text>
            </Flex>
          ))}
        </Box>
      ) : (
        <Accordion
          index={[activeIndex]}
          onChange={(index) => setActiveIndex(index)}
          flex="1 1 0%"
          overflow="auto"
          className="noScrollBar"
          pb="10"
          allowToggle
        >
          <SavedActions
            actions={savedActions}
            onUpdate={onUpdateSavedActions}
            onSelect={(prompt) => onSelect({ prompt })}
            editorInstance={editorInstance}
            imperativeHandleInstance={imperativeHandleInstance}
          />
          {categories.map((category) => (
            <AccordionItem key={category} border="none">
              {({ isExpanded }) => (
                <Box key={category}>
                  <Box bg={isExpanded ? accordionExpandedBg : "inherit"}>
                    <AccordionButton
                      alignItems="center"
                      fontSize="sm"
                      fontWeight="semibold"
                      _expanded={{ bgGradient: GRADIENT_COLOR, bgClip: "text" }}
                      minH="50px"
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {category}
                      </Box>
                      <motion.div
                        animate={{
                          rotate: isExpanded ? 180 : 0,
                        }}
                        transition={{
                          type: "spring",
                          duration: 0.5,
                          bounce: 0,
                        }}
                        style={{ height: "14px" }}
                      >
                        <Icon
                          as={BsFillCaretDownFill}
                          h="4"
                          w="4"
                          color={isExpanded ? `#606AE2` : iconColor}
                        />
                      </motion.div>
                    </AccordionButton>
                  </Box>

                  <AccordionPanel p="0">
                    {filteredPrompts
                      .filter((x) => x.category === category)
                      .map((prompt, index) => (
                        <Flex
                          key={
                            typeof prompt.label === "string"
                              ? prompt.label
                              : index
                          }
                          _hover={{ bg: promptBg }}
                          p="2"
                          pl="6"
                          cursor="pointer"
                          minH="50px"
                          alignItems="center"
                          onClick={() => onSelect(prompt)}
                        >
                          <Text fontWeight="normal">{prompt.label}</Text>
                        </Flex>
                      ))}
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      )}
      {filteredPrompts.length === 0 && filteredSavedActions.length === 0 ? (
        <Text textAlign="center">No results found!</Text>
      ) : null}
    </>
  );
}
