import { useMemo, memo, useEffect, useState, MouseEvent, useRef } from "react";
import { useSession } from "next-auth/react";
import {
  Box,
  InputGroup,
  Input,
  Text,
  Flex,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { BsChatSquareText } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import toast from "react-hot-toast";
import { Message } from "./messages";
import { firebaseClient } from "firebaseClient";
import { getAccount } from "utils/sessionHelper";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

interface Props {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  conversations: Conversation[];
  onSelect: (conversation: Conversation) => void;
  onUpdate: (
    conversations: Conversation[],
    options?: { type: "delete"; id: string }
  ) => void;
}

export const History = memo(
  ({ searchTerm, setSearchTerm, conversations, onSelect, onUpdate }: Props) => {
    const [filteredToday, setFilteredToday] = useState<Conversation[]>([]);
    const [filteredYesterday, setFilteredYesterday] = useState<Conversation[]>(
      []
    );
    const [filteredThisWeek, setFilteredThisWeek] = useState<Conversation[]>(
      []
    );
    const [filteredLast60Days, setFilteredLast60Days] = useState<
      Conversation[]
    >([]);
    const { data: session } = useSession() ?? {};

    const [today, yesterday, thisWeek, last60Days] = useMemo(() => {
      const sorted = conversations.sort(
        (a, b) => b.updatedAt.valueOf() - a.updatedAt.valueOf()
      );
      let today: Conversation[] = [];
      let yesterday: Conversation[] = [];
      let thisWeek: Conversation[] = [];
      let last60Days: Conversation[] = [];

      sorted.forEach((s) => {
        let updatedAt = moment(s.updatedAt);

        if (updatedAt.isSame(new Date(), "day")) {
          today.push(s);
        } else if (
          updatedAt.isSame(moment().subtract(1, "days").startOf("d"), "d")
        ) {
          yesterday.push(s);
        } else if (updatedAt.isoWeek() == moment().isoWeek()) {
          thisWeek.push(s);
        } else {
          last60Days.push(s);
        }
      });

      return [today, yesterday, thisWeek, last60Days];
    }, [conversations]);

    useEffect(() => {
      if (searchTerm) {
        const resultsInToday = getFilteredBySearchTerm(today, searchTerm);
        setFilteredToday(resultsInToday);

        const resultsInYesterday = getFilteredBySearchTerm(
          yesterday,
          searchTerm
        );
        setFilteredYesterday(resultsInYesterday);

        const resultsInThisWeek = getFilteredBySearchTerm(thisWeek, searchTerm);
        setFilteredThisWeek(resultsInThisWeek);

        const resultsInLast60Days = getFilteredBySearchTerm(
          last60Days,
          searchTerm
        );
        setFilteredLast60Days(resultsInLast60Days);
      } else {
        setFilteredToday(today);
        setFilteredYesterday(yesterday);
        setFilteredThisWeek(thisWeek);
        setFilteredLast60Days(last60Days);
      }
    }, [searchTerm, today]);

    const getFilteredBySearchTerm = (
      items: Conversation[],
      searchTerm: string
    ) => {
      return items.filter((t) => {
        return t.messages.some(
          (m) =>
            m.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            m.composerContent?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    };

    const handleDelete = async (id: string) => {
      const db = firebaseClient.firestore();
      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(id)
        .delete();
      const filteredConversations = conversations.filter((c) => c.id !== id);
      onUpdate(filteredConversations, { type: "delete", id });
    };

    const handleSave = async (id: string, newName: string) => {
      const updatedAt = new Date();
      const db = firebaseClient.firestore();
      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(id)
        .update({ name: newName, updatedAt });

      const index = conversations.findIndex((c) => c.id === id);
      let updatedConversations = [...conversations];
      updatedConversations[index].name = newName;
      updatedConversations[index].updatedAt = updatedAt;
      onUpdate(updatedConversations);
    };

    return (
      <>
        <Box p="5">
          <InputGroup size="sm">
            <Input
              placeholder="Search past conversations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Box>
        <Box flex="1 1 0%" overflow="auto" className="noScrollBar">
          {filteredToday.length > 0 ? (
            <Box mb="4">
              <Text fontWeight="semibold" ml="6" color="#0C67C2" mb="1">
                Today
              </Text>
              {filteredToday.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredYesterday.length > 0 ? (
            <Box mb="4">
              <Text fontWeight="semibold" ml="6" color="#0C67C2" mb="1">
                Yesterday
              </Text>
              {filteredYesterday.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredThisWeek.length > 0 ? (
            <Box mb="4">
              <Text fontWeight="semibold" ml="6" color="#0C67C2" mb="1">
                Earlier this week
              </Text>
              {filteredThisWeek.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredLast60Days.length > 0 ? (
            <Box mb="4">
              <Text fontWeight="semibold" ml="6" color="#0C67C2" mb="1">
                In the last 60 days
              </Text>
              {filteredLast60Days.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {conversations.length === 0 ? (
            <Text textAlign="center">No conversations saved yet!</Text>
          ) : filteredToday.length === 0 &&
            filteredYesterday.length === 0 &&
            filteredThisWeek.length === 0 &&
            filteredLast60Days.length === 0 ? (
            <Text textAlign="center">No results found!</Text>
          ) : null}
        </Box>
        <Box>
          <Text
            fontSize="10.5px"
            px="6"
            py="5"
            textAlign="center"
            lineHeight="1.2"
          >
            Conversation history is only saved for 60 days. If you particularly
            like an action you wrote, consider saving it as a favorite!
          </Text>
        </Box>
      </>
    );
  }
);

export interface Conversation {
  id: string;
  name: string;
  messages: Message[];
  createdAt: Date;
  updatedAt: Date;
}

interface HistoryItemProps {
  conversation: Conversation;
  onSelect: (conversation: Conversation) => void;
  onDelete: (id: string) => Promise<void>;
  onSave: (newName: string) => Promise<void>;
}

function HistoryItem({
  conversation,
  onSelect,
  onDelete,
  onSave,
}: HistoryItemProps) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [name, setName] = useState<string>(conversation.name);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const conversationItemBg = useColorModeValue(
    "background.lightMode.medium",
    "background.darkMode.medium"
  );

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
      setName(conversation.name);
    } else {
      setName(conversation.name);
    }
  }, [isEditMode, conversation]);

  const handleDelete = async (e) => {
    try {
      e.stopPropagation();
      setIsDeleting(true);
      await onDelete(conversation.id);
    } catch (e) {
      console.error("Error in deleting conversation: ", e);
      toast.error("Error in deleting conversation: " + e.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async (e) => {
    try {
      e.stopPropagation();
      if (!name) {
        toast.error("Name cannot be left blank");
        return;
      }

      setIsSaving(true);
      await onSave(name);
      setIsEditMode(false);
    } catch (e) {
      console.error("Error in updating conversation name: ", e);
      toast.error("Error in updating conversation name: " + e.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Flex
      _hover={{ bg: conversationItemBg }}
      p="2"
      pl="6"
      cursor={isEditMode ? "default" : "pointer"}
      minH="50px"
      alignItems="center"
      onClick={() => {
        if (isEditMode) {
          return;
        }

        onSelect(conversation);
      }}
    >
      <BsChatSquareText style={{ marginTop: "2px" }} />
      {isEditMode ? (
        <Flex>
          <Input
            variant="unstyled"
            size="sm"
            ml="3"
            ref={inputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <IconButton
            aria-label="confirm"
            icon={<CheckIcon w="3" h="3" />}
            variant="action"
            ml="3"
            size="sm"
            isLoading={isSaving}
            onClick={handleSave}
          />
          <IconButton
            aria-label="cancel"
            icon={<CloseIcon w="3" h="3" />}
            variant="action"
            ml="1"
            size="sm"
            isDisabled={isSaving}
            onClick={(e) => {
              e.stopPropagation();
              setIsEditMode(false);
            }}
          />
        </Flex>
      ) : (
        <>
          <Text fontWeight="medium" ml="2.5">
            {name}
          </Text>
          <IconButton
            aria-label="edit conversation name"
            icon={<GrEdit size={12} strokeOpacity="0.5" />}
            variant="action"
            ml="3"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditMode(true);
              inputRef.current?.focus();
            }}
          />
          <IconButton
            aria-label="Delete conversation"
            icon={<RiDeleteBin5Line size={13} color="gray" />}
            variant="action"
            ml="1"
            size="sm"
            isLoading={isDeleting}
            onClick={handleDelete}
          />
        </>
      )}
    </Flex>
  );
}
