import { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Button,
  useColorModeValue,
  MenuButtonProps,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSession } from "next-auth/react";
import { getAccount } from "utils/sessionHelper";
import { textStyle } from "theme/names";

interface Props {
  placeholder?: string;
  onSelectList: (list: any) => void;
  reset?: boolean;
  isDisabled?: boolean;
  menuButtonProps?: MenuButtonProps;
  value?: any;
  filterListIds?: number[];
}

export function ContactListDropdown({
  placeholder = "Contact lists",
  onSelectList,
  reset = false,
  isDisabled = false,
  menuButtonProps = {},
  value = undefined,
  filterListIds = []
}: Props) {
  const [selectedList, setSelectedList] = useState<any>();
  const { data: session } = useSession() ?? {}

  const downIconColor = useColorModeValue("gray.400", "gray.500");
  const textColor = useColorModeValue("gray.400", "gray.500");
  let contactLists = getAccount(session)?.contactLists || [];

  if (filterListIds.length > 0) {
    contactLists = contactLists?.filter(l => !filterListIds.includes(l.id))
  }

  useEffect(() => {
    if (reset) {
      setSelectedList(null);
    }
  }, [reset]);

  useEffect(() => {
    if (value !== undefined || value !== null) {
      setSelectedList(value);
    }
  }, [value])

  if (!session) {
    return null;
  }

  return (
    <Menu variant="secondary" >
      <MenuButton
        as={Button}
        // size="sm"
        variant="secondary"
        rightIcon={<ChevronDownIcon color={downIconColor} />}
        // ml="2"
        isDisabled={isDisabled}
        {...menuButtonProps}
      >
        {selectedList?.name ? (
          <Text 
          // textStyle={textStyle["body.medium.light"]}
           maxW="200px" noOfLines={1} wordBreak={"break-all"}>
            {selectedList.name}
          </Text>
        ) : (
          <Text 
          // textStyle={textStyle["body.medium.light"]} 
              maxW="200px"
              // color={textColor}
              noOfLines={1} wordBreak={"break-all"}>
            {placeholder}
          </Text>
        )}
      </MenuButton>
      <MenuList maxH="215px" overflow="scroll">
        {contactLists?.length > 0 ? (
          contactLists?.map((l) => (
            <MenuItem
              key={l.id}
              onClick={() => {
                setSelectedList(l);
                onSelectList(l);
              }}
            >
              {l.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No List found</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
