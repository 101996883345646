export function SendIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1901_4884)">
        <path
          d="M0.313094 17.1447C0.173952 17.7841 0.664859 18.0548 1.12036 17.8506L16.5152 9.95663H16.5168C16.7004 9.85306 16.807 9.66642 16.807 9.45112C16.807 9.23569 16.7004 9.04893 16.5168 8.9454H16.5152L1.12036 1.05135C0.664859 0.84709 0.173952 1.11784 0.313094 1.75726C0.322509 1.80057 1.23264 5.85467 1.72748 8.05954L9.76386 9.45108L1.72748 10.8424C1.23264 13.0472 0.322467 17.1014 0.313094 17.1447Z"
          fill="url(#paint0_linear_1901_4884)"
        />
        <path
          d="M0.313094 17.1447C0.173952 17.7841 0.664859 18.0548 1.12036 17.8506L16.5152 9.95663H16.5168C16.7004 9.85306 16.807 9.66642 16.807 9.45112C16.807 9.23569 16.7004 9.04893 16.5168 8.9454H16.5152L1.12036 1.05135C0.664859 0.84709 0.173952 1.11784 0.313094 1.75726C0.322509 1.80057 1.23264 5.85467 1.72748 8.05954L9.76386 9.45108L1.72748 10.8424C1.23264 13.0472 0.322467 17.1014 0.313094 17.1447Z"
          fill="url(#paint1_linear_1901_4884)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1901_4884"
          x1="8.54805"
          y1="0.984375"
          x2="8.54805"
          y2="17.9175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9557B4" />
          <stop offset="1" stopColor="#9557B4" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1901_4884"
          x1="14.7423"
          y1="8.7454"
          x2="4.93416"
          y2="9.08111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7069D6" />
          <stop offset="1" stopColor="#E86285" />
        </linearGradient>
        <clipPath id="clip0_1901_4884">
          <rect width="16.9331" height="16.9331" fill="white" transform="translate(0.078125 0.984375)" />
        </clipPath>
      </defs>
    </svg>
  );
}
