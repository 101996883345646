import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid, Icon
} from "@chakra-ui/react";
import {
  LiveDashboardChart
} from "components/what-and-when-to-post/LiveDashboard";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsFillCaretDownFill, BsFillCaretRightFill } from "react-icons/bs";
import { firebaseClient } from "../firebaseClient";

const Recommendation = ({ label }) => {
  return (
    <Flex textStyle="body.medium.light" alignItems={"center"} gap="1">
      <BsFillCaretRightFill {...{ fontSize: "10px" }} />
      {label}
    </Flex>
  );
};

export const WhenToPostData = ({ showStats, showStatsSet }) => {
  const db = firebaseClient.firestore();

  const dayOffset = 0;
  const startOfDayOffsetByDaysBeforeToday = (daysFromToday = 0) => {
    return new Date(
      moment
        .tz("Europe/Paris")
        .subtract(daysFromToday, "d")
        .startOf("day")
        .toString()
    ).toDateString();
  };
  const [thirtyDayAggregate, thirtyDayAggregateSet] = useState<any>();

  useEffect(() => {
    const getData = async () => {
      let count = 0;
      let thirtyDayAggregateDocs: any = undefined;

      // make sure we get a valid aggregation even if the last compute failed
      while (count < 30 && !thirtyDayAggregateDocs?.exists) {
        let date = startOfDayOffsetByDaysBeforeToday(dayOffset + 1 + count);

        // console.log(`data doc name: 30_day_aggregate_${date}`);
        try {
          thirtyDayAggregateDocs = await db
            .collection("liveDashboard")
            .doc(`30_day_aggregate_${date}`)
            .get();
        } catch (error) {
          console.log({ error });
        }

        count++;
      }

      return thirtyDayAggregateDocs.data();
    };

    getData()
      .then((data) => {
        // console.log({ data });
        thirtyDayAggregateSet(data);
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  /* BY WEEKDAY */
  const sortedWeekdaysData = weekdays.map((item, mapIndex) => {
    return { day: item, ...thirtyDayAggregate?.byWeekDay[item] };
  });
  const totalVolumeOfPosts = sortedWeekdaysData.reduce((previous, current) => {
    return previous + current.numPosts;
  }, 0);
  /* BY HOUR OF DAY */
  const sortedhourOfDayData = [...Array(24).keys()].map((item, mapIndex) => {
    return { hour: item, ...thirtyDayAggregate?.byHourOfDay[item] };
  });
  const volumeOfPostsByHourOfDay = sortedhourOfDayData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.numPosts / totalVolumeOfPosts,
    };
  });
  const engagementPerPostByHourOfDay = sortedhourOfDayData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );
  const reachPerPostByHourOfDay = sortedhourOfDayData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalReach / item.numPosts,
    };
  });
  const engagementRateByHourOfDay = sortedhourOfDayData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.totalReach,
      };
    }
  );

  // const maxEngagementPerPostByHourOfDay = Math.max(

  //   ...engagementPerPostByHourOfDay.map((item) => item.y)
  // );

  const hourMaxEngagementPerPostByHourOfDay =
    engagementPerPostByHourOfDay.reduce(
      (previous, current) => {
        return previous.y > current.y ? previous : current;
      },
      { x: 0, y: 0 }
    );

  const hourMaxReachPerPostByHourOfDay = reachPerPostByHourOfDay.reduce(
    (previous, current) => {
      return previous.y > current.y ? previous : current;
    },
    { x: 0, y: 0 }
  );

  const hourMaxEngagementRateByHourOfDay = engagementRateByHourOfDay.reduce(
    (previous, current) => {
      return previous.y > current.y ? previous : current;
    },
    { x: 0, y: 0 }
  );

  // BY WEEKDAY

  const volumeOfPostsByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.numPosts / totalVolumeOfPosts,
    };
  });
  const engagementPerPostByWeekDay = sortedWeekdaysData.map(
    (item, mapIndex) => {
      return {
        x: mapIndex,
        y: item.totalEngagement / item.numPosts,
      };
    }
  );
  const reachPerPostByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalReach / item.numPosts,
    };
  });
  const engagementRateByWeekDay = sortedWeekdaysData.map((item, mapIndex) => {
    return {
      x: mapIndex,
      y: item.totalEngagement / item.totalReach,
    };
  });

  const dayMaxEngagementPerPostByWeekDay = engagementPerPostByWeekDay.reduce(
    (previous, current) => {
      return previous.y > current.y ? previous : current;
    },
    { x: 0, y: 0 }
  );

  const dayMaxReachPerPostByWeekDay = reachPerPostByWeekDay.reduce(
    (previous, current) => {
      return previous.y > current.y ? previous : current;
    },
    { x: 0, y: 0 }
  );

  const dayMaxEngagementRateByWeekDay = engagementRateByWeekDay.reduce(
    (previous, current) => {
      return previous.y > current.y ? previous : current;
    },
    { x: 0, y: 0 }
  );

  console.log({
    engagementPerPostByHourOfDay,
    reachPerPostByHourOfDay,
    engagementRateByHourOfDay,
    hourMaxEngagementPerPostByHourOfDay,
    hourMaxReachPerPostByHourOfDay,
    hourMaxEngagementRateByHourOfDay,
  });

  const [utc, utcSet] = useState<"utc" | "local">("local");
  const [hours, hoursSet] = useState(
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ].map((hour, mapIndex) => {
      return moment().utc().hours(hour).minutes(0).local().format("HH:mm");
    })
  );
  useEffect(() => {
    hoursSet(
      [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ].map((hour, mapIndex) => {
        return utc === "utc"
          ? moment().utc().hours(hour).minutes(0).format("HH:mm")
          : moment().utc().hours(hour).minutes(0).local().format("HH:mm");
      })
    );
  }, [utc]);
  const YTickFormatValue = (v) => Number(v).toFixed(0);
  const YTickFormatPercent = (v) => `${(v * 100).toFixed(0)}%`;
  const XTickFormatWeekdays = (v) => weekdays[v];
  const XTickFormatHours = (v) => hours[v];
  // const XTickFormatLengthsOfPost = (v) => lengthsOfPost[v];
  // const XTickFormatContentType = (v) => contentType[v];

  // console.log({ thirtyDayAggregate });
  const [showCharts, showChartsSet] = useState(false);
  const [showHoursCharts, showHoursChartsSet] = useState(false);
  const [showWeekdayCharts, showWeekdayChartsSet] = useState(false);

  return (
    <Collapse animateOpacity in={showStats}>
      <Box {...{ p: 4 }}>
        <Flex {...{ flexDir: "column", gap: 1, mb: 2 }}>
          <Flex
            textStyle="body.medium.standard"
            mb="1"
            alignItems={"center"}
            gap="1"
          >
            According to our data based on 30,000+ Taplio posts in
            the last 30 days:
          </Flex>
          <Box textStyle="body.medium.light">By hour of day:</Box>
          <Recommendation
            {...{
              label: `To maximize engagement on your post, post between
            ${hourMaxEngagementPerPostByHourOfDay.x}:00 UTC and 
            ${hourMaxEngagementPerPostByHourOfDay.x + 1}:00 UTC`,
            }}
          />
          <Recommendation
            {...{
              label: `To maximize reach on your post, post between
            ${hourMaxReachPerPostByHourOfDay.x}:00 UTC and 
            ${hourMaxReachPerPostByHourOfDay.x + 1}:00 UTC`,
            }}
          />
          <Recommendation
            {...{
              label: `To maximize engagement rate on your post, post between 
            ${hourMaxEngagementRateByHourOfDay.x}:00 UTC and 
            ${hourMaxEngagementRateByHourOfDay.x + 1}:00 UTC`,
            }}
          />
          <Box textStyle="body.medium.light" mt={1}>
            By day of the week:
          </Box>

          <Recommendation
            {...{
              label: `To maximize engagement on your post, post on ${weekdays[dayMaxEngagementPerPostByWeekDay.x]
                }`,
            }}
          />
          <Recommendation
            {...{
              label: `To maximize reach on your post, post on ${weekdays[dayMaxReachPerPostByWeekDay.x]
                }`,
            }}
          />
          <Recommendation
            {...{
              label: `To maximize engagement rate on your post, post on ${weekdays[dayMaxEngagementRateByWeekDay.x]
                }`,
            }}
          />
        </Flex>

        <Button
          placeSelf="center"
          variant="tertiary"
          onClick={() => {
            showChartsSet(!showCharts);
          }}
          leftIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 1C8.3125 1 9 1.6875 9 2.5V13.5C9 14.3438 8.3125 15 7.5 15H6.5C5.65625 15 5 14.3438 5 13.5V2.5C5 1.6875 5.65625 1 6.5 1H7.5ZM7.5 2.5H6.5V13.5H7.5V2.5ZM2.5 7C3.3125 7 4 7.6875 4 8.5V13.5C4 14.3438 3.3125 15 2.5 15H1.5C0.65625 15 0 14.3438 0 13.5V8.5C0 7.6875 0.65625 7 1.5 7H2.5ZM2.5 8.5H1.5V13.5H2.5V8.5ZM10 4.5C10 3.6875 10.6562 3 11.5 3H12.5C13.3125 3 14 3.6875 14 4.5V13.5C14 14.3438 13.3125 15 12.5 15H11.5C10.6562 15 10 14.3438 10 13.5V4.5ZM11.5 13.5H12.5V4.5H11.5V13.5Z"
                fill="currentColor"
              />
            </svg>
          }
          // right icon caret down
          rightIcon={<Icon as={BsFillCaretDownFill} fontSize="12px" />}
        >
          Show more data in charts
        </Button>

        <Collapse animateOpacity in={showCharts}>
          <Box
            textStyle="body.bold.standard"
            flex="1"
            textAlign="left"
            mt={2}
            mb={0}
            onClick={() => {
              showHoursChartsSet(!showHoursCharts);
            }}
          >
            By hour of day:
          </Box>
          {/* <Collapse animateOpacity in={showHoursCharts}> */}
          <Grid
            {...{
              // layerStyle: layerStyle.bgBorderGrayRounded,
              p: 0,
              mt: 2,
              gridTemplateColumns: "auto auto",
              gridTemplateRows: "auto auto",
            }}
          >
            <LiveDashboardChart
              {...{
                taplioUI: true,
                title: "Volume",
                explanation: "What time of day do people post the most?",
                width: 300,
                height: 140,
                data: volumeOfPostsByHourOfDay,
                XTickFormat: XTickFormatHours,
                YTickFormat: YTickFormatPercent,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                title: "Reach",
                explanation:
                  "What time of day should you post to reach more people?",
                width: 300,
                height: 140,
                data: reachPerPostByHourOfDay,
                XTickFormat: XTickFormatHours,
                YTickFormat: YTickFormatValue,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                title: "Engagement",
                explanation:
                  "What time of day should you post to get more engagement?",
                width: 300,
                height: 140,
                data: engagementPerPostByHourOfDay,
                XTickFormat: XTickFormatHours,
                YTickFormat: YTickFormatValue,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                title: "Engagement Rate",
                explanation:
                  "What time of day should you post to get more engagement? Engagement Rate is Engagement / Reach.",
                width: 300,
                height: 140,
                data: engagementRateByHourOfDay,
                XTickFormat: XTickFormatHours,
                YTickFormat: YTickFormatPercent,
              }}
            />
          </Grid>
          {/* </Collapse> */}

          <Box
            textStyle="body.bold.standard"
            flex="1"
            textAlign="left"
            mt={2}
            mb={0}
            onClick={() => {
              showWeekdayChartsSet(!showWeekdayCharts);
            }}
          >
            By day of week:
          </Box>

          {/* <Collapse animateOpacity in={showWeekdayCharts}> */}
          <Grid
            {...{
              // layerStyle: layerStyle.bgBorderGrayRounded,
              p: 0,
              mt: 2,
              gridTemplateColumns: "auto auto",
              gridTemplateRows: "auto auto",
            }}
          >
            <LiveDashboardChart
              {...{
                taplioUI: true,
                width: 300,
                height: 140,
                title: "Volume",
                explanation: "What day of the week do people post the most?",
                data: volumeOfPostsByWeekDay,
                XTickFormat: XTickFormatWeekdays,
                YTickFormat: YTickFormatPercent,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                width: 300,
                height: 140,
                title: "Reach",
                explanation:
                  "What day of the week should you post to reach more people?",
                data: reachPerPostByWeekDay,
                XTickFormat: XTickFormatWeekdays,
                YTickFormat: YTickFormatValue,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                width: 300,
                height: 140,
                title: "Engagement",
                explanation:
                  "What day of the week should you post to get more engagement?",
                data: engagementPerPostByWeekDay,
                XTickFormat: XTickFormatWeekdays,
                YTickFormat: YTickFormatValue,
              }}
            />
            <LiveDashboardChart
              {...{
                taplioUI: true,
                width: 300,
                height: 140,
                title: "Engagement Rate",
                explanation:
                  "What day of the week should you post to get more engagement? Engagement Rate is Engagement / Reach.",
                data: engagementRateByWeekDay,
                XTickFormat: XTickFormatWeekdays,
                YTickFormat: YTickFormatPercent,
              }}
            />
          </Grid>
        </Collapse>
      </Box>
    </Collapse>
  );
};
