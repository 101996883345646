import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { radius } from "theme/foundations/radius";
import { text } from "theme/foundations/text";
import { secondary } from "./button/secondary";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    header: {},
  };
});

const variantInfo = definePartsStyle((props) => {
  return {
    dialog: {
      p: 6,
      position: "relative",
      // chin of the modal part 1
      _before: {
        content: "''",
        position: "absolute",
        bottom: "-4px",
        left: "0",
        width: "100%",
        height: "16px",
        borderBottomRadius: "10px",
        background: "primary.lightMode.default",
      },
      // chin of the modal part 2
      _after: {
        content: "''",
        position: "absolute",
        bottom: "0px",
        left: "0",
        width: "100%",
        height: "16px",
        borderBottomRadius: "10px",
        background: "white",
      },
      _dark: {
        background: "#1E1E1E",
        border: "1px solid",
        borderColor: "border.darkMode.light", // dark mode

        _after: {
          background: "#1E1E1E",
        },
      },
    },
    header: {
      p: 0,
      display: "flex",
      alignItems: "center",
      gap: 2,
      ...text.h3.bold.standard.light,
      _dark: {
        ...text.h3.bold.standard.dark,
      },
    },
    body: {
      px: 0,
      pt: 0,
      pb: 4,
      mt: 4,
      mb: 4,
      ...text.body.medium.standard.light,
      borderBottom: "1px solid",
      borderColor: "border.lightMode.light", // dark mode
      _dark: {
        ...text.body.medium.standard.dark,
        borderColor: "border.darkMode.light", // dark mode
      },
    },
    closeButton: {
      right: "16px",
      top: "16px",
      // secondary button theme
      ...secondary,
      // secondary button theme overwrites
      borderRadius: radius.sm,
      // color: "text.lightMode.standard",
      fontSize: "10px",
      borderColor: "border.lightMode.light", // dark mode
      _dark: {
        bg: "#1E1E1E",
        color: "white",
        borderColor: "border.darkMode.light", // dark mode
      },
    },
    footer: {
      p: 0,
      display: "flex",
      justifyContent: "space-between",
      // ml: "-14px",
    },
  };
});

const variantUpgrade = definePartsStyle((props) => {
  return {
    dialog: {
      bg: `linear-gradient(180deg, #0B66C2 0%, #0391DF 100%)`,
      color: 'white',
      borderRadius: 'lg',
    },
    header: {
      py: 7,
      px: 10,
      fontWeight: 'bold',
      fontSize: 'lg',
    },
    body: {
      py: 2,
      px: 10,
      fontSize: 'md',
    },
    footer: {
      py: 7,
      px: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    closeButton: {
      display: 'none',
    },
  };
});

const variantPika = definePartsStyle((props) => {
  return {
    dialog: {
      bg: "#fafafa",
      _dark: {
        bg: "#000",
      },
    },
  };
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: { info: variantInfo, pika: variantPika, upgrade: variantUpgrade },
  defaultProps: {
    variant: "info",
    // @ts-ignore
    // isCentered: true, // make modal centered by default
  },
});
