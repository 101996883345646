import { firebaseClient, getToken } from "../firebaseClient";
import async from 'async';
import * as Sentry from "@sentry/nextjs";
import { getAccount, updateUser } from "../utils/sessionHelper";

// special IDs
// -1 => hidden tweet
// -2 => evergreen

export const hideTweet = async (tweet, idUser, idList = 1, toggle = "") => {

  console.log("idUser: " + idUser);
  await toggleSaveTweetInList(tweet, idUser, -1, "add");

  let newReply = {
    in_reply_to_status_id: tweet.id_str,
    idUser: idUser,
    dateLastReply: new Date(),
    replies: [],
  };
  const db = firebaseClient.firestore();
  await db.collection('users').doc(idUser).collection("replies").doc(tweet.id_str).set(newReply);
}

export const toggleSaveTweetInList = async (tweet, idUser, idList = 1, toggle = "") => {

  // console.log("toggleSaveTweetInList for " + idUser);
  // console.log("tweetID", tweet);

  const db = firebaseClient.firestore();
  const bookmarkDoc = await db.collection("users").doc(idUser).collection("bookmarks").doc(tweet.id_str).get();

  //@ts-ignore
  if (bookmarkDoc && bookmarkDoc.exists && bookmarkDoc?.data()?.lists) {
    // console.log("doc exists");
    let data = bookmarkDoc.data();
    if (toggle == "add") {
    //@ts-ignore
      data.lists.push(idList);
    }
    else {
      //@ts-ignore
      for (var i = data.lists.length; i--; )
      {
            //@ts-ignore
          if (data.lists[i] === idList) {
            //@ts-ignore
            data.lists.splice(i, 1);
          }
      }
    }

    //@ts-ignore
    if (data.lists.length > 0)
      //@ts-ignore
      await db.collection("users").doc(idUser).collection("bookmarks").doc(tweet.id_str).update({lists: data.lists});
    else
      await db.collection("users").doc(idUser).collection("bookmarks").doc(tweet.id_str).delete();
  }
  else {
    // console.log("doc don't exists");
    if (toggle == "add") {

      let dataToSave = {
        ...tweet,
        lists: [idList],
        dateAdded: new Date(),
        idList: idList,
      };

      dataToSave.entities = JSON.stringify(dataToSave.entities);
      dataToSave.user.entities = JSON.stringify(dataToSave.user.entities);
      delete dataToSave.place;
  
      // console.log(JSON.stringify(dataToSave));
  
      await db.collection("users").doc(idUser).collection("bookmarks").doc(tweet.id_str).set(dataToSave);
    }
  }
};

export const getList = async (idUser, idList = 0, includeEvergreen=true) => {
  // console.log("getList: " + idList);
  const db = firebaseClient.firestore();

  let tweets = [];
  try { 
    if (idList) {
      const bookmarkDocs = await db.collection("users").doc(idUser).collection("bookmarks").where("lists", "array-contains", idList).orderBy("dateAdded", "desc").get();
      bookmarkDocs.forEach((tw) => {
        let data: any = tw.data();
        // @ts-ignore
        tweets.push(data);
      });
    }
    else {
      const bookmarkDocs = await db.collection("users").doc(idUser).collection("bookmarks").orderBy("dateAdded", "desc").get();
      bookmarkDocs.forEach((tw) => {
        let data: any = tw.data();
        if (data?.lists?.length && (Math.max(...data.lists) >=0 || (data.lists.includes(-2) && includeEvergreen))) {
          // @ts-ignore
          tweets.push(data);
        }
      });
    }
  }
  catch (e) {
    console.log("Error in getList: " + e.message, e);
    Sentry.captureException(e);
  }

  // just in case a tweet is badly formated
  tweets.forEach((tw: any) => {
    if (tw?.created_at?.toDate) {
      tw.created_at = tw.created_at.toDate();
      // Sentry.captureException(new Error("Invalid created_at"));
    }
  })

  // console.log("getList nb tweets got: " + tweets.length);

  return tweets;
};

export const deleteList = async (idUser, idList, session) => {
  // console.log("getList: " + idList);
  const db = firebaseClient.firestore();

  try { 
    const userDocs = await db.collection("users").doc(idUser).get();
    let user: any = userDocs.data();

    let list = user.lists.find(x => x.id === idList);
    user.lists.splice(user.lists.indexOf(list), 1);
    // await db.collection("users").doc(idUser).update({lists: user.lists});
    await updateUser(session, {lists: user.lists});
      
    const bookmarkDocs = await db.collection("users").doc(idUser).collection("bookmarks").where("lists", "array-contains", idList).get();
    console.log("nb bookmarkDocs: " + bookmarkDocs.docs.length);

    bookmarkDocs.forEach(doc => {
      let data = doc.data();
      // console.log("Removing tweet " + doc.id + " from list " + idList);
      for (var i = data.lists.length; i--; )
      {
          //@ts-ignore
          if (data.lists[i] === idList) { 
            //@ts-ignore
            data.lists.splice(i, 1);
          }
      }
      if (data.lists.length > 0)
        db.collection("users").doc(idUser).collection("bookmarks").doc(doc.id).update({lists: data.lists});
      else
        db.collection("users").doc(idUser).collection("bookmarks").doc(doc.id).delete();
    });
  }
  catch (e) {
    console.log("Error in deleteList: " + e.message, e);
    Sentry.captureException(e);
  }

  // console.log("End delete");

  return;
};

export const refreshProfilePic = async (tweet, type="twitter", imageUrl="", isUsername=false) => {

  try {

    console.log("Error loading image for user: " + tweet.user.id_str);

    let url = "https://us-central1-ez4cast.cloudfunctions.net/tweetChampions-refreshAccount?id=" + tweet.user.id_str + "&idTweet=" + tweet.id_str + "&type=" + type + "&imageUrl=" + imageUrl + "&isUsername=" + isUsername;
    if (imageUrl)
      url += "&imageUrl=" + encodeURIComponent(imageUrl);

    let res = await fetch(url);
  }
  catch (e) {
    console.error("Error in refreshProfilePic: " + e.message, e);
    Sentry.captureException(e);
  }

  return;
};