import { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import { v4 as uuid } from "uuid";
import toast from "react-hot-toast";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { firebaseClient } from "firebaseClient";
import { useFirebaseUser } from "utils/useFirebaseUser";

export type CampaignType = "regular" | "feature release";
export type ShowItToType = "starter" | "standard" | "pro"

export interface Campaign {
  id?: string;
  type: CampaignType;
  startDate?: Date;
  endDate: Date;
  userAcquisition?: number;
  featureTitle?: string;
  featureDescription?: string;
  showItTo: ShowItToType[];
  primaryCTA: string;
  secondaryCTA: string;
  enableForExtension: boolean;
  extentionTitle?: string;
  extentionDesc?: string;
  extentionCTA?: string;
  extentionLink?: string;
}

interface ActivateUpdateCampaignProps {
  id?: string;
  startTime: Date;
  duration: number;
  type: CampaignType;
  showItTo: ShowItToType[];
  featureTitle?: string;
  featureDescription?: string;
  primaryCTA: string;
  secondaryCTA: string;
  enableForExtension: boolean;
  extentionTitle?: string;
  extentionDesc?: string;
  extentionCTA?: string;
  extentionLink?: string;
}

export const planAndTargetUsersMapping = {
  starter: "start",
  standard: "grow",
  pro: "enterprise",
};

export function useCampaign() {
  const [activeCampaign, setActiveCampaign] = useState<Campaign>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const { data: session } = useSession() ?? {};
  const isAuthenticated = useFirebaseUser();

  const activateCampaign = async ({
    type,
    startTime,
    duration,
    showItTo,
    featureTitle,
    featureDescription,
    primaryCTA,
    secondaryCTA,
    enableForExtension,
    extentionTitle,
    extentionDesc,
    extentionCTA,
    extentionLink,
  }: ActivateUpdateCampaignProps) => {
    try {
      const db = firebaseClient.firestore();
      let data: Campaign = {
        id: uuid(),
        type,
        startDate: startTime < new Date() ? new Date() : startTime,
        endDate: moment(startTime).add(duration, "h").toDate(),
        userAcquisition: 0,
        showItTo,
        enableForExtension,
        primaryCTA,
        secondaryCTA,
        featureTitle,
        featureDescription,
        extentionTitle,
        extentionDesc,
        extentionCTA,
        extentionLink
      };

      await db.collection("campaigns").doc(data.id).set(data);
      setCampaigns([...campaigns, data]);

      toast.success("Successfully started campaign!");
    } catch (e) {
      toast.error("Error in activating campaign: " + e.message);
      console.error("Error in activating campaign: ", e);
    }
  };

  const updateCampaign = async ({
    id,
    type,
    startTime,
    duration,
    showItTo,
    featureTitle,
    featureDescription,
    primaryCTA,
    secondaryCTA,
    enableForExtension,
    extentionTitle,
    extentionDesc,
    extentionCTA,
    extentionLink,
  }: ActivateUpdateCampaignProps) => {
    try {
      const db = firebaseClient.firestore();
      let dataToSave: Campaign = {
        type,
        endDate: moment(startTime).add(duration, "h").toDate(),
        showItTo,
        enableForExtension,
        featureTitle,
        featureDescription,
        primaryCTA,
        secondaryCTA,
        extentionTitle,
        extentionDesc,
        extentionCTA,
        extentionLink,
      }

      await db.collection("campaigns").doc(id).update(dataToSave);
      const index = campaigns.findIndex(c => c.id === id);
      const campaignsCopy = [...campaigns];
      campaignsCopy[index] = dataToSave;
      setCampaigns(campaignsCopy);

      toast.success("Successfully updated campaign!");
    } catch (e) {
      toast.error("Error in updating campaign: " + e.message);
      console.error("Error in updating campaign: ", e);
    }
  }

  const getCampaigns = async () => {
    try {
      const db = firebaseClient.firestore();
      const docs = await db
        .collection("campaigns")
        .orderBy("startDate")
        .limit(100)
        .get();

      let campaigns: any[] = [];
      docs.forEach((doc) => {
        campaigns.push(doc.data());
      });

      setCampaigns(campaigns);
    } catch (e) {
      toast.error("Failed to fetch campaigns: " + e.message);
      console.error("Failed to fetch campaigns: ", e);
    }
  };

  const updateUserAcquisition = async () => {
    try {
      const userAcquisitionCounter = activeCampaign?.userAcquisition ?? 0;

      const db = firebaseClient.firestore();
      await db
        .collection("campaigns")
        .doc(activeCampaign?.id)
        .update({ userAcquisition: userAcquisitionCounter + 1 });
    } catch (err) {
      console.log("Error in updating user acquistion: ", err);
      Sentry.captureException(err);
    }
  };

  const stopCampaign = async (campaignId: string) => {
    try {
      const db = firebaseClient.firestore();
      await db.collection("campaigns").doc(campaignId).update({ endDate: new Date() });
      setActiveCampaign(undefined);
    } catch (e) {
      console.log("Error in stopping campaign: ", e);
      toast.error("Error in stopping campaign: " + e.message)
    }
  }

  useEffect(() => {
    if (campaigns.length > 0) {
      const currentlyRunning = campaigns.filter(
        // @ts-ignore
        (c) => new Date() < (c.endDate.toDate ? c.endDate.toDate() : c.endDate)
      );

      if (currentlyRunning.length === 1) {
        setActiveCampaign(currentlyRunning[0]);
      }
    }
  }, [campaigns]);

  useEffect(() => {
    if (session && isAuthenticated && !hasFetched) {
      setHasFetched(true);
      getCampaigns();
    }
  }, [session, isAuthenticated]);

  return { campaigns, activeCampaign, activateCampaign, updateUserAcquisition, stopCampaign, updateCampaign };
}
